/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const CLOSE_ALL = 'alchemist/People/EditableProfile/CLOSE_ALL';
export const SET_EDITABLE_SECTION = 'alchemist/People/EditableProfile/SET_EDITABLE_SECTION';
export const SECTION_SAVED_SUCCESSFULLY = 'alchemist/People/EditableProfile/SECTION_SAVED_SUCCESSFULLY';
export const TOGGLE_EMPTY_SECTIONS = 'alchemist/People/EditableProfile/TOGGLE_EMPTY_SECTIONS';
export const MARK_EMPTY_SECTION = 'alchemist/People/EditableProfile/MARK_EMPTY_SECTION';
export const LOAD_IFS = 'alchemist/People/EditableProfile/LOAD_IFS';
export const LOAD_REG_COMPANIES = 'alchemist/People/EditableProfile/Ifs/LOAD_REG_COMPANIES';
export const SET_REG_COMPANIES = 'alchemist/People/EditableProfile/Ifs/SET_REG_COMPANIES';
export const MARK_COMPANY = 'alchemist/People/EditableProfile/Ifs/MARK_COMPANY';
export const REORDER_MARKED_COMPANIES = 'alchemist/People/EditableProfile/Ifs/REORDER_MARKED_COMPANIES';
export const TOGGLE_MOBILE_REVIEWS = 'alchemist/People/EditableProfile/TOGGLE_MOBILE_REVIEWS';
export const CANCEL_DD_INVITATION = 'alchemist/People/EditableProfile/CANCEL_DD_INVITATION';
export const CANCEL_IFS_INVITATION = 'alchemist/People/EditableProfile/CANCEL_IFS_INVITATION';
export const REMOVE_VAULT_ACCESS = 'alchemist/People/EditableProfile/REMOVE_VAULT_ACCESS';
export const REMOVE_ROLE = 'alchemist/People/EditableProfile/REMOVE_ROLE';

export const SET_DD_EDITABLE_SECTION = 'alchemist/People/EditableProfile/SET_DD_EDITABLE_SECTION';
export const SET_SIMILAR_PROFILES_SECTION = 'alchemist/People/EditableProfile/SET_SIMILAR_PROFILES_SECTION';

export const SECTION_ROLE_MAPPER = {
  press: 'press',
  acquirer: 'acquirer',
  lecturer: 'lecturer',
  serviceProvider: 'consultant',
  investment: ['investor', 'angel', 'strategic_investor'],
  alchemistPortfolio: ['investor', 'angel', 'strategic_investor'],
  fmp: (profile) => profile?.fmp,
};
