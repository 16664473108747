import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ButtonPrimSm } from 'components/Buttons/ButtonPrim';
import { ButtonSecSm } from 'components/Buttons/ButtonSec';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import LoadingSpinner from 'components/LoadingSpinner';

import { makeSelectTypeofListBeingCopied, selectMarkedEventLists } from '../selectors';
import { clearMarkedEventLists as clearMarkedEventListsAction, copyEventLists } from '../actions';
import { LIST_TYPES } from '../constants';

const EventListsButtonsArchive = ({ selectedLists, clearMarkedEventLists, isDesktop, copyLists, listBeingCopied }) => (
  <>
    <div className="dsa-people-lists__section__buttons-bunch">
      <ButtonPrimSm className={isDesktop ? '' : 'me-6'} disabled={!selectedLists.length || listBeingCopied === LIST_TYPES.event} onClick={copyLists}>
        Copy to My Lists {listBeingCopied === LIST_TYPES.event && <LoadingSpinner className="dsa-copy-spinner d-inline-block p-0" size="lg" />}
      </ButtonPrimSm>
    </div>
    {isDesktop &&
      <div className="dsa-people-lists__section__buttons-clear">
        <span className="me-5">{selectedLists.length} list selected</span>
        <ButtonSecSm onClick={clearMarkedEventLists}>Clear</ButtonSecSm>
      </div>}
  </>
);

EventListsButtonsArchive.propTypes = {
  clearMarkedEventLists: PropTypes.func,
  selectedLists: PropTypes.array,
  isDesktop: PropTypes.bool,
  copyLists: PropTypes.func,
  listBeingCopied: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  clearMarkedEventLists: () => dispatch(clearMarkedEventListsAction()),
  copyLists: () => dispatch(copyEventLists()),
});

const mapStateToProps = createStructuredSelector({
  selectedLists: selectMarkedEventLists,
  listBeingCopied: makeSelectTypeofListBeingCopied(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(EventListsButtonsArchive));
