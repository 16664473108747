/*
 * PeopleListsConstants
 */

import { formatStr } from 'utils/general';
import { INITIAL_LOAD, SET_SEARCH, LOAD_CONTENT, LOAD_CONTENT_SUCCESS } from 'utils/filter/constants';

export const PEOPLE_LISTS_IDENTIFIER = 'PeopleLists';
export const OWN_LISTS = 'PeopleLists/OwnLists';
export const SHARED_WITH_ME_LISTS = 'PeopleLists/SharedWithMeLists';
export const PUBLIC_LISTS = 'PeopleLists/PublicLists';
export const EVENT_LISTS = 'PeopleLists/EventLists';
export const ADMIN_LISTS = 'PeopleLists/AdminLists';

export const TOGGLE_PEOPLE_LIST_MENU = 'alchemist/PeopleLists/TOGGLE_PEOPLE_LIST_MENU';
export const DELETE_PEOPLE_LISTS = 'alchemist/PeopleLists/DELETE_PEOPLE_LISTS';
export const REMOVE_ACCESS_FROM_SHARED_WITH_ME_LISTS = 'alchemist/PeopleLists/REMOVE_ACCESS_FROM_SHARED_WITH_ME_LISTS';
export const COPY_SHARED_WITH_ME_LISTS = 'alchemist/PeopleLists/COPY_SHARED_WITH_ME_LISTS';
export const COPY_PUBLIC_LISTS = 'alchemist/PeopleLists/COPY_PUBLIC_LISTS';
export const COPY_EVENT_LISTS = 'alchemist/PeopleLists/COPY_EVENT_LISTS';
export const COPY_ADMIN_LISTS = 'alchemist/PeopleLists/COPY_ADMIN_LISTS';

export const PEOPLE_LISTS_SET_SEARCH = formatStr(SET_SEARCH, PEOPLE_LISTS_IDENTIFIER);
export const LOAD_ALL_LISTS = 'alchemist/PeopleLists/LOAD_ALL_LISTS';
export const INITIAL_LOAD_ALL_LISTS = formatStr(INITIAL_LOAD, OWN_LISTS);

export const LOAD_OWN_LISTS = formatStr(LOAD_CONTENT, OWN_LISTS);
export const LOAD_SHARED_LISTS = formatStr(LOAD_CONTENT, SHARED_WITH_ME_LISTS);
export const LOAD_PUBLIC_LISTS = formatStr(LOAD_CONTENT, PUBLIC_LISTS);
export const LOAD_EVENT_LISTS = formatStr(LOAD_CONTENT, EVENT_LISTS);
export const LOAD_ADMIN_LISTS = formatStr(LOAD_CONTENT, ADMIN_LISTS);

export const LOAD_OWN_LISTS_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, OWN_LISTS);
export const LOAD_SHARED_LISTS_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, SHARED_WITH_ME_LISTS);
export const LOAD_PUBLIC_LISTS_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, PUBLIC_LISTS);
export const LOAD_EVENT_LISTS_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, EVENT_LISTS);
export const LOAD_ADMIN_LISTS_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, ADMIN_LISTS);

export const OPEN_PEOPLE_LIST_CREATE_MODAL = 'alchemist/PeopleLists/OPEN_PEOPLE_LIST_CREATE_MODAL';
export const CLOSE_PEOPLE_LIST_CREATE_MODAL = 'alchemist/PeopleLists/CLOSE_PEOPLE_LIST_CREATE_MODAL';
export const OPEN_PEOPLE_LIST_MERGE_MODAL = 'alchemist/PeopleLists/OPEN_PEOPLE_LIST_MERGE_MODAL';
export const CLOSE_PEOPLE_LIST_MERGE_MODAL = 'alchemist/PeopleLists/CLOSE_PEOPLE_LIST_MERGE_MODAL';
export const SET_MERGE_TARGET = 'alchemist/PeopleLists/SET_MERGE_TARGET';
export const MAKE_MERGE = 'alchemist/PeopleLists/MAKE_MERGE';
export const MERGE_DONE = 'alchemist/PeopleLists/MERGE_DONE';
export const COPY_DONE = 'alchemist/PeopleLists/COPY_DONE';
export const EXPAND_LIST_CONTAINER = 'alchemist/PeopleLists/EXPAND_LIST_CONTAINER';
export const ARCHIVE_LISTS = 'alchemist/PeopleLists/ARCHIVE_LISTS';
export const RESTORE_LISTS = 'alchemist/PeopleLists/RESTORE_LISTS';

export const COPY_ADMIN_TO_EVENT_LISTS = 'alchemist/PeopleLists/COPY_ADMIN_TO_EVENT_LISTS';
export const COPY_ADMIN_TO_EVENT_LISTS_LOADING = 'alchemist/PeopleLists/COPY_ADMIN_TO_EVENT_LISTS_LOADING';

export const LOAD_NEXT_PAGE_OWN_LISTS = `alchemist/${OWN_LISTS}/LOAD_NEXT_PAGE`;
export const LOAD_NEXT_PAGE_SHARED_WITH_ME_LISTS = `alchemist/${SHARED_WITH_ME_LISTS}/LOAD_NEXT_PAGE`;
export const LOAD_NEXT_PAGE_EVENT_LISTS = `alchemist/${EVENT_LISTS}/LOAD_NEXT_PAGE`;
export const LOAD_NEXT_PAGE_ADMIN_LISTS = `alchemist/${ADMIN_LISTS}/LOAD_NEXT_PAGE`;

export const LIST_TYPES = {
  my_lists: '0',
  shared: '1',
  public: '2',
  event: '3',
  admin: '4',
};

export const LIST_CARD_WIDTH_PX = 207;
export const LIST_CARD_MARGIN_PX = 14;
