/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { Text } from 'components/Text/Body';
import { toJS } from 'utils/general';
import { RESOURCE_COMPANIES, RESOURCE_PEOPLE } from 'containers/App/constants';

import { ReactstrapInputAdapter } from './RenderField';
import SimpleFieldHoc from '../HoC/SimpleFieldHoc';
import FormLabel from '../FormLabel';
import FormGroup from '../FormGroup';


// If the url input field is for a google drive link or any google link that is long,
// it is a requirement not to use this component
const UrlInput = ({ allValues, urlFieldClassName, resourceType, label, labelWidth, labelClassName, labelAdditionalComponent, isAdminField, isRow, resourceTypeLabel, CustomErrorComponent, customErrorComponentProps, ...props }) => {
  const labels = resourceType === RESOURCE_PEOPLE ? PEOPLE_LABELS : COMPANY_LABELS;
  const inputRef = useRef(null);

  const [isValueTooLong, setIsValueTooLong] = useState(false);

  const checkTextWidth = (inputValue) => {
    if (inputRef.current) {
      const elementWidth = inputRef.current.offsetWidth;
      const { font } = window.getComputedStyle(inputRef.current);
      const textWidth = measureTextWidth(inputValue, font);
      setIsValueTooLong(textWidth > (elementWidth - 24)); // 24px is the left & right padding of elementWidth
    }
  };
  // Debounced version of the measureTextWidth function
  const debouncedMeasureTextWidth = useCallback(debounce(checkTextWidth, 400), []);

  useEffect(() => {
    checkTextWidth(props.input?.value);
  }, []);

  return (
    <FormGroup
      className={classNames(`dsa-url-field dsa-form-group ${urlFieldClassName || ''}`, {
        'd-flex flex-row': isRow,
      })}
    >
      {(resourceType || label) &&
        <div className="d-flex justify-content-between">
          {label &&
            <FormLabel
              label={label}
              width={labelWidth}
              required={props.required}
              type={props.type}
              className={classNames('pt-3', labelClassName, { 'dsa-admin-txt': isAdminField })}
              meta={props.meta}
              labelAdditionalComponent={labelAdditionalComponent}
              isAdminField={isAdminField}
              showInfoTooltip={props.showInfoTooltip}
              infoTooltipText={props.infoTooltipText}
            />
          }
          {resourceType && <div className="flex-shrink-0 dsa-text-lighter-cyan font-weight-semibold pt-3 pr-2">{resourceTypeLabel || labels[props.input?.name] || 'URL'}</div>}
        </div>
      }
      <div className="w-100">
        <ReactstrapInputAdapter
          normalize={(val) => {
            if (val && val.toLowerCase) {
              if (props.name === 'linkedin_profile') {
                return val.toLowerCase().trim();
              }
              return val.toLowerCase();
            }
            return val;
          }}
          {...isTypeUrl(props.input?.name)}
          innerRef={inputRef}
          {...props}
          passedOnChange={(e) => {
            props.input.onChange(e);
            debouncedMeasureTextWidth(e.target?.value);
          }}
        />
        {!props.meta.error && isValueTooLong && (allValues?.[props.input?.name] || props.input?.value) &&
          <Text className="mt-3 mb-0">
            {getPreview(props.input?.name, allValues?.[props.input?.name] || props.input?.value, resourceType)}
          </Text>
        }
        {CustomErrorComponent && <CustomErrorComponent {...customErrorComponentProps} />}
      </div>
    </FormGroup>
  );
};
UrlInput.propTypes = {
  urlFieldClassName: PropTypes.string,
  allValues: PropTypes.object,
  name: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  resourceType: PropTypes.string,
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  labelClassName: PropTypes.string,
  labelAdditionalComponent: PropTypes.any,
  isAdminField: PropTypes.bool,
  resourceTypeLabel: PropTypes.string,
  CustomErrorComponent: PropTypes.element,
  customErrorComponentProps: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  allValues: (state, props) => toJS(state.get('form')?.get(props.formName)?.get('values')),
});

const ConnectedUrlInput = connect(mapStateToProps)(UrlInput);
export default ConnectedUrlInput;

export const UrlField = SimpleFieldHoc(ConnectedUrlInput, { colSize: 'col-50', className: 'mb-5', labelClassName: 'dsa-url-field__label' });

const PEOPLE_LABELS = {
  linkedin: 'Yourname/URL',
  linkedin_profile: 'Yourname/URL',
  twitter: 'Username/URL',
  twitterhandle: 'Username/URL',
  angelco: 'Yourname/URL',
  angelcoprofile: 'Yourname/URL',
  crunchbase: 'Yourname/URL',
  crunchbase_profile: 'Yourname/URL',
  signal_profile: 'Yourname/URL',
};

const COMPANY_LABELS = {
  linkedin: 'Company/URL',
  linkedin_profile: 'Company/URL',
  twitter: 'Company/URL',
  twitterhandle: 'Company/URL',
  angelco: 'Company/URL',
  angelcoprofile: 'Company/URL',
  crunchbase: 'Company/URL',
  crunchbase_profile: 'Company/URL',
};

const getPreview = (inputName, inputValue, resourceType) => {
  if (Object.keys(PEOPLE_LABELS).includes(inputName)
    && !inputValue.includes('://')
    && !inputValue.includes('@')
    && !inputValue.includes('www')
  ) {
    const domainNames = {
      linkedin: 'linkedin.com',
      linkedin_profile: 'linkedin.com',
      twitter: '@',
      twitterhandle: '@',
      angelco: 'wellfound.com',
      angelcoprofile: 'wellfound.com',
      crunchbase: 'crunchbase.com',
      crunchbase_profile: 'crunchbase.com',
      signal_profile: 'signal.nfx.com/investors',
    };
    let addedPrefix = '';
    if (['linkedin', 'linkedin_profile'].includes(inputName)) {
      if (resourceType === RESOURCE_PEOPLE) {
        addedPrefix = '/in';
      } else if (resourceType === RESOURCE_COMPANIES) {
        addedPrefix = '/company';
      }
    } else if (['crunchbase', 'crunchbase_profile'].includes(inputName)) {
      if (resourceType === RESOURCE_PEOPLE) {
        addedPrefix = '/person';
      } else if (resourceType === RESOURCE_COMPANIES) {
        addedPrefix = '/organization';
      }
    } else if (['angelco', 'angelcoprofile'].includes(inputName)) {
      if (resourceType === RESOURCE_COMPANIES) {
        addedPrefix = '/company';
      }
    } else if (['twitter', 'twitterhandle'].includes(inputName)) {
      return `@${inputValue}`;
    }
    return `https://${domainNames[inputName]}${addedPrefix}/${inputValue}`;
  }
  return inputValue;
};

const isTypeUrl = (inputName) => {
  if (Object.keys(PEOPLE_LABELS).includes(inputName)) {
    return {};
  }
  return { type: 'url' };
};

const measureTextWidth = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};
