import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CloseButtonWrapper from 'components/Buttons/CloseButtonWrapper';
import DsaModal from 'components/Modals/DsaModal';
import EditPeopleList from 'containers/Forms/PeopleList/EditPeopleList';

import { makeSelectCreateModalIsAdminList, makeSelectCreateModalIsEventList, makeSelectCreateModalIsOpen } from './selectors';
import { closePeopleListCreateModal } from './actions';


const PeopleListCreateModal = ({ closeModal, isOpen, isEventList, isAdminList }) => (
  <DsaModal isOpen={isOpen} toggle={closeModal}>
    <ModalHeader tag="h1">New&nbsp;
      { isEventList && 'Event ' }
      { isAdminList && 'Admin ' }
      List
    </ModalHeader>
    <CloseButtonWrapper closeAction={closeModal} />
    <EditPeopleList isEventList={isEventList} isAdminList={isAdminList} />
  </DsaModal>
);

PeopleListCreateModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  isEventList: PropTypes.bool,
  isAdminList: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closePeopleListCreateModal()),
});

const mapStateToProps = createStructuredSelector({
  isOpen: makeSelectCreateModalIsOpen(),
  isEventList: makeSelectCreateModalIsEventList(),
  isAdminList: makeSelectCreateModalIsAdminList(),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleListCreateModal);
