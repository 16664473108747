import { all, call, put, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { change, stopSubmit } from 'redux-form';

import { updateIncludedFromRequest } from 'actions';
import { API_BASE, PERMISSION, ATOMIC_RESULTS, RELATIONS, RESOURCE_ALCHEMIST_CLASSES, RESOURCE_DEMO_DAY_COMPANIES, CLASS_TYPE } from 'containers/App/constants';
import { makeSelectObject } from 'containers/App/selectors';
import { getCompany } from 'containers/Companies/Single/saga';
import { requestFunctionForObject, splitResponsesAndErrors, isUniquenessError } from 'utils/Forms/general';
import { callAndCatch, ProcessedError, handleCallAndCatches, handleAtomicCallAndCatches, preprocessError } from 'utils/Forms/sagas';
import { formDataToJApi } from 'utils/formToJsonApi';
import { patch, post, deleteReq, atomicReq } from 'utils/request';
import { updateObjFromApi } from 'utils/sagas';
import { refApiUrl, resRef } from 'utils/refs';
import { logError } from 'utils/log';
import { makeSelectCompany } from 'containers/Companies/Single/selectors';
import { makeSelectUserIs, makeSelectAccount } from 'containers/AuthProvider/selectors';

import {
  SAMPLE_INVESTMENT,
  SAMPLE_ADD_CONTACT,
  SAMPLE_MEMBERSHIP,
  SAMPLE_INVITE_TEAMMEMBER,
} from '../constants';
import { setUserByEmail } from '../actions';
import { makeSelectUsersByEmail } from '../selectors';
import { createNewCompany, inviteCompany, saveFormSection, savePortfolioInvestmentSaga } from '../saga';
import { WILL_NOT_PARTICIPATE_DD_OPT } from './constants';
import { getCurrentStartupAclassRel } from './utils';


export function* saveCompanyInvestmentRoundsSectionSaga(action) {
  const multiFormMainField = 'investment_rounds';
  if (!action.values[multiFormMainField]) return null;
  try {
    const invReq = yield call(atomicReq, action.values[multiFormMainField].map((invR) => ({ ...invR, company: action.objRef })), false);
    const isSuccess = yield handleAtomicCallAndCatches(invReq[ATOMIC_RESULTS], action, multiFormMainField);
    if (isSuccess) {
      yield updateObjFromApi(action.objRef, multiFormMainField);
      const account = yield select(makeSelectAccount());
      yield updateObjFromApi(account, ['notifications']);
    }
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    yield handleAtomicCallAndCatches(errorJson[ATOMIC_RESULTS], action, multiFormMainField);
  }
  return null;
}


export function* saveCompanyCoachRelsSaga(action) {
  const multiFormMainField = 'coachRels';
  if (!action.values[multiFormMainField]) return null;

  const coachRelsRequests = yield all(action.values[multiFormMainField].map((membership) => (
    callAndCatch(
      requestFunctionForObject(membership),
      refApiUrl(membership),
      {
        data: formDataToJApi(
          { ...SAMPLE_MEMBERSHIP, id: membership.id },
          { ...membership, relation: RELATIONS.COACH, company: action.objRef }
        ),
      }
    )
  )));
  yield handleCallAndCatches(coachRelsRequests, action, multiFormMainField);
  yield updateObjFromApi(action.objRef, 'current_members_rel.user');
  return null;
}

const makeSaveCompanyFounderRelSaga = (company) => {
  const teamAndRelatedMemberships = company.teamAndRelatedMemberships?.() || [];
  const currentTeamOrRelatedMembership = teamAndRelatedMemberships
    .filter((teamMembership) => !teamMembership.end_date);
  return function* saveCompanyFounderRelSaga(membership) {
    if (membership.id && membership.isDeleted) {
      try {
        yield call(deleteReq, `${refApiUrl(membership)}/capability`);
      } catch (err) {
        logError(err);
      }
    }
    const relationData = { company__relation: RELATIONS.FOUNDER, point_of_contact: membership.point_of_contact };
    const data = { ...membership.user, company: resRef(company), ...relationData };
    // User was already a founder (only modifying it's point of contact state
    if (membership.id) {
      return yield patch(
        `${API_BASE}/invites/teammember/${membership.id}`,
        { data: formDataToJApi(SAMPLE_INVITE_TEAMMEMBER, relationData) },
      );
    }
    if (membership.user.isNew) {
      return yield post(`${API_BASE}/invites/teammember`, { data: formDataToJApi(SAMPLE_INVITE_TEAMMEMBER, data) });
    }
    if (membership.user.id > 0) {
      const existingTeamOrRelatedMembership = currentTeamOrRelatedMembership
        .find((teamMembership) => membership.user.id === teamMembership.user.id);
      if ([RELATIONS.EMPLOYEE, RELATIONS.OTHER].includes(existingTeamOrRelatedMembership?.relation)) {
        // Promote EMPLOYEE or OTHER (aka. Related) to FOUNDER
        return yield patch(
          `${API_BASE}/invites/teammember/${existingTeamOrRelatedMembership.id}`,
          { data: formDataToJApi(SAMPLE_INVITE_TEAMMEMBER, relationData) }
        );
      }
      const profile = yield updateObjFromApi(membership.user, null, '', true);
      // Invite Existing
      return yield post(
        `${API_BASE}/invites/teammember`,
        { data: formDataToJApi(SAMPLE_INVITE_TEAMMEMBER, { ...data, existing: true, email: profile.email }) }
      );
    }
    // all previous cases should have covered everything
    return null;
  };
};

export function* saveCompanyFoundersRelsSaga(action) {
  const multiFormMainField = 'founderMemberships';
  if (!action.values[multiFormMainField]) return null;
  const company = yield select(makeSelectObject(action.objRef));
  const founderRelSaga = makeSaveCompanyFounderRelSaga(company);
  const coachRelsRequests = yield all((
    action.values[multiFormMainField].map((membership) => callAndCatch(founderRelSaga, membership))
  ));
  yield handleCallAndCatches(coachRelsRequests, action, multiFormMainField);
  yield updateObjFromApi(action.objRef, 'current_members_rel.user');
  return null;
}

export function* saveCompanyPortfolioSectionSaga(action) {
  const multiFormMainField = 'erogated_investments';
  const investmentRequests = yield all(action.values[multiFormMainField].map((investment) => callAndCatch(savePortfolioInvestmentSaga, { ...investment, investing_company: action.objRef })));
  yield all(investmentRequests.map((investmentRequest) => put(updateIncludedFromRequest(investmentRequest))));
  yield handleCallAndCatches(investmentRequests, action, multiFormMainField);
  yield updateObjFromApi(action.objRef, multiFormMainField);
}

export function* saveCompanyInvestorsSectionSaga(action) {
  let errors = {};
  const options = [
    { field: 'fundInvestments', saga: saveCompanyInvestor },
    { field: 'angelInvestments', saga: saveCompanyInvestor },
    { field: 'advisorRels', saga: saveCompanyAdvisor },
  ];
  for (let i = 0; i < options.length; i++) {
    const { field, saga } = options[i];
    if (action.values[field]) {
      const invitations = yield all(action.values[field].map((invitation) => {
        if (field === 'advisorRels' && [null, undefined].includes(invitation.user)) return null;
        return callAndCatch(saga, invitation, action.objRef);
      }));

      const { responses, errors: superFieldErrors } = splitResponsesAndErrors(invitations, field);
      yield all(responses.map((response) => put(updateIncludedFromRequest(response))));
      if (superFieldErrors) {
        errors = { ...errors, ...superFieldErrors };
      }
    }
  }
  if (isEmpty(errors)) {
    yield getCompany();
    yield put(action.successAction);
  } else {
    yield put(stopSubmit(action.section, errors));
  }
}


function* addNewInvestorsAdvisor(profileToAdd) {
  try {
    const newInvitationRequest = yield call(
      post,
      '/api/v1/people?_form=add_contact',
      { data: formDataToJApi(SAMPLE_ADD_CONTACT, profileToAdd) }
    );
    yield put(updateIncludedFromRequest(newInvitationRequest));
    yield put(setUserByEmail(profileToAdd.email, newInvitationRequest.data));

    return newInvitationRequest.data;
  } catch (err) {
    let error = '';
    let foundUniquenessError;
    try {
      const errorJson = yield err.response.json();
      errorJson.errors.forEach((currentError) => {
        if (isUniquenessError(currentError)) {
          foundUniquenessError = true;
        }
        error += `${currentError.detail}; `;
      });
    } catch (e) {
      error = err.toString();
    }
    if (foundUniquenessError) {
      const usersByEmail = yield select(makeSelectUsersByEmail());
      if (usersByEmail[profileToAdd.email]) {
        return usersByEmail[profileToAdd.email];
      }
    }
    throw error;
  }
}

function* saveCompanyInvestor(investment) {
  const newInvestment = { ...investment };
  try {
    newInvestment.investing_company = yield createNewCompany(newInvestment.investing_company);
  } catch (err) {
    throw new ProcessedError('investing_company', 'Error adding new company');
  }
  if (newInvestment.investor_profile && newInvestment.investor_profile.isNew) {
    try {
      newInvestment.investor_profile = yield addNewInvestorsAdvisor({ ...investment.investor_profile, company: newInvestment.investing_company });
    } catch (err) {
      throw new ProcessedError('investor_profile', 'Error adding new investor');
    }
  }

  return yield call(
    requestFunctionForObject(newInvestment),
    `${refApiUrl(newInvestment)}?include=investment_round.investments,investor_profile,investing_company`,
    {
      data: formDataToJApi(
        { ...SAMPLE_INVESTMENT, id: newInvestment.id },
        { verified: false, ...newInvestment }
      ),
    }
  );
}


function* saveCompanyAdvisor(advisorMembership, companyRef) {
  const newAdvisorMembership = { ...advisorMembership };
  if (newAdvisorMembership.user.isNew) {
    try {
      newAdvisorMembership.user = yield addNewInvestorsAdvisor(newAdvisorMembership.user);
    } catch (err) {
      throw new ProcessedError('investor_profile', 'Error adding new advisor');
    }
  }
  return yield call(
    requestFunctionForObject(newAdvisorMembership),
    `${refApiUrl(newAdvisorMembership)}?include=company.current_members_rel.user`,
    {
      data: formDataToJApi(
        { ...SAMPLE_MEMBERSHIP, id: newAdvisorMembership.id },
        {
          relation: RELATIONS.ADVISOR, verified: false, company: companyRef, ...newAdvisorMembership,
        }
      ),
    }
  );
}

export function* deleteAdvisorOrPOC(action) {
  const { formName, field, value } = action;
  yield put(change(formName, field, value));
}

// typeof selectedDd.aclass?.value === 'number' means there is a valid alchemist class id.
function* handleCompanyAclassForm(action) {
  const company = yield select(makeSelectCompany());
  const currentAclassDdRel = getCurrentStartupAclassRel(company);
  const { values: { demo_day_to_join: aclass, stage, fundraise_goal: fundraiseGoal } } = action;

  // If there is a current entry and admin deleted it or admin changed to 'Will not participate in future Demo Day'
  if (currentAclassDdRel?.id
    && (!aclass || aclass?.label === WILL_NOT_PARTICIPATE_DD_OPT.label)
  ) {
    return { type: RESOURCE_DEMO_DAY_COMPANIES, id: currentAclassDdRel.id, isDeleted: true };
  }

  // If there is a current entry and admin is updating its attributes and class relationship for that entry
  if (typeof aclass?.value === 'number' && currentAclassDdRel?.aclass?.().id !== undefined) {
    return {
      type: RESOURCE_DEMO_DAY_COMPANIES,
      id: currentAclassDdRel?.id,
      data: {
        ...currentAclassDdRel.resRef,
        attributes: {
          stage: stage?.value,
          fundraise_goal: fundraiseGoal || null,
        },
        relationships: {
          aclass: { data: {
            type: RESOURCE_ALCHEMIST_CLASSES,
            id: aclass?.value,
          } },
        },
      },
    };
  }

  // If admin updates with new class id of which DD the startup will participate in
  if (typeof aclass?.value === 'number') {
    return {
      data: {
        type: RESOURCE_DEMO_DAY_COMPANIES,
        attributes: {
          stage: stage?.value,
          fundraise_goal: fundraiseGoal || null,
        },
        relationships: {
          company: { data: action.objRef },
          aclass: { data: {
            type: RESOURCE_ALCHEMIST_CLASSES,
            id: aclass?.value,
          } },
        },
      },
    };
  }
  return null;
}

// eslint-disable-next-line consistent-return
export function* submitCompanyHeaderForm(action) {
  const editedCompany = yield select(makeSelectObject(action.objRef));
  const isAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
  const formHeaderReqs = [{ ...action.values, ...action.objRef }];
  try {
    if (isAdmin) {
      // values for edit demo day settings
      const aClassDemoDayRelReq = yield handleCompanyAclassForm(action);
      formHeaderReqs.push(aClassDemoDayRelReq);

      // values for aa invite startup popup
      if (action.aaInviteFormValues?.company && action.aaInviteFormValues?.aclass) {
        const inviteCompanyReq = yield inviteCompany({ values: action.aaInviteFormValues, section: action.section }, true);
        formHeaderReqs.push(inviteCompanyReq);
      }

      // values for ax invite startup popup
      if (action.axInviteFormValues?.company && action.axInviteFormValues?.aclass) {
        const inviteCompanyReq = yield inviteCompany({ values: action.axInviteFormValues, section: action.section }, true);
        formHeaderReqs.push(inviteCompanyReq);
      }

      // `aclasses_rel` relationship is pointing to `aclass_companies` resources
      // If startup already has AA class and this currently saved AA class in the db is not equal to AA class field,
      // patch the startup's AA aclass_companies entry.
      const savedAaClassRel = editedCompany?.aclasses_rel?.().filter((a) => a.aclass?.().class_type === CLASS_TYPE.alchemist)?.[0];
      if (savedAaClassRel?.id && savedAaClassRel.aclass?.().id !== action.aaClass?.id) {
        formHeaderReqs.push({
          ...savedAaClassRel,
          aclass: action.aaClass,
        });
      }

      // if admin removes an ax class, code below deletes its corresponding aclass_companies entry
      const savedAxClassRels = editedCompany?.aclasses_rel?.().filter((a) => a.aclass?.().class_type === CLASS_TYPE.alchemistx);
      const currentAxClasses = action.axInviteFormValues?.aclass?.class_type === CLASS_TYPE.alchemistx
        ? action.axClasses.filter((axClass) => axClass?.id !== action.axInviteFormValues?.aclass?.id).map((axClass) => axClass?.id)
        : action.axClasses.map((axClass) => axClass?.id);
      const toBeDeletedAxClassRels = savedAxClassRels?.filter((axRel) => !currentAxClasses.includes(axRel?.aclass?.().id));
      if (toBeDeletedAxClassRels?.length > 0) {
        formHeaderReqs.push(...toBeDeletedAxClassRels.map((axRel) => ({ ...axRel, isDeleted: true })));
      }
    }

    yield call(atomicReq, formHeaderReqs.filter((req) => req));
    if (action.successAction && Array.isArray(action.successAction)) {
      yield all(action.successAction.map((s) => put(s)));
    } else if (action.successAction) {
      yield put(action.successAction);
    }
    yield getCompany();
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    yield handleAtomicCallAndCatches(errorJson[ATOMIC_RESULTS], action);
  }
}

export function* submitCompanyStatusForm(action) {
  const newAction = { ...action };
  const hasShutdownDateChanged = newAction.values.shutdowndate || newAction.values.shutdowndate === null;
  const hasTuitionPaidChanged = [true, false].includes(newAction.values.tuition_paid);

  if (
    (hasShutdownDateChanged || hasTuitionPaidChanged)
    && !newAction.values.acquireddate
    && newAction.values.acquired_by
  ) {
    delete newAction.values.acquired_by;
  }

  if (newAction.values.acquired_by && !newAction.values.acquireddate) {
    const company = yield select(makeSelectCompany());
    newAction.values.acquireddate = company.acquireddate;
  }

  return yield saveFormSection(newAction);
}

export function* initializeAaClassDdRelFields() {
  const isAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
  if (isAdmin) {
    const editedCompany = yield select(makeSelectCompany());
    const aClassDdRel = getCurrentStartupAclassRel(editedCompany);
    if (aClassDdRel) {
      yield put(change('companyHeader', 'demo_day_to_join', aClassDdRel.aclass
        ? {
          label: `Class ${aClassDdRel.aclass().title_or_number}`,
          value: aClassDdRel.aclass().id,
          title_or_number: aClassDdRel.aclass().title_or_number,
        } : WILL_NOT_PARTICIPATE_DD_OPT));
      yield put(change('companyHeader', 'stage', aClassDdRel.stage ? { label: aClassDdRel.stage, value: aClassDdRel.stage } : null));
      yield put(change('companyHeader', 'fundraise_goal', aClassDdRel.fundraise_goal || ''));
    }
  }
}
