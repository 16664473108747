import { makeSelectObjectFromRefSelector, makeSelectObjectArrayFromRefArray } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';

/**
 * Direct selector to the addContact state domain
 */
const selectAddContactDomain = (state) => state.get('addContact');

/**
 * Other specific selectors
 */
const makeSelectSchema = () => makeSubSelector(selectAddContactDomain, ['schema']);
const selectIsCheckingContact = makeSubSelector(selectAddContactDomain, ['isCheckingContact']);
const selectMatchedUserRef = makeSubSelector(selectAddContactDomain, ['matchedUser']);
const selectShowInviteForm = makeSubSelector(selectAddContactDomain, ['showInviteForm']);
const selectMatchedUser = makeSelectObjectFromRefSelector(selectMatchedUserRef);
const selectSelectedProfileRef = makeSubSelector(selectAddContactDomain, ['selectedProfile']);
const selectMatchRefs = makeSubSelector(selectAddContactDomain, ['matchRefs']);
const selectMatchProfiles = makeSelectObjectArrayFromRefArray(selectMatchRefs);
const selectProfileSelectionOpen = makeSubSelector(selectAddContactDomain, ['profileSelectionOpen']);
const selectIsExactMatch = makeSubSelector(selectAddContactDomain, ['isExactMatch']);
const selectFromModal = makeSubSelector(selectAddContactDomain, ['fromModal']);
const selectCheckContactValues = makeSubSelector(selectAddContactDomain, ['checkContactValues']);

const selectPreviewDetails = makeSubSelector(selectAddContactDomain, ['previewDetails']);
const selectInviteAsNew = makeSubSelector(selectAddContactDomain, ['inviteAsNew']);

export {
  makeSelectSchema,
  selectIsCheckingContact,
  selectMatchedUser,
  selectShowInviteForm,
  selectMatchProfiles,
  selectProfileSelectionOpen,
  selectIsExactMatch,
  selectSelectedProfileRef,
  selectCheckContactValues,
  selectFromModal,
  selectPreviewDetails,
  selectInviteAsNew,
};
