import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import noop from 'lodash/noop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { expandListContainer } from 'containers/People/PeopleLists/actions';
import { makeSelectExpandedListContainer, makeSelectSearch } from 'containers/People/PeopleLists/selectors';
import ExpandButon from 'components/Buttons/ExpandButton';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import LoadingSingle from 'components/LoadingSpinner/LoadingSingle';
import { getListsPerRowNumber } from 'containers/People/PeopleLists/utils';
import Warning from 'components/Text/Warning';
import { LIST_TYPES } from 'containers/People/PeopleLists/constants';

const ListSection = ({ title, children, buttonsComponent: Buttons, expandedContainer,
  containerId, expandSection, isMobileTablet, listsNumber, search, loadNextPage, loading, hasMore, loadingNextPage,
  isArchive, buttonsProps, badgeText, iconForTitle,
}) => {
  const isAdminList = [LIST_TYPES.event, LIST_TYPES.admin].includes(containerId);
  const containerIsExpanded = expandedContainer === containerId;
  // number of displayed lists depends on screen width
  const listsPerRow = getListsPerRowNumber();
  const [displayedLists, setDisplayedLists] = useState([]);

  const expandListButton =
    <div className="w-100 text-center my-5">
      <ExpandButon
        onClick={() => expandSection(containerId)}
        text={containerIsExpanded ? 'Collapse' : 'Show all lists'}
        expanded={containerIsExpanded}
      />
    </div>;

  useEffect(() => {
    if (containerIsExpanded) {
      setDisplayedLists(children);
    } else {
      // timeout is needed to make css smooth max-height animation work. slice items after className is changed.
      setTimeout(() => setDisplayedLists(children.slice(0, listsPerRow)), 500);
    }
  }, [containerIsExpanded, children, listsPerRow]);

  if (isMobileTablet) {
    return isArchive ? (
      <>
        <div className={classNames('dsa-people-lists__section__title', { 'dsa-admin-txt': isAdminList })}>{`${title} Archived (${children.length})`}</div>
        <div className="dsa-people-lists__section__buttons"><Buttons /></div>
        <div className="dsa-people-lists__container">
          {children}
        </div>
      </>
    ) : (
      <AccordionItem>
        <AccordionHeader className={isAdminList ? 'dsa-admin-txt' : ''} targetId={title}>
          {iconForTitle && <FontAwesomeIcon icon={iconForTitle} className="me-3" />}
          {`${title} ${search ? `(${listsNumber})` : ''}`}
        </AccordionHeader>
        <AccordionBody accordionId={title}>
          <div className="dsa-people-lists__section__buttons"><Buttons /></div>
          {/* {buttonsProps?.showArchiveLink && <ArchivedListsButton className="my-5" to={buttonsProps.linkTo} />} */}
          <div className={`dsa-people-lists__container ${containerIsExpanded ? '_expanded_' : '_collapsed_'}`}>
            <InfiniteScroll
              pageStart={1}
              hasMore={!loading && !loadingNextPage && hasMore && containerIsExpanded}
              loadMore={loadNextPage || noop}
              loader={null}
              useWindow={false}
              className="d-flex flex-wrap w-100"
              initialLoad={false}
            >
              {children}
              {loadingNextPage && <div className="center-content-x w-100"><LoadingSingle /></div>}
            </InfiniteScroll>
          </div>
          {children.length >= 3 && expandListButton}
        </AccordionBody>
      </AccordionItem>
    );
  }

  if (isArchive) {
    return (
      <div className={classNames('dsa-people-lists__section', { 'dsa-admin-section': isAdminList })} data-testid={title}>
        <div className="dsa-people-lists__section__title">{title}</div>
        <div className="dsa-people-lists__section__buttons"><Buttons {...buttonsProps} /></div>
        {badgeText && <Warning text={badgeText} className="mt-5" />}
        <div className="dsa-people-lists__container">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('dsa-people-lists__section', { 'dsa-admin-section': isAdminList })} data-testid={title}>
      <div className="dsa-people-lists__section__title">
        {iconForTitle && <FontAwesomeIcon icon={iconForTitle} className="me-3" />}
        {title}
      </div>
      <div className="dsa-people-lists__section__buttons"><Buttons showArchiveLink {...buttonsProps} /></div>
      {badgeText && <Warning text={badgeText} className="mt-5" />}
      <div className={`dsa-people-lists__container ${containerIsExpanded ? '_expanded_' : '_collapsed_'}`}>
        <InfiniteScroll
          pageStart={1}
          hasMore={!loading && !loadingNextPage && hasMore && containerIsExpanded}
          loadMore={loadNextPage || noop}
          loader={null}
          useWindow={false}
          className="d-flex flex-wrap"
          initialLoad={false}
        >
          {displayedLists}
          {loadingNextPage && <div className="center-content-x-y ml-7"><LoadingSingle /></div>}
        </InfiniteScroll>
      </div>
      {children.length > 0 && (listsPerRow < children.length) && expandListButton}
    </div>
  );
};


ListSection.propTypes = {
  title: PropTypes.string,
  buttonsComponent: PropTypes.any,
  children: PropTypes.any,
  expandedContainer: PropTypes.string,
  containerId: PropTypes.string,
  expandSection: PropTypes.func,
  isMobileTablet: PropTypes.bool,
  listsNumber: PropTypes.number,
  search: PropTypes.string,
  badgeText: PropTypes.string,
  buttonsProps: PropTypes.object,
  isArchive: PropTypes.bool,
  loadNextPage: PropTypes.func,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadingNextPage: PropTypes.bool,
  iconForTitle: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  expandSection: (containerId) => dispatch(expandListContainer(containerId)),
});

const mapStateToProps = createStructuredSelector({
  expandedContainer: makeSelectExpandedListContainer(),
  search: makeSelectSearch(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(ListSection));
