/* eslint-disable consistent-return */
/* eslint-disable import/no-import-module-exports */
/**
 * Create the store with dynamic reducers
 */

import { createBrowserHistory } from 'history';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createTracker } from 'redux-segment';
import jwtDecode from 'jwt-decode';

import { isTokenExpired, reloginPath } from 'utils/auth';
import { createOurSentryMiddleware } from 'containers/Analytics/helpers';
import { setLastPage, toJS } from 'utils/general';
import { FINISH_LOGOUT, START_LOGOUT } from 'containers/AuthProvider/constants';
import { logToSentry } from 'utils/log';

import createReducer, { SET_IS_AUTH_ROUTE } from './reducers';

const tokenExpirationMiddleware = (store) => (next) => (action) => {
  try {
    const currentToken = localStorage.getItem('token');
    const decodedToken = currentToken ? jwtDecode(currentToken) : null;

    // don't check token for these actions
    if (action.type === START_LOGOUT || action.type === FINISH_LOGOUT || action.type === SET_IS_AUTH_ROUTE) {
      return next(action);
    }
    // check if the token is expired
    if (decodedToken && isTokenExpired(decodedToken)) {
      const state = store.getState();
      if (!!state && toJS(state)?.authRoute?.isUserOnAuthRoute) {
        setLastPage();
        window.location = reloginPath(decodedToken);
        return;
      }
    }

    // token is valid, proceed
    return next(action);
  } catch (err) {
    logToSentry(err);
    // String 'undefined' means there is token in the localStorage and its value is 'undefined'
    if (localStorage.getItem('token') === 'undefined') {
      localStorage.removeItem('token');
    }
    setLastPage();
    window.location = '/signin';
  }
};

const sagaMiddleware = createSagaMiddleware();
const sentryMiddleware = createOurSentryMiddleware();

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {
  // Create the store with three middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. tokenExpirationMiddleware: handle token expiration and force user to relogin if he's on auth route
  const middlewares = [
    sentryMiddleware,
    sagaMiddleware,
    tokenExpirationMiddleware,
    window.analytics && createTracker(),
    routerMiddleware(history),
  ].filter(Boolean);

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
      typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
        // Prevent recomputing reducers for `replaceReducer`
        shouldHotReload: false,
        maxAge: 200,
      })
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(null, history),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers, history));
    });
  }

  return store;
}

const initialState = {};
export const store = configureStore(initialState);
