import { push } from 'connected-react-router';
import fromPairs from 'lodash/fromPairs';
import { parse, stringify } from 'qs';
import { call, put, takeLatest, select, fork, delay, take } from 'redux-saga/effects';
import { filter, find, orderBy, sortBy } from 'lodash';

import { updateIncluded } from 'actions';
import { showAlert } from 'containers/AlertBox/actions';
import {
  API_ALCHEMIST_CLASSES_BASE_URL,
  API_BASE,
  API_PEOPLE_BASE_URL,
  API_ACCOUNTS_BASE_URL,
  CLASS_TYPE,
  PERMISSION,
  RESOURCE_ALCHEMIST_CLASSES,
  VARIETY,
  API_SIGNATURES_BASE_URL,
  CAPABILITIES,
} from 'containers/App/constants';
import { DEMODAY_INVITE, FLOW_TYPE, FLOW_TYPE_TO_URL } from 'containers/AuthProcess/constants';
import { showHints } from 'containers/Hints/saga';
import {
  getDecodedToken,
  reloginPath,
  ssoTokenIsSetAndCurrent,
  getSsoTokenFromCookie,
  setLmsSsoCookie,
  deleteLmsSsoCookie,
} from 'utils/auth';
import { addDays } from 'utils/dateTime';
import { setLastPage } from 'utils/general';
import { extractData, flattenJAPIObject } from 'utils/jsonApiExtract';
import { logError } from 'utils/log';
import { postRaw, refreshTokenFromHeader, request } from 'utils/request';
import { injectRelationship, updateObjFromApi, pushLocation, waitAndSelectAccount, waitSelect } from 'utils/sagas';
import { makeSelectLocation, makeSelectQueryParam } from 'containers/App/selectors';
import { ALCH_OVERVIEW_KEY } from 'containers/Admin/VaultSenders/constants';
import { resRef } from 'utils/refs';
import { orderClasses } from 'utils/postProccessObject';
import { changeThemeToDefault, resetLocalStorageTheme } from 'utils/theme';
import { showCapabilityNotifs } from 'containers/Notifications/Capabilities/saga';
import { loadSidebarLinks } from 'containers/EditSidebarItemModal/saga';
import { setFounderChooseModal } from 'containers/FounderChooseModal/actions';

import { DEIMPERSONATE, IMPERSONATE_USER, INITIAL_CHECK_TOKEN, LOAD_PROFILE, START_LOGOUT, LOAD_HINTS, LOAD_LISTS_FOR_MODAL, FULL_ACCOUNT_DATA_LOADED } from './constants';
import {
  finishLogout,
  fullAccountDataLoaded,
  fullCompaniesDataLoaded,
  loadHintsAction,
  loadProfileAction,
  setActiveAxClasses,
  setCompanyTheme,
  setHints,
  setIsAuthenticated,
  setIsChecking,
  setListsForModalLoaded,
  setListsForModalLoading,
  setProfile,
  setToken,
  setUpcomingClass,
  setUpcomingClassX,
  updateSignatures,
  updateSiteSettings,
} from './actions';
import {
  makeSelectAccount,
  makeSelectMyCompanyTheme,
  makeSelectHighestUserAccess,
  makeSelectProfile,
  makeSelectUser,
  makeSelectUserIs,
  makeSelectUserIsOneOf,
  makeSelectAlchemistClassUsing,
  makeSelectMyCompany,
} from './selectors';
import { addJiraCollector } from '../../components/BugReporting/bugUtils';

/**
 * It's not a login flow, it's basically fetching user details.
 * Called every time with initialCheck saga
 */
export function* baseLoginFlow() {
  const user = yield select(makeSelectUser());
  yield put(loadProfileAction(user.profileId));
  yield put(setIsAuthenticated(true));
  yield put(setIsChecking(false));
}

/**
 * There are different login flows: FLOW_TYPE
 * Different endpoints trigger different flow: FLOW_TO_LOGIN_ENDPOINT
 */
export function* loginFlow(flow, linkedInCode) {
  const ddInviteFlag = yield select(makeSelectQueryParam('for'));
  const ddInviteQs = ddInviteFlag ? `${linkedInCode ? '&' : '?'}for=${ddInviteFlag}` : '';

  if (flow === FLOW_TYPE.lmsLogin) {
    const token = ssoTokenIsSetAndCurrent();
    if (token) {
      // &jwt= is not required but adds support for localhost dev env.
      window.location = `${token.lms_domain}${FLOW_TYPE_TO_URL[flow]}&jwt=${getSsoTokenFromCookie()}`;
      return;
    }
  }

  yield baseLoginFlow();

  if (flow === FLOW_TYPE.register && ddInviteFlag?.toLowerCase() === DEMODAY_INVITE) {
    yield put(push(`/demo-day/express-interest/success?for=${ddInviteFlag}`));
    return;
  }

  if (flow === FLOW_TYPE.demoDayInvitation) {
    yield redirectToDdAttendance(flow, linkedInCode, ddInviteFlag);
    return;
  }

  /**
   * Probably temporary solution, because it's binded to free connect hint(show only on login)
   * Must be moved to initialCheck (?)
   */
  yield put(loadHintsAction());

  if ([FLOW_TYPE.standard, FLOW_TYPE.onboarding].includes(flow)) {
    yield fork(loadFounderPopup);
  }

  /**
   * Last page was saved if there were problems with token(expired, etc)
   */
  if (localStorage.last_page && [FLOW_TYPE.standard, FLOW_TYPE.onboarding].includes(flow)) {
    const onboardingActivationLink = localStorage.last_page.includes('/onboarding/') && localStorage.last_page.includes('?')
      ? localStorage.last_page.split('?')?.[0]
      : null;
    yield put(push(onboardingActivationLink || localStorage.last_page));
    localStorage.removeItem('last_page');
    return;
  }

  yield put(push(`${FLOW_TYPE_TO_URL[flow] || '/'}${linkedInCode ? `?code=${linkedInCode}` : ''}${ddInviteQs}`));
}

function* loadProfile(action) {
  const include = ['account', 'roles', 'demo_day_users_rel.demo_day.aa_class', 'ifs_invitations.participant_set.ifs', 'location'];

  const user = yield select(makeSelectUser());

  if (user?.roles?.includes(PERMISSION.class_coach)) {
    include.push('coach_classes');
  }

  const isFounder = user?.roles?.includes(PERMISSION.founder);
  if (isFounder) {
    include.push('company');
  }

  const requestURL = `${API_PEOPLE_BASE_URL}/${action.profileId}?include=${Array.from(new Set(include)).join(',')}${isFounder ? '&fields[companies]=id,name,aclass_id' : ''}`;
  const profileData = yield call(request, requestURL);

  const { inclusions, items: [profileRef] } = extractData(profileData);
  yield put(updateIncluded(inclusions));
  yield put(setProfile(profileRef));

  yield fork(loadUserCompaniesData);
  yield fork(loadAccountData);

  yield loadEditableSidebarLinks();

  if (yield select(makeSelectUserIs('admin'))) {
    yield fork(loadSiteSettings);
    yield fork(loadSignatures);
    yield fork(loadActiveDemoDayClass);
    addJiraCollector();
  }
}

function* loadHints() {
  const myAccount = yield waitAndSelectAccount();
  if (!myAccount) return; // loadHints fired for all logins, but not all users have accounts

  // only show hints to admins, founders and class_coach
  const shouldShow = yield select(makeSelectUserIsOneOf([PERMISSION.admin, PERMISSION.founder, PERMISSION.class_coach]));
  if (!shouldShow) return;

  const requestURL = `${API_ACCOUNTS_BASE_URL}/${myAccount.id}/unseen_hints`;
  const hintsData = yield call(request, requestURL);

  const { inclusions, items } = extractData(hintsData);
  yield put(updateIncluded(inclusions));
  yield put(setHints(items));

  yield showHints();
}

export function* loadActiveDemoDayClass() {
  const filterStr = stringify({
    filter: {
      'demo_date:ge': addDays(new Date(), -13 * 7),
      'demo_date:le': addDays(new Date(), 60),
      '_2:class_type:eq': CLASS_TYPE.alchemist,
      '_2:demo_day.invites_sent:eq': 'True',
      '_3:class_type:eq': CLASS_TYPE.alchemistx,
    },
    fop: { '_1:and:[_2:or:_3]': '' },
    sort: '-demo_date',
    fields: { [RESOURCE_ALCHEMIST_CLASSES]: 'id,number,demo_date,class_type,title,demo_access_end_date_pst_aware,demoday_active_until,demo_date_pst_aware' },
  });
  const upcomingClassRequest = yield request(`${API_ALCHEMIST_CLASSES_BASE_URL}?${filterStr}`);
  const { inclusions } = extractData(upcomingClassRequest);
  const { alchemist_classes: alchemistClasses } = inclusions;
  // This line below is added for the sake of passing functional tests
  if (!alchemistClasses) return;

  const aClasses = orderBy(Object.values(alchemistClasses)?.map((c) => flattenJAPIObject(c)), (a) => a.demo_date_pst_aware, ['desc']);
  yield put(updateIncluded(inclusions));
  yield put(setUpcomingClass(find(aClasses, (aclass) => aclass?.class_type === CLASS_TYPE.alchemist)));

  const axClasses = aClasses.filter((aclass) => aclass?.class_type === CLASS_TYPE.alchemistx);
  const orderedAxClasses = orderClasses(axClasses);
  yield put(setUpcomingClassX(orderedAxClasses?.[0]));
  yield put(setActiveAxClasses(orderedAxClasses?.map((c) => resRef(c))));
}

export function* getAlchemistClassUsing(aaClassNumber) {
  const location = yield select(makeSelectLocation());
  const alchemistClassRequest = yield call(request, `${API_ALCHEMIST_CLASSES_BASE_URL}/${location.pathname.includes('/ax') ? CLASS_TYPE.alchemistx : CLASS_TYPE.alchemist}/${aaClassNumber}`);
  const { inclusions } = extractData(alchemistClassRequest);
  yield put(updateIncluded(inclusions));
  const updatedClass = yield waitSelect(makeSelectAlchemistClassUsing(aaClassNumber));
  return updatedClass;
}

export function* loadUserCompaniesData() {
  const myProfile = yield select(makeSelectProfile());
  const iAmRegistrant = yield select(makeSelectUserIs(PERMISSION.registration));
  const iAmAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
  const iAmFounder = yield select(makeSelectUserIs(PERMISSION.founder));
  // admin, founder and class coach are the only principals that use onboarding in the token
  const iAmOnboarding = yield select(makeSelectUserIs(PERMISSION.onboarding));
  if (iAmRegistrant && !iAmAdmin) {
    return;
  }
  let relsToLoad = ['company', 'company_membership.company'];
  // iAmOnboarding is added to catch founders who are still onboarding
  if (iAmFounder || iAmOnboarding) {
    relsToLoad = ['company.aclass', 'company.team_notes.profile', 'company.aclasses_rel.aclass', 'company_membership.company.aclass'];
  }
  yield updateObjFromApi(myProfile, relsToLoad);
  const theme = yield select(makeSelectMyCompanyTheme());
  yield put(setCompanyTheme(theme));
  yield put(fullCompaniesDataLoaded());
}

function* loadAccountData() {
  const account = yield select(makeSelectAccount());
  if (!account) return null;

  const myProfile = yield select(makeSelectProfile());
  yield injectRelationship(account, 'profile', myProfile);

  const userHighestAccess = yield select(makeSelectHighestUserAccess());
  const isOnboarding = yield select(makeSelectUserIs(PERMISSION.onboarding));
  const isNetworkOnboarding = yield select(makeSelectUserIs(PERMISSION.network_onboarding));
  const isFounder = yield select(makeSelectUserIs(PERMISSION.founder));

  let accountRels = [];

  if (isOnboarding || isNetworkOnboarding || userHighestAccess) {
    // isOnboarding is added to catch founders who are still onboarding
    if (isFounder || isOnboarding) {
      accountRels = accountRels.concat(['notifications',
        'capabilities_rel.onboarding_steps_rel.step', 'capabilities_rel.aclass_company.company', 'capabilities_rel.aclass_company.aclass', 'capabilities_rel.aclass_company.onboarding_steps_rel.step']);
    } else if (isNetworkOnboarding || userHighestAccess !== PERMISSION.demoday) {
      accountRels = accountRels.concat(['notifications', 'capabilities_rel']);
    } else {
      accountRels = accountRels.concat(['notifications']);
    }
  }

  if (userHighestAccess) {
    accountRels = accountRels.concat(['profile.areas_of_interest']);
  }

  if (isFounder) {
    accountRels = accountRels.concat(['point_of_contact_for']);
  }

  yield updateObjFromApi(account, accountRels);
  yield put(fullAccountDataLoaded());
  return null;
}

export function* loadLists() {
  const account = yield select(makeSelectAccount());
  const isAdmin = yield select(makeSelectUserIs(VARIETY.admin));
  const includes = ['shared_lists_with_me_rel.user_list.creator.profile', 'user_lists'];
  if (isAdmin) includes.push('event_lists', 'admin_lists');
  yield updateObjFromApi(
    account,
    `${Array.from(new Set(includes)).join()}&fields[people]=id,nicename&fields[peoplelists]=title,users_count`
  );
}

export function* loadSiteSettings() {
  try {
    const queryObj = { filter: { 'key:eq': ALCH_OVERVIEW_KEY } };
    const siteSettingsRequest = yield call(request, `${API_BASE}/sitesettings?${stringify(queryObj)}`);

    const siteSettings = fromPairs(siteSettingsRequest.data.map((siteSetting) => flattenJAPIObject(siteSetting)).map((siteSetting) => [siteSetting.key, siteSetting]));

    yield put(updateSiteSettings(siteSettings));
  } catch (err) {
    logError(err);
  }
}

export function* loadSignatures() {
  const queryObj = { sort: 'created_at' };
  const signaturesRequest = yield call(request, `${API_SIGNATURES_BASE_URL}?${stringify(queryObj)}`);
  const { inclusions, items } = extractData(signaturesRequest);
  yield put(updateIncluded(inclusions));
  yield put(updateSignatures(sortBy(items, (item) => item.id)));
}

function* loadEditableSidebarLinks() {
  const allowedPrincipals = [PERMISSION.founder, PERMISSION.class_coach, PERMISSION.admin];
  const isFounderOrAdminOrCoach = yield select(makeSelectUserIsOneOf(allowedPrincipals));
  // admin, founder and class coach are the only principals that use onboarding in the token
  const iAmOnboarding = yield select(makeSelectUserIs(PERMISSION.onboarding));
  if (isFounderOrAdminOrCoach || iAmOnboarding) {
    yield fork(loadSidebarLinks);
  }
}

function* loadFounderPopup() {
  try {
    const myProfile = yield waitSelect(makeSelectProfile());
    const iAmFounder = yield select(makeSelectUserIs(PERMISSION.founder));
    // admin, founder and class coach are the only principals that use onboarding in the token
    const iAmOnboarding = yield select(makeSelectUserIs(PERMISSION.onboarding));

    // iAmOnboarding is added to catch founders who are still onboarding
    if (iAmFounder || iAmOnboarding) {
      yield updateObjFromApi(myProfile?.account?.(), 'capabilities_rel');
      const myAccount = yield select(makeSelectAccount());
      const activeFounderCapabilities = myAccount?.capabilities_rel?.().filter((c) => c.capability === CAPABILITIES.founder && c.active);

      if (activeFounderCapabilities?.length > 1) {
        const myCompany = yield waitSelect(makeSelectMyCompany());
        const activeClasses = filter(myCompany?.aclasses_rel?.(), (aRel) => aRel?.aclass?.().considered_active
          || new Date() < new Date(aRel?.aclass?.().demo_date_pst_aware)
          || aRel?.class_type === CLASS_TYPE.alchemist);

        if (activeClasses?.length > 1) {
          yield put(setFounderChooseModal(true));
        }
      }
    }
  } catch (err) {
    logError(err);
  }
}

export function* forceReloadTokenByReLogIn() {
  localStorage.removeItem('token');
  window.location = reloginPath();
}

function* initialCheck() {
  const { token } = localStorage;
  if (!token || window.location.pathname.match(/^\/oauthlanding/)) {
    return;
  }

  yield put(setIsChecking(true));
  // ToDo: should maybe check if the token is expired before checking?
  const resp = yield call(postRaw, '/check_token', { token });
  if (resp.status === 200) {
    yield refreshTokenFromHeader(resp);
    yield put(setToken());
    yield baseLoginFlow();

    if (localStorage.last_page) {
      yield pushLocation(localStorage.last_page);
      localStorage.removeItem('last_page');
    }

    return;
  }
  // expired
  const registeringUser = getDecodedToken();
  localStorage.removeItem('token');
  if (registeringUser && (
    registeringUser.no_account
    || window.location.pathname.match(/^\/class-website\/register/)
    || window.location.pathname.match(/^\/demo-day\/register/)
    || window.location.pathname.match(/^\/register-profile/))) {
    if (!registeringUser.no_account) {
      yield fork(
        alertUserWhenReady,
        `Detected previous platform login. If you have an account go to ${window.location.origin}/signin`
      );
    }
    yield put(setIsChecking(false));
    return;
  }
  // came from /login already
  if (localStorage.last_page) {
    localStorage.removeItem('last_page');
    yield pushLocation('/signin?fail=1');
  } else {
    setLastPage();
    window.location.href = reloginPath(registeringUser);
  }
  yield put(setIsChecking(false));
}

function* alertUserWhenReady(msg) {
  yield take(() => true);
  yield put(showAlert(msg));
}

function* logout() {
  // ToDo: actually make a server request to logout
  yield pushLocation('/signin');
  localStorage.removeItem('token');
  // clear session cookies
  document.cookie = 'alch_sess_x=; Max-Age=-99999999;'; // production
  document.cookie = 'alch_sess_x2=; Max-Age=-99999999;'; // staging

  // clear the lms sso cookie
  deleteLmsSsoCookie();

  // reset custom theme to default

  changeThemeToDefault();
  resetLocalStorageTheme();

  yield delay(100);
  yield put(finishLogout());
}

function* impersonateUser(action) {
  localStorage.impersonatorToken = localStorage.token;

  const lmsSsoToken = getSsoTokenFromCookie();
  if (lmsSsoToken) localStorage.impersonatorLmsSsoToken = lmsSsoToken;

  const resp = yield call(request, `/impersonate_user/${action.uid}`, { redirect: 'manual' });
  window.location.href = resp.meta.redirect_url;
}

function* deimpersonate() {
  if (localStorage.getItem('impersonatorToken') && localStorage.getItem('impersonatorToken') !== 'undefined') {
    localStorage.setItem('token', localStorage.getItem('impersonatorToken'));
  } else {
    localStorage.removeItem('token');
  }
  localStorage.removeItem('impersonatorToken');

  deleteLmsSsoCookie();
  if (localStorage.impersonatorLmsSsoToken) setLmsSsoCookie(localStorage.impersonatorLmsSsoToken, 'create');
  delete localStorage.impersonatorLmsSsoToken;

  window.location.href = '/people';
}

function* loadListsForModalSaga() {
  try {
    yield put(setListsForModalLoading());
    yield loadLists();
  } catch (err) {
    logError(err);
  } finally {
    yield put(setListsForModalLoaded());
  }
}
// This redirects to DD registration form (/demo-day/attendance) with query params: invitation id and class id
function* redirectToDdAttendance(flow, linkedInCode, ddInviteFlag) {
  const landingQParams = sessionStorage.getItem('landingQueryParams')
    ? parse(sessionStorage.getItem('landingQueryParams'), { ignoreQueryPrefix: true })
    : {};
  const qParamsStr = stringify({
    ...(linkedInCode ? { code: linkedInCode } : {}),
    ...(ddInviteFlag ? { for: ddInviteFlag } : {}),
    ...(landingQParams.invitation && landingQParams.class ? { invitation: landingQParams.invitation, class: landingQParams.class } : {}),
  });
  return yield put(push(`${FLOW_TYPE_TO_URL[flow] || '/'}${qParamsStr === '' ? '' : `?${qParamsStr}`}`));
}

export default function* defaultSaga() {
  yield takeLatest(INITIAL_CHECK_TOKEN, initialCheck);
  yield takeLatest(LOAD_PROFILE, loadProfile);
  yield takeLatest(LOAD_HINTS, loadHints);
  yield takeLatest(START_LOGOUT, logout);
  yield takeLatest(IMPERSONATE_USER, impersonateUser);
  yield takeLatest(DEIMPERSONATE, deimpersonate);
  yield takeLatest(LOAD_LISTS_FOR_MODAL, loadListsForModalSaga);
  yield takeLatest(FULL_ACCOUNT_DATA_LOADED, showCapabilityNotifs);
}
