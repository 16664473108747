import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { ButtonPrimSm } from 'components/Buttons/ButtonPrim';
import { ButtonSecSm } from 'components/Buttons/ButtonSec';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { openModalForList } from 'containers/People/PeopleListManageModal/actions';
import { SHARE_SECTION, EDIT_SECTION } from 'containers/People/PeopleListManageModal/constants';
import ArchivedListsButton from 'components/Buttons/Specific/ArchivedListsButton';
import LoadingSpinner from 'components/LoadingSpinner';

import { makeSelectAdminListMergeEnabled, makeSelectCopyAdminToEventListsLoading, selectMarkedAdminLists } from '../selectors';
import {
  archivePeopleLists,
  clearMarkedAdminLists as clearMarkedAdminListsAction,
  copyAdminToEventLists as copyAdminToEventListsAction,
  deletePeopleLists,
  openPeopleListCreateModal,
  openPeopleListMergeModal,
} from '../actions';
import { ADMIN_LISTS } from '../constants';


const AdminListsButtons = ({
  openShareModalForList, selectedAdminLists, clearMarkedAdminLists, openCreateListModal, deleteLists, isMergeEnabled, openMergeModal, isDesktop, showArchiveLink, linkTo, copyAdminToEventLists, isCopyingToEventLists, archiveLists,
}) => {
  const singleActionsEnabled = selectedAdminLists.length === 1;

  return (
    <>
      <div className="dsa-people-lists__section__buttons-bunch">
        <ButtonPrimSm className="dsa-admin-btn" disabled={!!selectedAdminLists.length} onClick={openCreateListModal}>New List</ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!singleActionsEnabled}
          onClick={() => openShareModalForList(selectedAdminLists[0], SHARE_SECTION)}
        >
          Share
        </ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!singleActionsEnabled}
          onClick={() => openShareModalForList(selectedAdminLists[0], EDIT_SECTION)}
        >
          Edit
        </ButtonPrimSm>
        <ButtonPrimSm className="dsa-admin-btn" disabled={!isMergeEnabled} onClick={openMergeModal}>Merge</ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!selectedAdminLists.length}
          onClick={deleteLists}
        >Delete
        </ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!selectedAdminLists.length}
          onClick={archiveLists}
        >
          Archive
        </ButtonPrimSm>
        <ButtonPrimSm
          className={classNames('dsa-admin-btn', { 'me-6': !isDesktop })}
          disabled={!selectedAdminLists.length || isCopyingToEventLists}
          onClick={copyAdminToEventLists}
        >
          Copy to Event Lists {isCopyingToEventLists && <LoadingSpinner className="dsa-copy-spinner d-inline-block p-0" size="lg" />}
        </ButtonPrimSm>
        {isDesktop && showArchiveLink && <ArchivedListsButton to={linkTo} className="ml-5 dsa-admin-txt" />}
      </div>
      {isDesktop &&
        <div className="dsa-people-lists__section__buttons-clear">
          <span className="me-5">{selectedAdminLists.length} list selected</span>
          <ButtonSecSm onClick={clearMarkedAdminLists}>Clear</ButtonSecSm>
        </div>}
    </>
  );
};

AdminListsButtons.propTypes = {
  openShareModalForList: PropTypes.func,
  clearMarkedAdminLists: PropTypes.func,
  openCreateListModal: PropTypes.func,
  openMergeModal: PropTypes.func,
  deleteLists: PropTypes.func,
  selectedAdminLists: PropTypes.array,
  isMergeEnabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  showArchiveLink: PropTypes.bool,
  linkTo: PropTypes.string,
  copyAdminToEventLists: PropTypes.func,
  isCopyingToEventLists: PropTypes.bool,
  archiveLists: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  selectedAdminLists: selectMarkedAdminLists,
  isMergeEnabled: makeSelectAdminListMergeEnabled(),
  isCopyingToEventLists: makeSelectCopyAdminToEventListsLoading(),
});

const mapDispatchToProps = (dispatch) => ({
  openShareModalForList: (personList, section) => dispatch(openModalForList(personList, section)),
  clearMarkedAdminLists: () => dispatch(clearMarkedAdminListsAction()),
  openCreateListModal: () => dispatch(openPeopleListCreateModal(false, true)),
  openMergeModal: () => dispatch(openPeopleListMergeModal()),
  deleteLists: () => dispatch(deletePeopleLists(ADMIN_LISTS)),
  copyAdminToEventLists: () => dispatch(copyAdminToEventListsAction()),
  archiveLists: () => dispatch(archivePeopleLists(ADMIN_LISTS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(AdminListsButtons));
