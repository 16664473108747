/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import { API_PROGARAM_SEARCH_BASE_URL } from 'containers/App/constants';
import {
  INITIAL_LOAD,
  LOAD_NEXT_PAGE,
  LOAD_CONTENT,
  LOAD_CONTENT_SUCCESS,
  REMOVE_ADV_FILTER,
  SET_SEARCH,
  SET_CATEGORY,
  APPLY_TEMP_FILTERS,
  TOGGLE_SORT,
  SET_SELECTED_OBJECT,
} from 'utils/filter/constants';
import { formatStr } from 'utils/general';


export const PROGRAM_PAGE_ID = 'Program';
export const PROGRAM_INITIAL_LOAD = formatStr(INITIAL_LOAD, PROGRAM_PAGE_ID);
export const PROGRAM_LOAD_NEXT_PAGE = formatStr(LOAD_NEXT_PAGE, PROGRAM_PAGE_ID);
export const PROGRAM_LOAD_CONTENT = formatStr(LOAD_CONTENT, PROGRAM_PAGE_ID);
export const PROGRAM_LOAD_CONTENT_SUCCESS = formatStr(LOAD_CONTENT_SUCCESS, PROGRAM_PAGE_ID);
export const PROGRAM_SET_SELECTED_OBJECT = formatStr(SET_SELECTED_OBJECT, PROGRAM_PAGE_ID);

export const PROGRAM_REMOVE_ADV_FILTER = formatStr(REMOVE_ADV_FILTER, PROGRAM_PAGE_ID);
export const PROGRAM_SET_SEARCH = formatStr(SET_SEARCH, PROGRAM_PAGE_ID);
export const PROGRAM_SET_CATEGORY = formatStr(SET_CATEGORY, PROGRAM_PAGE_ID);
export const PROGRAM_SET_CATEGORIES = `alchemist/${PROGRAM_PAGE_ID}/SET_CATEGORIES`;
export const PROGRAM_TOGGLE_SORT = formatStr(TOGGLE_SORT, PROGRAM_PAGE_ID);
export const PROGRAM_APPLY_TEMP_FILTERS = formatStr(APPLY_TEMP_FILTERS, PROGRAM_PAGE_ID);

export const RES_CLASS_LINKS_SETTINGS = 'res_class_links';
export const LOAD_SITE_SETTINGS = 'app/Resources/LOAD_SITE_SETTINGS';
export const LOAD_SITE_SETTINGS_SUCCESS = 'app/Resources/LOAD_SITE_SETTINGS_SUCCESS';
export const LOAD_SITE_SETTINGS_ERROR = 'app/Resources/LOAD_SITE_SETTINGS_ERROR';
export const RESOURCE_CLASS_LINKS = 'resource_class_links';
export const LOAD_ALCHEMIST_CLASSES = 'app/Resources/LOAD_ALCHEMIST_CLASSES';
export const LOAD_ALCHEMIST_CLASSES_SUCCESS = 'app/Resources/LOAD_ALCHEMIST_CLASSES_SUCCESS';
export const LOAD_ALCHEMIST_CLASSES_ERROR = 'app/Resources/LOAD_ALCHEMIST_CLASSES_ERROR';
export const SET_EDIT_LINKS_MODAL = 'app/Resources/SET_EDIT_LINKS_MODAL';

export const SET_ACTIVE_CLASS_TAB = 'app/Resources/SET_ACTIVE_CLASS_TAB';

export const baseRequestURL = API_PROGARAM_SEARCH_BASE_URL;

export const AJ_CLASS_PARENT_COMPANY_NAME = 'alchemist japan';
