/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { ModalFooter, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { change } from 'redux-form/immutable';

import { ButtonPrim } from 'components/Buttons/ButtonPrim';
import { ButtonSec } from 'components/Buttons/ButtonSec';
import { showAlert } from 'containers/AlertBox/actions';
import { RESOURCE_PEOPLE_LISTS, RESOURCE_PEOPLE, PERMISSION } from 'containers/App/constants';
import { makeSelectLocation } from 'containers/App/selectors';
import { SAVE_FORM_SECTION } from 'containers/Forms/constants';
import Field from 'containers/Forms/Field';
import { closeSharePeopleListModal } from 'containers/People/PeopleListManageModal/actions';
import { clearMarkedAdminLists, clearMarkedEventLists, clearMarkedOwnLists, closePeopleListCreateModal, loadAdminLists, loadEventLists, loadOwnLists } from 'containers/People/PeopleLists/actions';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { NonFormSelectInputField } from 'components/Form/Input/SelectInput';
import { ADMIN_LISTS, EVENT_LISTS, OWN_LISTS } from 'containers/People/PeopleLists/constants';
import FormLabel from 'components/Form/FormLabel';
import FormGroup from 'components/Form/FormGroup';

import FormBase, { withFormWrapper } from '..';
import { saveFormSection } from '../actions';

const makeSaveUserListAction = (isEventList, isAdminList) => (values, section, objRef, successAction, resourceType, apiEndpoint) => {
  let addtlValues = {};
  if (isEventList) {
    addtlValues = {
      event_list: true,
    };
  } else if (isAdminList) {
    addtlValues = {
      admin_list: true,
    };
  }

  const obj = {
    type: SAVE_FORM_SECTION,
    values: { ...values, ...addtlValues },
    section,
    objRef,
    successAction,
    resourceType,
    apiEndpoint,
  };
  return obj;
};

class EditPeopleList extends React.Component {
  constructor(props) {
    super(props);
    let typeOfList = OWN_LISTS;
    if (this.props.peopleList?.event_list || this.props.isEventList) {
      typeOfList = EVENT_LISTS;
    } else if (this.props.peopleList?.admin_list || this.props.isAdminList) {
      typeOfList = ADMIN_LISTS;
    }
    this.state = {
      listType: {
        label: LIST_LABELS[typeOfList],
        value: typeOfList,
      },
    };
  }

  componentDidMount() {
    this.props.loadSchema(RESOURCE_PEOPLE_LISTS);
  }

  render() {
    const {
      schemas, peopleList, dispatch, location, isEventList, isAdmin, isAdminList, changeField,
    } = this.props;

    if (!schemas || !schemas[RESOURCE_PEOPLE_LISTS]) return null;
    const schema = schemas[RESOURCE_PEOPLE_LISTS];
    const closeAction = peopleList && peopleList.id ? closeSharePeopleListModal() : closePeopleListCreateModal();
    const succcessAlert = peopleList && peopleList.id ? 'List Changes Saved' : 'New List Created';

    let pageSuccessActions = [];
    if (location.pathname === `/${RESOURCE_PEOPLE}/lists`) {
      pageSuccessActions = [loadOwnLists(), clearMarkedOwnLists()];
      if (isAdmin) {
        pageSuccessActions = [...pageSuccessActions, loadEventLists(), clearMarkedEventLists(), loadAdminLists(), clearMarkedAdminLists()];
      }
    }

    const successActions = [showAlert(succcessAlert), closeAction, ...pageSuccessActions];

    return (
      <FormBase
        formName={RESOURCE_PEOPLE_LISTS}
        schema={schema}
        editedObject={peopleList}
        saveAction={isAdmin ? makeSaveUserListAction(isEventList, isAdminList) : saveFormSection}
        successAction={successActions}
        SaveButtonComponent={peopleList && peopleList.id ? SaveChangesButton : CreateListButton}
        CloseButtonComponent={DiscardCloseButton}
        closeForm={() => dispatch(closeAction)}
        resourceType={RESOURCE_PEOPLE_LISTS}
        ButtonsWrapper={ModalFooter}
        extraFields={isAdmin ? ['event_list', 'admin_list'] : []}
      >
        <ModalBody>
          <fieldset className="dsa-form-horizontal__group">
            <Field name="title" colSize="col-100" />
            {isAdmin &&
              <FormGroup row className="dsa-form-group">
                <FormLabel label="List Type" />
                <NonFormSelectInputField
                  name="list_type"
                  colSize="col-100"
                  value={this.state.listType}
                  onChange={(e) => {
                    if (e.value === OWN_LISTS) {
                      changeField('event_list', false);
                      changeField('admin_list', false);
                    } else if (e.value === EVENT_LISTS) {
                      changeField('event_list', true);
                    } else if (e.value === ADMIN_LISTS) {
                      changeField('event_list', false);
                      changeField('admin_list', true);
                    }
                    this.setState({ listType: e });
                  }}
                  options={[
                    { label: 'My List', value: OWN_LISTS },
                    { label: 'Admin List', value: ADMIN_LISTS },
                    { label: 'Event List', value: EVENT_LISTS },
                  ]}
                  isClearable={false}
                  disabled={isListDisabled(peopleList) || peopleList?.admin_list || isAdminList}
                />
              </FormGroup>
            }
            <Field name="description" colSize="col-100" />
          </fieldset>
        </ModalBody>
      </FormBase>
    );
  }
}

EditPeopleList.propTypes = {
  loadSchema: PropTypes.func,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  schemas: PropTypes.object,
  peopleList: PropTypes.object,
  isAdmin: PropTypes.bool,
  isEventList: PropTypes.bool,
  isAdminList: PropTypes.bool,
  changeField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  isAdmin: makeSelectUserIs(PERMISSION.admin),
});

const mapDispatchToProps = (dispatch) => ({
  changeField: (fieldName, value) => dispatch(change(RESOURCE_PEOPLE_LISTS, fieldName, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withFormWrapper(EditPeopleList));

const SaveChangesButton = ({ disabled, submitting }) => (
  <ButtonPrim
    disabled={disabled}
    isSending={submitting}
    type="submit"
    className="disabled-while-loading"
  >
    Save Changes
  </ButtonPrim>
);
SaveChangesButton.propTypes = { disabled: PropTypes.bool, submitting: PropTypes.bool };

const DiscardCloseButton = (props) => (
  <ButtonSec onClick={props.onClick} type="button">{props.isSubmitDisabled ? '' : 'Discard & '}Close</ButtonSec>);

DiscardCloseButton.propTypes = { isSubmitDisabled: PropTypes.bool, onClick: PropTypes.func };

const CreateListButton = ({ disabled, submitting }) => (
  <ButtonPrim
    disabled={disabled}
    isSending={submitting}
    type="submit"
    className="disabled-while-loading"
  >
    Create List
  </ButtonPrim>
);
CreateListButton.propTypes = { ...SaveChangesButton.propTypes };

const isListDisabled = (list) => {
  if (list?.ifs_participant_sets) {
    return list.ifs_participant_sets?.()[0]?.baselist_is_frozen;
  }
  if (list?.demo_day) {
    return list.demo_day?.()[0]?.baselist_is_frozen;
  }
  return false;
};

const LIST_LABELS = {
  [OWN_LISTS]: 'My List',
  [EVENT_LISTS]: 'Event List',
  [ADMIN_LISTS]: 'Admin List',
};
