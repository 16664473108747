import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ButtonPrimSm } from 'components/Buttons/ButtonPrim';
import { ButtonSecSm } from 'components/Buttons/ButtonSec';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import LoadingSpinner from 'components/LoadingSpinner';

import { makeSelectTypeofListBeingCopied, selectMarkedAdminLists } from '../selectors';
import { clearMarkedAdminLists as clearMarkedAdminListsAction, copyAdminLists } from '../actions';
import { LIST_TYPES } from '../constants';


const AdminListsButtonsArchive = ({ selectedLists, clearMarkedAdminLists, isDesktop, copyLists, listBeingCopied }) => (
  <>
    <div className="dsa-people-lists__section__buttons-bunch">
      <ButtonPrimSm className={isDesktop ? 'dsa-admin-btn' : 'me-6 dsa-admin-btn'} disabled={!selectedLists.length || listBeingCopied === LIST_TYPES.admin} onClick={copyLists}>
        Copy to My Lists {listBeingCopied === LIST_TYPES.admin && <LoadingSpinner className="dsa-copy-spinner d-inline-block p-0" size="lg" />}
      </ButtonPrimSm>
    </div>
    {isDesktop &&
      <div className="dsa-people-lists__section__buttons-clear">
        <span className="me-5">{selectedLists.length} list selected</span>
        <ButtonSecSm onClick={clearMarkedAdminLists}>Clear</ButtonSecSm>
      </div>}
  </>
);

AdminListsButtonsArchive.propTypes = {
  clearMarkedAdminLists: PropTypes.func,
  selectedLists: PropTypes.array,
  isDesktop: PropTypes.bool,
  copyLists: PropTypes.func,
  listBeingCopied: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  clearMarkedAdminLists: () => dispatch(clearMarkedAdminListsAction()),
  copyLists: () => dispatch(copyAdminLists()),
});

const mapStateToProps = createStructuredSelector({
  selectedLists: selectMarkedAdminLists,
  listBeingCopied: makeSelectTypeofListBeingCopied(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(AdminListsButtonsArchive));
