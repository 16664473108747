import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { faUser } from '@fortawesome/pro-regular-svg-icons';

import ListSection from 'components/Lists/ListSection';
import { RESOURCE_PEOPLE } from 'containers/App/constants';

import { openPeopleListCreateModal, markOwnList as markOwnListAction, loadNextPageOwnLists } from '../actions';
import OwnListsButtons from './OwnListsButtons';
import PeopleListCard from '../PeopleListCard';
import { selectMarkedOwnLists, selectOwnLists, selectOwnListsHasMore, selectOwnListsLoading, selectOwnListsLoadingNextPage } from '../selectors';
import { LIST_TYPES } from '../constants';
import OwnListsButtonsArchive from './OwnListsButtons.archive';


const OwnLists = ({ lists, markOwnList, markedOwnLists, isArchive, loadNextPage, loading, hasMore, loadingNextPage }) => {
  const archivedLists = lists?.filter((list) => list.archived);
  const visibleLists = isArchive
    ? archivedLists
    : lists?.filter((list) => !list.archived);

  return (
    <ListSection
      iconForTitle={faUser}
      title={isArchive ? 'Archived: My Lists' : 'My Lists'}
      buttonsComponent={isArchive ? OwnListsButtonsArchive : OwnListsButtons}
      containerId={LIST_TYPES.my_lists}
      listsNumber={lists?.length}
      isArchive={isArchive}
      buttonsProps={{
        showDeleteButton: true,
        linkTo: `/${RESOURCE_PEOPLE}/lists/archived/own`,
        showArchiveLink: archivedLists?.length > 0,
      }}
      loadNextPage={loadNextPage}
      loading={loading}
      hasMore={hasMore}
      loadingNextPage={loadingNextPage}
    >
      {visibleLists?.map((peopleList) => (
        <PeopleListCard
          peopleList={peopleList}
          key={peopleList.id}
          markObject={markOwnList}
          markedObjects={markedOwnLists}
        />))}
    </ListSection>
  );
};


OwnLists.propTypes = {
  lists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  markOwnList: PropTypes.func,
  markedOwnLists: PropTypes.array,
  isArchive: PropTypes.bool,
  loadNextPage: PropTypes.func,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadingNextPage: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (personList) => dispatch(openPeopleListCreateModal(personList)),
  markOwnList: (personList, unselect) => dispatch(markOwnListAction(personList, unselect)),
  loadNextPage: () => dispatch(loadNextPageOwnLists()),
});

const mapStateToProps = createStructuredSelector({
  lists: selectOwnLists,
  markedOwnLists: selectMarkedOwnLists,
  loading: selectOwnListsLoading,
  hasMore: selectOwnListsHasMore,
  loadingNextPage: selectOwnListsLoadingNextPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnLists);
