/*
 * Admin constants
 */

import {
  RESOURCE_CLASS_WEBSITES,
  RESOURCE_DRY_RUNS,
  RESOURCE_DEMO_DAYS,
  RESOURCE_CUSTOMER_SUMMITS,
  ATTEND_TYPE,
} from 'containers/App/constants';
import { LOAD_CONTENT, LOAD_NEXT_PAGE } from 'utils/filter/constants';
import { formatStr } from 'utils/general';

export const ADMIN_DD_PAGE_IDENTIFIER = 'Admin/DemoDay';
export const LOAD_ALCHEMIST_CLASSES = 'app/Admin/DemoDay/LOAD_ALCHEMIST_CLASSES';
export const LOAD_ALCHEMIST_CLASSES_SUCCESS = 'app/Admin/DemoDay/LOAD_ALCHEMIST_CLASSES_SUCCESS';
export const LOAD_ALCHEMIST_CLASSES_ERROR = 'app/Admin/DemoDay/LOAD_ALCHEMIST_CLASSES_ERROR';
export const LOAD_SELECTED_CLASS = 'app/Admin/DemoDay/LOAD_SELECTED_CLASS';
export const LOAD_SELECTED_CLASS_ERROR = 'app/Admin/DemoDay/LOAD_SELECTED_CLASS_ERROR';
export const SELECT_ALCHEMIST_CLASS = 'app/Admin/DemoDay/SELECT_ALCHEMIST_CLASS';
export const SELECT_PEOPLE_LIST = 'app/Admin/DemoDay/SELECT_PEOPLE_LIST';
export const SYNC_PEOPLE_LIST = 'app/Admin/DemoDay/SYNC_PEOPLE_LIST';
export const SYNC_PEOPLE_LIST_SUCCESS = 'app/Admin/DemoDay/SYNC_PEOPLE_LIST_SUCCESS';
export const SELECT_ACTION = 'app/Admin/DemoDay/SELECT_ACTION';
export const FORM_SENT = 'app/Admin/DemoDay/FORM_SENT';
export const IMPORT_FILES = 'app/Admin/DemoDay/IMPORT_FILES';
export const CSV_MODAL_STEP = 'app/Admin/DemoDay/CSV_MODAL_STEP';
export const SET_PREVIEW = 'app/Admin/DemoDay/SET_PREVIEW';
export const CONFIRM_PREVIEW_SEND = 'app/Admin/DemoDay/CONFIRM_PREVIEW_SEND';
export const SENDING_MAIL_ERROR = 'app/Admin/DemoDay/SENDING_MAIL_ERROR';
export const SET_ELIGIBLE_AVAILABLE_TOTAL_COUNT = 'app/Admin/DemoDay/SET_ELIGIBLE_AVAILABLE_TOTAL_COUNT';
export const TOGGLE_SHOW_LAST_3_DEMO_DAYS = 'app/Admin/OnboardingDashboard/TOGGLE_SHOW_LAST_3_DEMO_DAYS';
export const LOAD_LAST_3_DEMO_DAYS_AVG = 'app/Admin/DemoDay/LOAD_LAST_3_DEMO_DAYS_AVG';
export const TOGGLE_SHOW_BOUNCED = 'app/Admin/OnboardingDashboard/TOGGLE_SHOW_BOUNCED';
export const SET_ARE_AUTO_TEMPLATES_SAVING = 'app/Admin/DemoDay/SET_ARE_AUTO_TEMPLATES_SAVING';
export const ADD_SAVED_AUTO_TEMPLATE = 'app/Admin/DemoDay/ADD_SAVED_AUTO_TEMPLATE';
export const SET_SAVED_AUTO_TEMPLATES = 'app/Admin/DemoDay/SET_SAVED_AUTO_TEMPLATES';
export const SET_BE_PLACEHOLDERS = 'app/Admin/DemoDay/SET_BE_PLACEHOLDERS';

export const SET_IS_REMINDERS_MODAL_OPEN = 'app/Admin/DemoDay/SET_IS_REMINDERS_MODAL_OPEN';
export const SAVE_TEMPLATES = 'app/Admin/DemoDay/SAVE_TEMPLATES';


export const SET_PREVIEW_TEXTS_IS_LOADING = 'app/Admin/DemoDay/SET_PREVIEW_TEXTS_IS_LOADING';
export const UPDATE_PREVIEW_TEXTS = 'app/Admin/DemoDay/UPDATE_PREVIEW_TEXTS';

export const SEND_ENDPOINT_ADAPTER = 'STD_ENDPOINT/send';
export const PREVIEW_ENDPOINT_ADAPTER = 'STD_ENDPOINT/preview';

export const DEMO_DAY_SETTING_GROUP = 'demo-day';
export const CLASS_WEBSITE_SETTING_GROUP = 'class-website';
export const DRY_RUN_SETTING_GROUP = 'dry-run';
export const CUSTOMER_SUMMIT_SETTING_GROUP = 'customer-summit';
export const SIGNATURES_SETTING_GROUP = 'signatures';

export const DDCW_SETTING_GROUP_TO_TYPE_MAPPER = {
  [DEMO_DAY_SETTING_GROUP]: RESOURCE_DEMO_DAYS,
  [CLASS_WEBSITE_SETTING_GROUP]: RESOURCE_CLASS_WEBSITES,
  [DRY_RUN_SETTING_GROUP]: RESOURCE_DRY_RUNS,
  [CUSTOMER_SUMMIT_SETTING_GROUP]: RESOURCE_CUSTOMER_SUMMITS,
};
export const DDCW_SETTING_GROUP_TO_CLASS_REL_NAME_MAPPER = {
  [DEMO_DAY_SETTING_GROUP]: 'demo_day',
  [CLASS_WEBSITE_SETTING_GROUP]: 'class_website',
  [DRY_RUN_SETTING_GROUP]: 'dry_run',
  [CUSTOMER_SUMMIT_SETTING_GROUP]: 'customer_summit',
};

export const TEMPLATE_TYPE = {
  invite: 'invite',
  reminder: 'reminder',
  final_reminder: 'final_reminder',
  attend_in_person: 'attend_in_person',
  attend_remotely: 'attend_remotely',
  registrants_reminder: 'registrants_reminder',
  watch_later_registrants_reminder: 'watch_later_registrants_reminder',
  invite_classwebsite: 'invite_classwebsite',
  reminder_classwebsite: 'reminder_classwebsite',
  interest_classwebsite: 'interest_classwebsite',
  reminder_interest_classwebsite: 'reminder_interest_classwebsite',
  comp_list_classwebsite: 'comp_list_classwebsite',
  invite_dryrun: 'invite_dryrun',
  invite_2_dryrun: 'invite_2_dryrun',
  invite_summit: 'invite_summit',
  reminder_summit: 'reminder_summit',
  partials_reminder: 'partials_reminder',
  partials_final_reminder: 'partials_final_reminder',
  partials_reminder_summit: 'partials_reminder_summit',
  partials_final_reminder_summit: 'partials_final_reminder_summit',
  missed_demoday_reminder: 'missed_demoday_reminder',
  not_registered_reminder: 'not_registered_reminder',
  attendees_notify: 'attendees_notify',
  successful_registration: 'successful_registration',
  dd_connection: 'dd_connection',
  dd_reserve: 'dd_reserve',
  dd_meet: 'dd_meet',
  dd_share: 'dd_share',
  add_reviews_founder_connects: 'add_reviews_founder_connects',
};
export const DEMO_DAY_INVITATIONS_LOAD_CONTENT = formatStr(LOAD_CONTENT, ADMIN_DD_PAGE_IDENTIFIER);
export const DEMO_DAY_INVITATIONS_LOAD_NEXT_PAGE = formatStr(LOAD_NEXT_PAGE, ADMIN_DD_PAGE_IDENTIFIER);

// const DEMODAY_SIGNIN_URL = `${window.location.origin}/demo-day/[[class_id]]/sign-in`;

export const ACTION_NICE_MAPPER = {
  [TEMPLATE_TYPE.invite]: 'Invite',
  [TEMPLATE_TYPE.attend_in_person]: 'Attend in-person',
  [TEMPLATE_TYPE.attend_remotely]: 'Attend Remotely',
  [TEMPLATE_TYPE.registrants_reminder]: 'Registrant Email',
  [TEMPLATE_TYPE.watch_later_registrants_reminder]: 'Auto: Recorded Registrant Email',
  [TEMPLATE_TYPE.missed_demoday_reminder]: 'Auto: Registered DD user follow up',
  [TEMPLATE_TYPE.not_registered_reminder]: 'Auto: Not Registered Investors',
  [TEMPLATE_TYPE.attendees_notify]: 'Auto: Registered Attended',
  [TEMPLATE_TYPE.successful_registration]: 'Auto: Successful Registration',
  [TEMPLATE_TYPE.dd_connection]: 'Auto: Demoday Connection',
  [TEMPLATE_TYPE.dd_reserve]: 'Auto: Demoday Reserve',
  [TEMPLATE_TYPE.dd_meet]: 'Auto: Demoday Meet',
  [TEMPLATE_TYPE.dd_share]: 'Auto: Demoday Share',
  [TEMPLATE_TYPE.add_reviews_founder_connects]: 'Auto: Add Reviews on Founder Connects',
  [TEMPLATE_TYPE.reminder]: 'Auto: Reminder to register',
  [TEMPLATE_TYPE.final_reminder]: 'Auto: Final reminder to register',
  [TEMPLATE_TYPE.invite_classwebsite]: 'Invite',
  [TEMPLATE_TYPE.reminder_classwebsite]: 'Reminder',
  [TEMPLATE_TYPE.interest_classwebsite]: 'Interest',
  [TEMPLATE_TYPE.reminder_interest_classwebsite]: 'Interest Reminder',
  [TEMPLATE_TYPE.comp_list_classwebsite]: 'Auto: Companies List',
  [TEMPLATE_TYPE.invite_dryrun]: 'Invite',
  [TEMPLATE_TYPE.invite_2_dryrun]: 'Invite 2',
  [TEMPLATE_TYPE.invite_summit]: 'Invite',
  [TEMPLATE_TYPE.reminder_summit]: 'Reminder',
  [TEMPLATE_TYPE.partials_reminder]: 'Auto: Partials Reminder',
  [TEMPLATE_TYPE.partials_final_reminder]: 'Auto: Partials Final Reminder',
  [TEMPLATE_TYPE.partials_reminder_summit]: 'Partials Reminder',
  [TEMPLATE_TYPE.partials_final_reminder_summit]: 'Partials Final Reminder',
};

export const REGISTRATION_TEXT_MAPPER = {
  [ATTEND_TYPE.in_person]: 'In-person',
  [ATTEND_TYPE.remotely]: 'Remotely',
  [ATTEND_TYPE.remotely_live]: 'Live Stream',
  [ATTEND_TYPE.remotely_recorded]: 'Watch the Recording',
  null: 'Not Registered',
};

export const REGISTRATION_COLOR_MAPPER = {
  [ATTEND_TYPE.in_person]: 'green',
  [ATTEND_TYPE.remotely]: 'blue',
  [ATTEND_TYPE.remotely_live]: 'blue',
  [ATTEND_TYPE.remotely_recorded]: 'gray',
  null: 'red',
};

export const getFormNameForAction = (demoDayAction) => `demoDayAction${demoDayAction.id}`;
export const isDemoDayAction = (formName) => formName.match(/^demoDayAction\d+$/);
