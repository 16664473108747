import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Generic/Tooltip';
import { AAHighlight } from 'components/Search/AAHighlight';


class TruncateTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.outerRef = React.createRef();
  }

  render() {
    const { text, len = 42, delay = 10, className, placement = 'right' } = this.props;

    return text && text.length > len
      ? (
        <>
          <div className={`dsa-truncated-text ${className}`} ref={this.outerRef}>{text.substr(0, len - 1)}…</div>
          <Tooltip placement={placement} autohide={false} targetRef={this.outerRef} delay={delay} className="dsa-tooltip-base">
            <AAHighlight>{text}</AAHighlight>
          </Tooltip>
        </>)
      : <AAHighlight>{text}</AAHighlight>;
  }
}


TruncateTooltip.propTypes = {
  text: PropTypes.string,
  len: PropTypes.number,
  delay: PropTypes.number,
  className: PropTypes.string,
  placement: PropTypes.string,
};

export default TruncateTooltip;
