/*
 * People EditableProfile Actions
 */
import {
  SET_EDITABLE_SECTION, SECTION_SAVED_SUCCESSFULLY, CLOSE_ALL, TOGGLE_EMPTY_SECTIONS, TOGGLE_MOBILE_REVIEWS, MARK_EMPTY_SECTION, LOAD_IFS, LOAD_REG_COMPANIES, SET_REG_COMPANIES, MARK_COMPANY, REORDER_MARKED_COMPANIES, SET_DD_EDITABLE_SECTION, CANCEL_DD_INVITATION, CANCEL_IFS_INVITATION,
  REMOVE_VAULT_ACCESS,
  REMOVE_ROLE,
  SET_SIMILAR_PROFILES_SECTION,
} from './constants';

// export const saveFormSection = createFormAction(SAVE_FORM_SECTION);
const setEditableSection = (section, isOpen) => ({ type: SET_EDITABLE_SECTION, section, isOpen });
const closeAll = () => ({ type: CLOSE_ALL });
const sectionSavedSuccessfully = (section) => ({ type: SECTION_SAVED_SUCCESSFULLY, section });
const toggleEmptySections = () => ({ type: TOGGLE_EMPTY_SECTIONS });
const markEmptySection = (section, isEmpty) => ({ type: MARK_EMPTY_SECTION, section, isEmpty });
const loadIfs = (ifsInvitationId) => ({ type: LOAD_IFS, ifsInvitationId });
const loadIfsRegistrantCompanies = (ifsId) => ({ type: LOAD_REG_COMPANIES, ifsId });
const setIfsRegistrantCompanies = (companyRefs) => ({ type: SET_REG_COMPANIES, companyRefs });
const markIfsCompany = (company, unselect) => ({ type: MARK_COMPANY, company, unselect });
const reorderMarkedIfsCompanies = (companyRefs) => ({ type: REORDER_MARKED_COMPANIES, companyRefs });
const toggleMobileReviews = () => ({ type: TOGGLE_MOBILE_REVIEWS });
const cancelDdInvitation = (ddInvitationId, section) => ({ type: CANCEL_DD_INVITATION, ddInvitationId, section });
const cancelIfsInvitation = (ifsInvitationId, section) => ({ type: CANCEL_IFS_INVITATION, ifsInvitationId, section });
const removeVaultAccess = (formName, fieldName, value, capability, capabilityId) => ({ type: REMOVE_VAULT_ACCESS, formName, fieldName, value, capability, capabilityId });
const removeRole = (formName, removedOption) => ({ type: REMOVE_ROLE, formName, removedOption });

const setEditableDdSection = (invitationId) => ({ type: SET_DD_EDITABLE_SECTION, invitationId });
const setIsSimilarProfilesVisible = (isSimilarProfilesVisible) => ({ type: SET_SIMILAR_PROFILES_SECTION, isSimilarProfilesVisible });

export {
  closeAll,
  setEditableSection,
  sectionSavedSuccessfully,
  toggleEmptySections,
  markEmptySection,
  loadIfs,
  loadIfsRegistrantCompanies,
  setIfsRegistrantCompanies,
  markIfsCompany,
  reorderMarkedIfsCompanies,
  toggleMobileReviews,
  setEditableDdSection,
  cancelDdInvitation,
  cancelIfsInvitation,
  removeVaultAccess,
  removeRole,
  setIsSimilarProfilesVisible,
};
