import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import ClassNameExtendableHoC from 'components/Generic/ClassNameExtendableHoC';


const ListColBase = ClassNameExtendableHoC(
  Col,
  'dsa-list_col',
  ({
    width, multiLine, hideCol, textCenter, type, colMobile, isLastRow, twoLines, fourLines, bottomGradient, padVer, alignCenter, fitContent, lower,
  }) => [
    width && `dsa-width__${width}`,
    type && `_${type}_`,
    {
      _flex_: multiLine,
      '_flex-mobile_': colMobile,
      '_four-lines_': fourLines,
      '_two-lines_': twoLines,
      'dsa_bottom-gradient': bottomGradient,
      'd-none': hideCol,
      'text-center': textCenter,
      'pr-6': isLastRow,
      'py-3': padVer,
      '_align-center_': alignCenter,
      '_fit-content_': fitContent,
      _lower_: lower,
    },
  ],
  ['width', 'multiLine', 'hideCol', 'textCenter', 'type', 'colMobile', 'isLastRow', 'twoLines']
);

ListColBase.propTypes = {
  multiLine: PropTypes.bool,
  colMobile: PropTypes.bool,
  isLastRow: PropTypes.bool,
  twoLines: PropTypes.bool,
  fourLines: PropTypes.bool,
  padVer: PropTypes.bool,
  bottomGradient: PropTypes.bool,
  hideCol: PropTypes.bool,
  textCenter: PropTypes.bool,
  alignCenter: PropTypes.bool,
  fitContent: PropTypes.bool,
  width: PropTypes.string,
  type: PropTypes.string,
  lower: PropTypes.bool,
};

export default ListColBase;
