import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

import DefaultSaveButton from 'components/Form/DefaultSaveButton';
import DefaultCloseButton from 'components/Form/DefaultCloseButton';
import DefaultButtonsWrapper from 'components/Form/DefaultButtonsWrapper';
import ErrorBox from 'components/Form/ErrorBox';
import NoteLegend from 'components/Generic/NoteLegend';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';


export const SubmissionContext = React.createContext();

const InnerButtonsWrapper = ({ removeInnerButtonsWrapper, children, innerButtonsWrapperClassName }) => removeInnerButtonsWrapper ? (
  children
) : (
  <div className={`mb-md-5 mb-0 w-mob-100 ${innerButtonsWrapperClassName || ''}`}>{children}</div>
);

class FormWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.updateSubmissionContextProps();
  }

  componentDidUpdate() {
    this.updateSubmissionContextProps();
  }

  updateSubmissionContextProps() {
    const submissionContextProps = pick(this.props, 'handleSubmit', 'save', 'isSubmitDisabled', 'submitting');
    if (!this.submissionContextProps || !isEqual(submissionContextProps, this.submissionContextProps)) {
      this.submissionContextProps = submissionContextProps;
    }
  }

  render() {
    const {
      handleSubmit, save, passedChildren, dirty, closeForm, submitting, className, error, form, removeInnerSaveButton,
      canSaveUnchanged, SaveButtonComponent, CloseButtonComponent, ButtonsWrapper, formNoteText, submitSucceeded,
      errorsOnTop, invalid, showErrorNextSaveButton, submitFailed, isMobileTablet, removeInnerButtonsWrapper, switchButtonsPlaces,
      innerButtonsWrapperClassName,
    } = this.props;

    const isSubmitDisabled = submitSucceeded || submitting || (!dirty && !canSaveUnchanged) || error;
    return (
      <Form
        className={className}
        onSubmit={handleSubmit(save)}
        name={form}
      >
        <SubmissionContext.Provider value={this.submissionContextProps}>
          {isMobileTablet && formNoteText && <NoteLegend>{formNoteText}</NoteLegend>}
          {errorsOnTop && error && <ErrorBox error={error} rounded className="mb-7" />}
          {passedChildren}
          {!errorsOnTop && error && <ErrorBox error={typeof error === 'string' ? error.charAt(0).toUpperCase() + error.substring(1) : error} rounded className="my-7" />}
          {removeInnerSaveButton
            ? null
            : (
              <ButtonsWrapper className="mb-5 mb-md-0">
                <InnerButtonsWrapper removeInnerButtonsWrapper={removeInnerButtonsWrapper} innerButtonsWrapperClassName={innerButtonsWrapperClassName}>
                  {!switchButtonsPlaces && <SaveButtonComponent disabled={isSubmitDisabled} submitting={submitting} invalid={invalid} />}
                  {closeForm &&
                    <CloseButtonComponent onClick={closeForm} type="button" isSubmitDisabled={isSubmitDisabled} />}
                  {switchButtonsPlaces && <SaveButtonComponent disabled={isSubmitDisabled} submitting={submitting} invalid={invalid} />}
                </InnerButtonsWrapper>
                {showErrorNextSaveButton && invalid && submitFailed &&
                  <ErrorBox
                    rounded
                    className="mb-5 py-3"
                    error="Make sure all the required fields (*) are filled and errors are resolved."
                  />}
              </ButtonsWrapper>
            )}
          {!isMobileTablet && formNoteText && <NoteLegend>{formNoteText}</NoteLegend>}
        </SubmissionContext.Provider>
      </Form>
    );
  }
}

FormWrapper.propTypes = {
  className: PropTypes.string,
  passedChildren: PropTypes.any,
  closeForm: PropTypes.func,
  save: PropTypes.func,
  dirty: PropTypes.bool,
  errorsOnTop: PropTypes.bool,
  error: PropTypes.string,
  form: PropTypes.string,
  removeInnerSaveButton: PropTypes.bool,
  showErrorNextSaveButton: PropTypes.bool,
  invalid: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  handleSubmit: PropTypes.func,
  canSaveUnchanged: PropTypes.bool,
  SaveButtonComponent: PropTypes.any,
  CloseButtonComponent: PropTypes.any,
  ButtonsWrapper: PropTypes.any,
  formNoteText: PropTypes.string,
  isMobileTablet: PropTypes.bool,
  removeInnerButtonsWrapper: PropTypes.bool,
  switchButtonsPlaces: PropTypes.bool,
  innerButtonsWrapperClassName: PropTypes.string,
};

InnerButtonsWrapper.propTypes = {
  removeInnerButtonsWrapper: PropTypes.bool,
  children: PropTypes.any,
  innerButtonsWrapperClassName: PropTypes.string,
};

FormWrapper.defaultProps = {
  SaveButtonComponent: DefaultSaveButton,
  CloseButtonComponent: DefaultCloseButton,
  ButtonsWrapper: DefaultButtonsWrapper,
};

export default ResponsiveHoC(FormWrapper);
