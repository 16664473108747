import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink as NavLinkReact } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import MyAccountMenu from 'components/MainContent/MyAccountMenu';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { PERMISSION, CLASS_TYPE, RESOURCE_PEOPLE } from 'containers/App/constants';

import MobileTopBar from './MobileTopBar';
import AccountMenuContent from './AccountMenuContent';
import StandardSidebar from './StandardSidebar';
import DemoDaySidebar from './DemoDaySidebar';
import SideBarSection from './SideBarSection';


class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSidebarOpened: false };
    this.closeSidebar = this.closeSidebar.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState((prevState) => ({ isSidebarOpened: !prevState.isSidebarOpened }));
  }

  closeSidebar() {
    if (this.state.isSidebarOpened) {
      this.setState(() => ({ isSidebarOpened: false }));
    }
  }

  render() {
    const {
      myRoles, isAuthenticated, myProfile, logout, isDesktop, isMobileTablet, myAccount, authUser, deimpersonate,
      userHighestAccess, hasMultiFounderCaps, openFounderPopup, hasMultiActiveClasses,
    } = this.props;
    if (!myAccount) {
      return null;
    }
    const isOnboarding = myRoles && (myRoles.includes('onboarding') || myRoles.includes('network_onboarding'));
    const isLocked = isOnboarding;
    const primaryMembership = myProfile && myProfile.primaryMembership && myProfile.primaryMembership();
    const primaryCompany = (primaryMembership && primaryMembership.company())
      || (myProfile && myProfile.company && myProfile.company());
    const isFounderPrimaryMembership = primaryMembership && primaryMembership.relation === 0;

    const iAmDemoDay = userHighestAccess === PERMISSION.demoday;
    if (!isAuthenticated || (iAmDemoDay && !myAccount.demoDayClass)) {
      return null;
    }
    const demoDayClassNumber = myAccount?.demoDayClass?.()?.number;
    const isAX = myAccount?.demoDayClass?.()?.class_type === CLASS_TYPE.alchemistx;
    const logoUrl = iAmDemoDay ? `/demo-day/${isAX ? 'ax' : 'aa'}/${demoDayClassNumber}/companies` : `/${RESOURCE_PEOPLE}`;

    let mobileAccountMenuContent;
    if (isMobileTablet) {
      mobileAccountMenuContent = (
        <AccountMenuContent
          myProfile={myProfile}
          logoutAction={logout}
          isLocked={isLocked}
          closeOnClick={this.closeSidebar}
          primaryCompany={primaryCompany}
          isFounderPrimaryMembership={isFounderPrimaryMembership}
          iAmDemoDay={iAmDemoDay}
          authUser={authUser}
          deimpersonate={deimpersonate}
          hasMultiFounderCaps={hasMultiFounderCaps}
          hasMultiActiveClasses={hasMultiActiveClasses}
          openFounderPopup={openFounderPopup}
        />);
    }

    return (
      <>
        {isMobileTablet &&
          <MobileTopBar onClick={this.toggleSidebar} isSidebarOpened={this.state.isSidebarOpened} logoUrl={logoUrl} />}
        <div className="dsa-site-sidebar-outer">
          <div
            className={classNames(
              'dsa-site-sidebar d-flex',
              '_prim_',
              { 'dsa-site-sidebar__locked': isLocked, _opened_: this.state.isSidebarOpened }
            )}
          >
            {isDesktop && (
              <div className="dsa-site-sidebar_item">
                <NavLink className="dsa-brand" to={logoUrl} tag={NavLinkReact}>
                  <img src="/alchemist-logomark.png" className="d-inline-block align-top" alt="Alchemist Vault Logo" />
                </NavLink>
              </div>)}
            {iAmDemoDay && !isOnboarding
              ? (
                <SideBarSection>
                  <DemoDaySidebar myAccount={myAccount} closeSidebar={this.closeSidebar}>
                    {mobileAccountMenuContent}
                  </DemoDaySidebar>
                </SideBarSection>
              ) : (
                <StandardSidebar primaryCompany={primaryCompany} isLocked={isLocked} closeSidebar={this.closeSidebar} {...this.props}>
                  {mobileAccountMenuContent}
                </StandardSidebar>
              )}
            {isDesktop &&
              <div className="dsa-site-sidebar_bottom">
                <MyAccountMenu
                  myProfile={myProfile}
                  isLocked={isLocked}
                  iAmDemoDay={iAmDemoDay}
                  primaryCompany={primaryCompany}
                  isFounderPrimaryMembership={isFounderPrimaryMembership}
                  logoutAction={logout}
                  authUser={authUser}
                  deimpersonate={deimpersonate}
                  hasMultiFounderCaps={hasMultiFounderCaps}
                  hasMultiActiveClasses={hasMultiActiveClasses}
                  openFounderPopup={openFounderPopup}
                />
              </div>}
          </div>
        </div>
      </>
    );
  }
}

SideBar.propTypes = {
  myProfile: PropTypes.object,
  myAccount: PropTypes.object,
  upcomingClass: PropTypes.object,
  myRoles: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobileTablet: PropTypes.bool,
  authUser: PropTypes.object,
  logout: PropTypes.func,
  deimpersonate: PropTypes.func,
  userHighestAccess: PropTypes.string,
  hasMultiFounderCaps: PropTypes.bool,
  openFounderPopup: PropTypes.func,
  hasMultiActiveClasses: PropTypes.bool,
};

export default ResponsiveHoC(SideBar);
