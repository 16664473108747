import React from 'react';
import PropTypes from 'prop-types';
import { faCalendarCircleUser } from '@fortawesome/pro-light-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectLatestIfsInvitation } from 'containers/AuthProvider/selectors';
import { addDays } from 'utils/dateTime';
import { FOUNDER_PARTICIPANT } from 'containers/InvestorFeedbackSummit/Registration/constants';

import { SideBarLinkItem } from './SideBarItem';


const IfsAgendaSidebarLink = ({ closeSidebar, latestIfsInvitation, isLocked }) => {
  const latestIfs = latestIfsInvitation?.participant_set?.()?.ifs?.();
  const isIfsFounder = FOUNDER_PARTICIPANT.includes(latestIfsInvitation?.participant_set?.().participant_type);

  return latestIfs && latestIfsInvitation && new Date() < addDays(new Date(latestIfs.event_datetime_pst_aware), 29)
    ? (
      <SideBarLinkItem
        to={latestIfsInvitation.agenda_sent && latestIfsInvitation.registration_finished ? `/ifs/${latestIfs.id}/agenda/${isIfsFounder ? `founder/${latestIfsInvitation.id}` : `investor/${latestIfsInvitation.code}`}` : '/ifs/attendance?isFS=true'}
        onClick={closeSidebar}
        faIcon={isLocked ? faLock : faCalendarCircleUser}
      >
        My IFS Agenda
      </SideBarLinkItem>
    ) : null;
};

IfsAgendaSidebarLink.propTypes = {
  closeSidebar: PropTypes.func,
  latestIfsInvitation: PropTypes.object,
  isLocked: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  latestIfsInvitation: selectLatestIfsInvitation,
});

export default connect(mapStateToProps)(IfsAgendaSidebarLink);
