import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';

import ChooseButton from 'components/Generic/ChooseButton';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { getTimeAgo } from 'utils/dateTime';

import { openPeopleListCreateModal } from './actions';
import { LIST_CARD_MARGIN_PX, LIST_CARD_WIDTH_PX } from './constants';

const PeopleListCard = ({
  peopleList: list, markObject, markedObjects, isMobileTablet,
}) => {
  const isAdminList = list.event_list || list.admin_list;
  const faIcon = (list.myPermission && list.myPermission.update && faPencilAlt)
    || (list.isSharedWithMe && faEye)
    || (list.is_shared && faUsers);
  const totalPeople = list.users_count;
  const peopleText = totalPeople === 1 ? 'person' : 'people';
  const peopleStatsText = totalPeople > 0 ? `${totalPeople} ${peopleText}` : '(empty list)';
  const ownerStatsText = (
    (list.subType() === 'own' && `${list.connections_stats.sent} pending · ${list.connections_stats.introd} intro'd`)
    || (['sharedWithMe', 'eventList', 'adminList'].includes(list.subType()) && `Owner: ${list.creator && list.creator().profile().nicename}`));

  const isChosen = !!(markedObjects && markedObjects.find((obj) => list.id === obj.id));

  return (
    <ListCard className={isChosen ? 'is-selected dsa-people-list-card' : 'dsa-people-list-card'} isAdminList={isAdminList}>
      {!isMobileTablet && faIcon && <ListStatus><FontAwesomeIcon icon={faIcon} /></ListStatus>}
      <StyledLink to={`/people/lists/${list.id}`}>
        {!isMobileTablet && <TextTruncated isAdminList={isAdminList}>{list.title}</TextTruncated>}
        {isMobileTablet && <MobileTitle isAdminList={isAdminList}>{list.title}</MobileTitle>}
        {!isMobileTablet && <TextTruncated description>{list.description}</TextTruncated>}
        <TextTruncated grey={isMobileTablet}>{peopleStatsText}</TextTruncated>
        {!isMobileTablet && <TextTruncated owner>{ownerStatsText || ''}</TextTruncated>}
        {isMobileTablet && faIcon && <FontAwesomeIcon className="mt-4" icon={faIcon} />}
        {!isMobileTablet && <Divider />}
        {!isMobileTablet && <DateText>{getTimeAgo(list?.created_at)}</DateText>}
      </StyledLink>
      <CheckBox data-testid="list-checkbox">
        <CheckBoxButton
          item={list}
          checkedIcon={faCheckSquare}
          uncheckedIcon={faSquare}
          markObject={markObject}
          selectedObjects={markedObjects}
        />
      </CheckBox>
    </ListCard>
  );
};

PeopleListCard.propTypes = {
  peopleList: PropTypes.object,
  markObject: PropTypes.func,
  markedObjects: PropTypes.array,
  isMobileTablet: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  openModal: (personList) => dispatch(openPeopleListCreateModal(personList)),
});

export default connect(null, mapDispatchToProps)(ResponsiveHoC(PeopleListCard));


const CheckBox = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #E6E6E6;
  margin-top: 1rem;
`;

const DateText = styled.div`
  font-size: 12px;
  margin-top: 1.2rem;
  text-align: center;
  color: #395564;
`;

const CheckBoxButton = styled(ChooseButton)`
  font-size: 1rem;
  color: #03273B;
  padding: .34375rem .657rem;
`;

const ListStatus = styled.div`
  position: absolute;
  bottom: .5rem;
  left: .8rem;
  font-size: .875rem;
  color: #62778C;
`;

const ListCard = styled.div`
  flex: 0 0 auto;
  width: ${LIST_CARD_WIDTH_PX}px;
  height: 10.5rem;  // $spacer * 24
  margin-right: ${LIST_CARD_MARGIN_PX}px;
  margin-top: ${LIST_CARD_MARGIN_PX}px;

  border: 1px solid #E6E6E6;
  border-radius: 0.1875rem;
  background-color: white;
  display: block;
  position: relative;

  &:hover {
    box-shadow: 3px 10px 15px 0 rgba(0,0,0,0.06);
    -webkit-transform: none;
    transform: none;
    border-color: #CBCBCB;
    ${(p) => p.isAdminList && `
      border-color: #127557;
    `}
  }

  &.is-selected {
    border-color: #03273B;
    ${(p) => p.isAdminList && `
      border-color: #127557;
    `}
    box-shadow: none;

    ${(p) => p.isAdminList && `
      button svg {
        color: #127557;
      }
    `}
  }
`;

const MobileTitle = styled.div`
  color: ${(p) => p.isAdminList ? '#127557' : '#03273B'};
  line-height: '21px';
  font-size: 1rem;
  font-weight: 600;
`;

const TextTruncated = styled.div`
  height: 1.3125rem;
  line-height: 1.3125rem;
  font-size: ${(props) => (props.owner ? '.75rem' : '.875rem')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${(p) => p.isAdminList ? '#127557' : ((p.description && '#62778C') || (p.owner && '#D1D7DB') || (p.grey && '#395564') || '#03273B')};
  font-weight: ${(props) => ((props.description || props.owner || props.grey) ? '400' : '500')};
  margin-bottom: ${(props) => (props.description ? '.4375rem' : '0')};
  margin-top: ${(props) => (props.grey ? '.875rem;' : '0')};
`;


const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: .8rem;
  display: block;

  &:hover {
    color: initial;
    text-decoration: none;
  }
`;
