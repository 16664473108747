import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import ListsWrapper from '../ListsWrapper';
import { clearMarkedAdminLists, loadAdminLists } from '../actions';
import { selectAdminLists } from '../selectors';
import { ADMIN_LISTS } from '../constants';
import AdminLists from '../Sections/AdminLists';

const ArchivedAdminLists = ({ onStart, clearMarkedAdmin, lists }) => {
  useEffect(() => {
    // fetch lists when user reloads page
    if (!lists || (lists && lists.length === 0)) {
      onStart();
    }

    return () => {
      clearMarkedAdmin();
    };
  }, []);

  return (
    <ListsWrapper listsType={ADMIN_LISTS} isArchive>
      <AdminLists isArchive />
    </ListsWrapper>
  );
};

ArchivedAdminLists.propTypes = {
  onStart: PropTypes.func,
  clearMarkedAdmin: PropTypes.func,
  lists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  onStart: () => dispatch(loadAdminLists()),
  clearMarkedAdmin: () => dispatch(clearMarkedAdminLists()),
});

const mapStateToProps = createStructuredSelector({
  lists: selectAdminLists,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedAdminLists);
