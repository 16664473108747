export const UPDATE_SITE_SETTINGS = 'alchemist/User/UPDATE_SITE_SETTINGS';
export const UPDATE_SIGNATURES = 'alchemist/User/UPDATE_SIGNATURES';
export const SET_USER = 'alchemist/User/SET_USER';
export const SET_TOKEN = 'alchemist/User/SET_TOKEN';
export const SET_IS_AUTHENTICATED = 'alchemist/User/SET_IS_AUTHENTICATED';
export const SET_IS_CHECKING = 'alchemist/User/SET_IS_CHECKING';
export const INITIAL_CHECK_TOKEN = 'alchemist/User/INITIAL_CHECK_TOKEN';
export const LOAD_PROFILE = 'alchemist/User/LOAD_PROFILE';
export const SET_PROFILE = 'alchemist/User/SET_PROFILE';
export const LOAD_HINTS = 'alchemist/User/LOAD_HINTS';
export const SET_HINTS = 'alchemist/User/SET_HINTS';
export const SHOW_HINT = 'alchemist/User/SHOW_HINT';
export const START_LOGOUT = 'alchemist/User/START_LOGOUT';
export const FINISH_LOGOUT = 'alchemist/User/FINISH_LOGOUT';
export const IMPERSONATE_USER = 'alchemist/User/IMPERSONATE_USER';
export const DEIMPERSONATE = 'alchemist/User/DEIMPERSONATE';
export const FULL_ACCOUNT_DATA_LOADED = 'alchemist/User/FULL_ACCOUNT_DATA_LOADED';
export const FULL_COMPANIES_DATA_LOADED = 'alchemist/User/FULL_COMPANIES_DATA_LOADED';
export const SET_UPCOMING_CLASS = 'alchemist/User/SET_UPCOMING_CLASS';
export const SET_UPCOMING_CLASSX = 'alchemist/User/SET_UPCOMING_CLASSX';
export const SET_ACTIVE_AX_CLASSES = 'alchemist/User/SET_ACTIVE_AX_CLASSES';
export const TRACK_LMS_PAGE = 'alchemist/User/TRACK_LMS_PAGE';
export const SET_CLASS_FROM_LINKEDIN_SIGNIN = 'alchemist/User/SET_CLASS_FROM_LINKEDIN_SIGNIN';
export const SET_COMPANY_THEME = 'alchemist/User/SET_COMPANY_THEME';

// theme localStorage keys
export const LS_KEY_THEME_PRIMARY_COLOR = 'alchemist_primary_color';
export const LS_KEY_THEME_LINK_COLOR = 'alchemist_link_color';
export const LS_KEY_THEME_TYPE = 'alchemist_theme_type';

export const LOAD_LISTS_FOR_MODAL = 'alchemist/User/LOAD_LISTS_FOR_MODAL';
export const SET_LISTS_FOR_MODAL_LOADING = 'alchemist/User/SET_LISTS_FOR_MODAL_LOADING';
export const SET_LISTS_FOR_MODAL_LOADED = 'alchemist/User/SET_LISTS_FOR_MODAL_LOADED';
export const ALCHEMIST_DD_LOGIN_LS_KEY = 'alchemist_dd_login_path';
export const NEXT_PAGE_AFTER_ONBOARDING = 'next_page_after_onboarding';
