import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FormGroup from 'components/Form/FormGroup';
import FormLabel from 'components/Form/FormLabel';
import { makeSelectUserIs } from 'containers/AuthProvider/selectors';
import { PERMISSION } from 'containers/App/constants';

import FormHelperPopover from '../FormHelperPopover';
import ErrorPart from '../ErrorPart';
import FieldClass from './FieldClass';


const FullFieldMiddleHoc = (WrappedInput, defaultProps = {}) => class extends FieldClass {
  static defaultProps = {
    colSize: 'col-50', className: '', isNotRow: false, ...defaultProps,
  };

  render() {
    const {
      helpText, isHelpTextFloated, helperPopoverText, popoverPlacement = 'top-end', popoverWidth, label, labelWidth,
      colSize, className, labelClassName, isNotRow, inline, compoundField, errorProcessor, isAdminField, labelAdditionalComponent, iAmAdmin,
      afterContent, formGroupClassName = '', hideErrorPart, CustomErrorComponent, customErrorComponentProps, ...props
    } = this.props;

    if (!iAmAdmin && (isAdminField || className.includes('dsa-admin-field'))) {
      return null;
    }

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const isRow = !isNotRow && !inline;

    // const classNameStr = classNames(props.type, colSize, className);
    const classNameStr = classNames(colSize, className, { 'dsa-admin-field': isAdminField && (props.input.value || ![null, ''].includes(props.input.value)) });
    return (
      <FormGroup row={isRow} className={classNames('dsa-form-group', formGroupClassName, { _inline_: inline, _compound_: compoundField })}>
        {label &&
          <FormLabel
            label={label}
            width={labelWidth}
            required={props.required}
            type={props.type}
            className={classNames(labelClassName, { 'dsa-admin-txt': isAdminField })}
            meta={props.meta}
            labelAdditionalComponent={labelAdditionalComponent}
            isAdminField={isAdminField}
            showInfoTooltip={props.showInfoTooltip}
            infoTooltipText={props.infoTooltipText}
          />}
        <div className={classNameStr} ref={this.inputRef} data-testid={props.input && `field__${props.input.name}`}>
          <WrappedInput {...props} />
          {!isIE11 && props.meta.active && helperPopoverText && !!this.inputRef && (
            <FormHelperPopover
              helperText={helperPopoverText}
              target={this.inputRef && this.inputRef.current}
              placement={popoverPlacement}
              popoverWidth={popoverWidth}
            />)}
          {!hideErrorPart && <ErrorPart errorProcessor={errorProcessor} meta={props.meta} />}
          {CustomErrorComponent && <CustomErrorComponent {...customErrorComponentProps} />}
          {helpText && (!props.meta.error || !props.meta.dirty) &&
            <div className={`dsa-help-text ${isHelpTextFloated ? 'position-absolute' : ''}`}>
              <ReactMarkdown>{helpText}</ReactMarkdown>
            </div>}
        </div>
        {afterContent && afterContent}
      </FormGroup>
    );
  }
};

const FullFieldHoc = (Component, defaultProps) => {
  const mapStateToProps = createStructuredSelector({
    iAmAdmin: makeSelectUserIs(PERMISSION.admin),
  });

  return connect(mapStateToProps)(FullFieldMiddleHoc(Component, defaultProps));
};

export default FullFieldHoc;
