import { createSelector } from 'reselect';

import { makeSelectObjectArrayFromRefArray, makeSelectObjectFromRefSelector } from 'containers/App/selectors';
import { makeSubSelector } from 'utils/selectors';
import { PERMISSION } from 'containers/App/constants';

import reducer from './reducer';
import { makeSelectUser, makeSelectUserIsOneOf } from '../../AuthProvider/selectors';

const localInitialState = reducer(undefined, {});

const selectPeopleSinglePage = (state) => state ? state.get('peopleSinglePage', localInitialState) : localInitialState;

const makeSelectSelectedPersonNotFound = () => makeSubSelector(selectPeopleSinglePage, ['notFound']);
const makeSelectSelectedPersonRef = () => makeSubSelector(selectPeopleSinglePage, ['selectedPerson']);
const makeSelectedPersonIsFetchingConnections = () => makeSubSelector(selectPeopleSinglePage, ['isFetchingConnections']);
const selectSimilarProfileRefs = makeSubSelector(selectPeopleSinglePage, ['similarProfiles']);
const makeSelectSimilarProfiles = () => makeSelectObjectArrayFromRefArray(selectSimilarProfileRefs);

const makeSelectSelectedPerson = () => makeSelectObjectFromRefSelector(makeSelectSelectedPersonRef());
const selectIsSelf = createSelector(
  makeSelectSelectedPersonRef(),
  makeSelectUser(),
  (selectedPersonRef, user) => user.profileId === parseInt(selectedPersonRef.id, 10),
);

const selectShowTeamNotes = createSelector(
  makeSelectUserIsOneOf([PERMISSION.founder, PERMISSION.admin]),
  selectIsSelf,
  (isFounderOrAdmin, isSelf) => !isSelf && isFounderOrAdmin
);

export {
  selectIsSelf,
  makeSelectSelectedPerson,
  makeSelectSelectedPersonRef,
  makeSelectSelectedPersonNotFound,
  makeSelectedPersonIsFetchingConnections,
  makeSelectSimilarProfiles,
  selectShowTeamNotes,
};
