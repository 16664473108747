import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { stringify } from 'qs';

import { updateIncluded, updateIncludedFromRequest } from 'actions';
import { API_PEOPLE_BASE_URL, PERMISSION, RESOURCE_PEOPLE } from 'containers/App/constants';
import { makeSelectHighestUserAccess, makeSelectUserIs, makeSelectUserCanAccessPeoplePage } from 'containers/AuthProvider/selectors';
import { allIfsEventAgenda } from 'containers/InvestorFeedbackSummit/Registration/saga';
import { initialLoad as loadTeamNotes } from 'containers/People/ProfileTeamNotes/actions';
import { logError } from 'utils/log';
import { request } from 'utils/request';
import { refApiUrl } from 'utils/refs';
import { checkRouteMatch } from 'utils/general';
import { refreshCurrentRoute } from 'utils/sagas';
import { hasRoleOneOf } from 'utils/fieldProcessors';
import { extractData } from 'utils/jsonApiExtract';

import { getSingleConnectionSaga, loadMyCompanyTeamMembers } from '../Search/saga';
import { selectedPersonNotFound, setSimilarProfiles } from './actions';
import { SET_SELECTED_PERSON } from './constants';
import { makeSelectSelectedPerson, makeSelectSelectedPersonRef, selectIsSelf, selectShowTeamNotes } from './selectors';
import { setIsSimilarProfilesVisible } from '../EditableProfile/actions';


export const basicIncludes = ['tags', 'location', 'company_membership.company', 'roles', 'company.aclass'];
export const extraIncludes = ['areas_of_service', 'press_topics', 'erogated_investments.investment_round.company.tags',
  'erogated_investments.investment_round.company.investment_rounds', 'erogated_investments.investing_company',
  'company.stages_of_interest', 'not_areas_of_interest', 'areas_of_interest', 'angel_prefer_geographies'];
export const ifsIncludes = ['ifs_invitations.selected_events', ...allIfsEventAgenda.map((aType) => `ifs_invitations.${aType}`)];

function* getPerson() {
  const userHighestAccess = yield select(makeSelectHighestUserAccess());
  const personRef = yield select(makeSelectSelectedPersonRef());
  const isSelf = yield select(selectIsSelf);
  if (!personRef.id || !personRef.id.match(/^\d+$/)) {
    yield put(selectedPersonNotFound());
    return;
  }

  let include = [...basicIncludes, ...extraIncludes];
  if (!isSelf) {
    include.push('comments.author.profile');
  }

  if (userHighestAccess === PERMISSION.corporate) {
    include = include.filter((incl) => incl !== 'erogated_investments.investment_round.company.investment_rounds');
  }

  const isAdmin = yield select(makeSelectUserIs(PERMISSION.admin));
  if (isAdmin) {
    include.push('account.capabilities_rel.aclass', 'account.capabilities_rel.aclass_company.aclass', 'account.capabilities_rel.aclass_company.aclass', 'account.capabilities_rel.membership.company.aclass', 'account.capabilities_rel.membership.company.point_of_contact_account', 'account.capabilities_rel.dd_invitation', 'demo_day_users_rel.demo_day.aa_class.event_agenda', 'demo_day_users_rel.selected_events', ...ifsIncludes, 'ifs_invitations.areas_of_interest', 'ifs_invitations.selected_companies', 'ifs_invitations.selected_investors', 'coach_classes', 'company.tags.text');
  }
  if (isAdmin || isSelf) {
    include.push('profile_emails', 'meetings');
  }

  const requestURL = `${refApiUrl(personRef)}?include=${Array.from(new Set(include)).join()}`;
  const showTeamNotes = yield select(selectShowTeamNotes);

  try {
    // Call our request helper (see 'utils/request')
    const peopleRequest = yield call(request, requestURL);
    if (showTeamNotes) {
      yield put(loadTeamNotes());
    }
    yield put(updateIncludedFromRequest(peopleRequest));
    yield getSingleConnectionSaga(personRef.id);
    yield getProfilesSimilarToProfilePerson();
    yield loadMyCompanyTeamMembers();
  } catch (err) {
    if (err.response.status === 404) {
      yield put(selectedPersonNotFound());
    }
    logError(err);
    // yield put(contentLoadingError(err));
  }
}

function* getProfilesSimilarToProfilePerson() {
  try {
    const hasAccessToPeoplePage = yield select(makeSelectUserCanAccessPeoplePage());
    const isSelf = yield select(selectIsSelf);
    const profilePerson = yield select(makeSelectSelectedPerson());

    if (!isSelf
      && hasAccessToPeoplePage
      && hasRoleOneOf(profilePerson, ['founder', 'investor', 'angel', 'strategic_investor', 'mentor'])
    ) {
      const hasTopicOfFocusAndIsInvestor = profilePerson?.areas_of_interest && hasRoleOneOf(profilePerson, ['investor', 'angel', 'strategic_investor']);
      const includeToUse = hasTopicOfFocusAndIsInvestor ? 'areas_of_interest' : 'tags';
      const filterToUse = hasTopicOfFocusAndIsInvestor ? profilePerson?.areas_of_interest?.() : profilePerson?.tags?.();
      if (filterToUse?.length > 0) {
        const queryStr = stringify({
          include: `${includeToUse},roles`,
          filter: {
            [`${includeToUse}.text:in`]: `[${filterToUse.map((tag) => `'${tag.text}'`)?.join()}]`,
          },
          page: { size: 9 },
        });
        const similarProfilesReq = yield call(request, `${API_PEOPLE_BASE_URL}/${profilePerson?.id}/similar?${queryStr}`);
        const { inclusions, items } = extractData(similarProfilesReq);
        yield put(updateIncluded(inclusions));
        yield put(setSimilarProfiles(items));
        yield put(setIsSimilarProfilesVisible(true));
      }
    }
  } catch (err) {
    logError(err);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  yield takeLatest(SET_SELECTED_PERSON, getPerson);
  yield takeLatest(LOCATION_CHANGE, refreshCurrentRoute, { callbackSaga: getPerson, condition: (location) => checkRouteMatch(`/${RESOURCE_PEOPLE}/:personId`, location) });
}
