/*
 *
 * AddContact constants
 *
 */

export const LOAD_SCHEMA = 'app/AddContact/LOAD_SCHEMA';
export const RESET_STATE = 'app/AddContact/RESET_STATE';
export const BACK_TO_FIRST_STEP = 'app/AddContact/BACK_TO_FIRST_STEP';
export const SET_PROCESSED_LINKEDIN_URL = 'app/AddContact/SET_PARSED_LINKEDIN_URL';
export const CHECK_CONTACT = 'app/AddContact/CHECK_CONTACT';
export const CHECK_CONTACT_FINISHED = 'app/AddContact/CHECK_CONTACT_FINISHED';
export const SET_FORM_SCHEMA = 'app/AddContact/SET_FORM_SCHEMA';
export const SEND_INVITATION = 'app/AddContact/SEND_INVITATION';
export const OPEN_PROFILE_SELECTION = 'app/AddContact/OPEN_PROFILE_SELECTION';
export const CLOSE_PROFILE_SELECTION = 'app/AddContact/CLOSE_PROFILE_SELECTION';
export const SELECT_PROFILE = 'app/AddContact/SELECT_PROFILE';
export const SET_PREVIEW = 'app/AddContact/SET_PREVIEW';
export const UPDATE_ADD_CONTACT_PREVIEW_TEXTS = 'app/AddContact/UPDATE_ADD_CONTACT_PREVIEW_TEXTS';

export const ADD_CONTACT_FORM_NAME = 'adminAddContact';
export const DEDUP_CONTACT_FORM_NAME = 'dedupAddContact';

export const REMOVE_VAULT_ACCESS_ADD_CONTACT = 'app/AddContact/REMOVE_VAULT_ACCESS_ADD_CONTACT';
export const REMOVE_ROLE_ADD_CONTACT = 'app/AddContact/REMOVE_ROLE_ADD_CONTACT';
export const OPEN_PREVIEW_MODAL = 'app/AddContact/OPEN_PREVIEW_MODAL';
export const SET_INVITE_AS_NEW = 'app/AddContact/SET_INVITE_AS_NEW';
