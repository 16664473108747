import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { ButtonPrimSm } from 'components/Buttons/ButtonPrim';
import { ButtonSecSm } from 'components/Buttons/ButtonSec';
import { ResponsiveHoC } from 'components/Responsive/ResponsiveHoC';
import { openModalForList } from 'containers/People/PeopleListManageModal/actions';
import { SHARE_SECTION, EDIT_SECTION } from 'containers/People/PeopleListManageModal/constants';
import ArchivedListsButton from 'components/Buttons/Specific/ArchivedListsButton';
import LoadingSpinner from 'components/LoadingSpinner';

import { makeSelectEventListMergeEnabled, makeSelectTypeofListBeingCopied, selectMarkedEventLists } from '../selectors';
import {
  clearMarkedEventLists as clearMarkedEventListsAction,
  copyEventLists,
  deletePeopleLists,
  openPeopleListCreateModal,
  openPeopleListMergeModal,
} from '../actions';
import { EVENT_LISTS, LIST_TYPES } from '../constants';


const EventListsButtons = ({
  openShareModalForList, selectedEventLists, clearMarkedEventLists, openCreateListModal, deleteLists, isMergeEnabled,
  openMergeModal, isDesktop, showArchiveLink, linkTo, copyLists, listBeingCopied,
}) => {
  const singleActionsEnabled = selectedEventLists.length === 1;
  const areSelectedListsEventTypeAndSent = selectedEventLists.length > 0 && selectedEventLists.some((list) => {
    if (list.ifs_participant_sets) {
      return list.ifs_participant_sets?.()[0]?.baselist_is_frozen;
    }
    if (list.demo_day) {
      return list.demo_day?.()[0]?.baselist_is_frozen;
    }
    return false;
  });

  return (
    <>
      <div className="dsa-people-lists__section__buttons-bunch">
        <ButtonPrimSm className="dsa-admin-btn" disabled={!!selectedEventLists.length} onClick={openCreateListModal}>New List</ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!singleActionsEnabled}
          onClick={() => openShareModalForList(selectedEventLists[0], SHARE_SECTION)}
        >
          Share
        </ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!singleActionsEnabled}
          onClick={() => openShareModalForList(selectedEventLists[0], EDIT_SECTION)}
        >
          Edit
        </ButtonPrimSm>
        <ButtonPrimSm className="dsa-admin-btn" disabled={!isMergeEnabled} onClick={openMergeModal}>Merge</ButtonPrimSm>
        <ButtonPrimSm
          className="dsa-admin-btn"
          disabled={!selectedEventLists.length || areSelectedListsEventTypeAndSent}
          onClick={deleteLists}
        >Delete
        </ButtonPrimSm>
        <ButtonPrimSm className={isDesktop ? 'dsa-admin-btn' : 'me-6 dsa-admin-btn'} disabled={!selectedEventLists.length || listBeingCopied === LIST_TYPES.event} onClick={copyLists}>
          Copy to My Lists {listBeingCopied === LIST_TYPES.event && <LoadingSpinner className="dsa-copy-spinner d-inline-block p-0" size="lg" />}
        </ButtonPrimSm>
        {isDesktop && showArchiveLink && <ArchivedListsButton to={linkTo} className="dsa-admin-txt ml-5" />}
      </div>
      {isDesktop &&
        <div className="dsa-people-lists__section__buttons-clear">
          <span className="me-5">{selectedEventLists.length} list selected</span>
          <ButtonSecSm onClick={clearMarkedEventLists}>Clear</ButtonSecSm>
        </div>}
    </>
  );
};

EventListsButtons.propTypes = {
  openShareModalForList: PropTypes.func,
  clearMarkedEventLists: PropTypes.func,
  openCreateListModal: PropTypes.func,
  openMergeModal: PropTypes.func,
  deleteLists: PropTypes.func,
  selectedEventLists: PropTypes.array,
  // areSectionActionsEnabled: PropTypes.bool,
  isMergeEnabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  showArchiveLink: PropTypes.bool,
  linkTo: PropTypes.string,
  copyLists: PropTypes.func,
  listBeingCopied: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  openShareModalForList: (personList, section) => dispatch(openModalForList(personList, section)),
  clearMarkedEventLists: () => dispatch(clearMarkedEventListsAction()),
  openCreateListModal: () => dispatch(openPeopleListCreateModal(true)),
  openMergeModal: () => dispatch(openPeopleListMergeModal()),
  deleteLists: () => dispatch(deletePeopleLists(EVENT_LISTS)),
  copyLists: () => dispatch(copyEventLists()),
});

const mapStateToProps = createStructuredSelector({
  selectedEventLists: selectMarkedEventLists,
  isMergeEnabled: makeSelectEventListMergeEnabled(),
  listBeingCopied: makeSelectTypeofListBeingCopied(),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveHoC(EventListsButtons));
