import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { faUserGear } from '@fortawesome/pro-regular-svg-icons';

import ListSection from 'components/Lists/ListSection';
import { RESOURCE_PEOPLE } from 'containers/App/constants';

import { markAdminList as markAdminListAction, loadNextPageAdminLists } from '../actions';
import PeopleListCard from '../PeopleListCard';
import { selectAdminLists, selectMarkedAdminLists, selectAdminListsLoading, selectAdminListsHasMore, selectAdminListsLoadingNextPage } from '../selectors';
import { LIST_TYPES } from '../constants';
import AdminListsButtonsArchive from './AdminListsButtons.archive';
import AdminListsButtons from './AdminListsButtons';


const AdminLists = ({ lists, markAdminList, markedAdminLists, isArchive, loadNextPage, loading, hasMore, loadingNextPage }) => {
  const archivedLists = lists?.filter((list) => list.archived);

  const visibleLists = isArchive
    ? archivedLists
    : lists?.filter((list) => !list.archived);

  return (
    <ListSection
      iconForTitle={faUserGear}
      title={isArchive ? 'Archived: Admin Lists' : 'Admin Lists'}
      buttonsComponent={isArchive ? AdminListsButtonsArchive : AdminListsButtons}
      containerId={LIST_TYPES.admin}
      listsNumber={lists?.length}
      badgeText={isArchive
        ? 'The lists will be deleted permanently after 2 years of creation date.'
        : 'All company admins can access and fully edit admin lists.'}
      isArchive={isArchive}
      buttonsProps={{
        linkTo: `/${RESOURCE_PEOPLE}/lists/archived/admin`,
        showArchiveLink: archivedLists?.length > 0,
      }}
      loadNextPage={loadNextPage}
      loading={loading}
      hasMore={hasMore}
      loadingNextPage={loadingNextPage}
    >
      {visibleLists?.map((peopleList) => (
        <PeopleListCard
          peopleList={peopleList}
          key={peopleList.id}
          markObject={markAdminList}
          markedObjects={markedAdminLists}
        />))}
    </ListSection>
  );
};


AdminLists.propTypes = {
  lists: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  markAdminList: PropTypes.func,
  markedAdminLists: PropTypes.array,
  isArchive: PropTypes.bool,
  loadNextPage: PropTypes.func,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadingNextPage: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  lists: selectAdminLists,
  markedAdminLists: selectMarkedAdminLists,
  loading: selectAdminListsLoading,
  hasMore: selectAdminListsHasMore,
  loadingNextPage: selectAdminListsLoadingNextPage,
});

const mapDispatchToProps = (dispatch) => ({
  markAdminList: (personListRel, unselect) => dispatch(markAdminListAction(personListRel, unselect)),
  loadNextPage: () => dispatch(loadNextPageAdminLists()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLists);
