/*
 *
 * AddContact actions
 *
 */
import { makeFormActionFunction } from 'utils/Forms/actions';
import { resRef } from 'utils/refs';

import {
  CHECK_CONTACT, CHECK_CONTACT_FINISHED, LOAD_SCHEMA, SEND_INVITATION, SET_FORM_SCHEMA, RESET_STATE, SELECT_PROFILE,
  OPEN_PROFILE_SELECTION, CLOSE_PROFILE_SELECTION, BACK_TO_FIRST_STEP, SET_PROCESSED_LINKEDIN_URL, SET_PREVIEW,
  REMOVE_VAULT_ACCESS_ADD_CONTACT, REMOVE_ROLE_ADD_CONTACT,
  OPEN_PREVIEW_MODAL, SET_INVITE_AS_NEW,
} from './constants';


const loadSchema = () => ({ type: LOAD_SCHEMA });
const resetState = () => ({ type: RESET_STATE });
const backToFirstStep = () => ({ type: BACK_TO_FIRST_STEP });
const setProcessedLinkedinUrl = (processedLinkedinUrl) => ({ type: SET_PROCESSED_LINKEDIN_URL, processedLinkedinUrl });
const checkContactFinished = (matchedUser, isExactMatch, fromModal) => ({
  type: CHECK_CONTACT_FINISHED, matchedUser, isExactMatch, fromModal,
});
const selectProfile = (profile) => ({ type: SELECT_PROFILE, profileRef: resRef(profile) });
const setFormSchema = (schema) => ({ type: SET_FORM_SCHEMA, schema });
const openProfileSelection = (matchRefs, isExactMatch) => ({ type: OPEN_PROFILE_SELECTION, isExactMatch, matchRefs });
const closeProfileSelection = () => ({ type: CLOSE_PROFILE_SELECTION });
const sendInvitation = makeFormActionFunction(SEND_INVITATION);
const checkContact = makeFormActionFunction(CHECK_CONTACT);

const setPreview = (preview) => ({ type: SET_PREVIEW, preview });
const openPreviewModal = (objRef, section) => ({ type: OPEN_PREVIEW_MODAL, objRef, section });

const removeVaultAccess = (formName, fieldName, value, capability, capabilityId) => ({ type: REMOVE_VAULT_ACCESS_ADD_CONTACT, formName, fieldName, value, capability, capabilityId });
const removeRole = (formName, removedOption) => ({ type: REMOVE_ROLE_ADD_CONTACT, formName, removedOption });
const inviteAsNew = (asNew) => ({ type: SET_INVITE_AS_NEW, asNew });

export {
  loadSchema,
  resetState,
  backToFirstStep,
  checkContactFinished,
  selectProfile,
  setFormSchema,
  sendInvitation,
  checkContact,
  openProfileSelection,
  closeProfileSelection,
  setProcessedLinkedinUrl,
  setPreview,
  removeVaultAccess,
  removeRole,
  openPreviewModal,
  inviteAsNew,
};
