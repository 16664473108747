import { all, call, put, select, takeLatest, fork, take, delay } from 'redux-saga/effects';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { stopSubmit } from 'redux-form/immutable';

import { updateIncludedFromRequest } from 'actions';
import {
  RESOURCE_COMPANIES,
  API_BASE,
  RESOURCE_TAG,
  RESOURCE_LOCATIONS,
  API_LOCATIONS_BASE_URL, API_ROLES_BASE_URL, RESOURCE_INVESTMENT_ROUNDS, RESOURCE_PERK_CATEGORIES,
} from 'containers/App/constants';
import { makeSelectObjectRaw, makeSelectQueryParam } from 'containers/App/selectors';
import { STEP_PERSONAL_MORE, STEP_TWO_SHORT_FORM } from 'containers/RegisterProfile/constants';
import { formRequest, isFieldChange, isFormInitialized, isUniquenessError, requestFunctionForObject } from 'utils/Forms/general';
import { handleError, processError, ProcessedError } from 'utils/Forms/sagas';
import { logError } from 'utils/log';
import { formDataToJApi } from 'utils/formToJsonApi';
import { flattenJAPIObject } from 'utils/jsonApiExtract';
import { post, request } from 'utils/request';
import { refApiUrl, resRef, resRefForArrayOrObj } from 'utils/refs';
import { toJS } from 'utils/general';
import { SUBMIT_IFS_REG_FORM } from 'containers/InvestorFeedbackSummit/Registration/constants';
import { selectTimezone } from 'containers/InvestorFeedbackSummit/Registration/selectors';

import { formSectionSaved, setFormSchema } from './actions';
import {
  deleteAdvisorOrPOC,
  initializeAaClassDdRelFields,
  saveCompanyCoachRelsSaga, saveCompanyFoundersRelsSaga,
  saveCompanyInvestmentRoundsSectionSaga,
  saveCompanyInvestorsSectionSaga,
  saveCompanyPortfolioSectionSaga,
  submitCompanyHeaderForm,
} from './Company/saga';
import {
  LOAD_FORM_SCHEMA,
  SAMPLE_INVITE_COMPANY,
  SAVE_COMPANY_COACHES_SECTION,
  SAVE_COMPANY_INVESTMENT_ROUNDS_SECTION,
  SAVE_COMPANY_INVESTORS_SECTION,
  SAVE_COMPANY_PORTFOLIO_SECTION,
  SAVE_FORM_SECTION,
  SAVE_INVITE_COMPANY,
  SAVE_PEOPLE_EXPERIENCE_SECTION,
  SAVE_PEOPLE_PORTFOLIO_SECTION,
  SAVE_DEMODAY_ATTENDANCE_SECTION,
  SAVE_SETTINGS,
  SEND_PASSWORD_CHANGE,
  SAMPLE_INVESTMENT,
  SAMPLE_MAPPER, SAVE_COMPANY_FOUNDERS_SECTION, SAVE_GEN_INFO_SECTION, SAVE_PEOPLE_ACCOUNT_SECTION, SAVE_MENTOR_SECTION, SAVE_FORM_WITH_EMAILS,
} from './constants';
import {
  loadCompanyUsers,
  savePeopleExperienceSectionSaga,
  savePeoplePortfolioSectionSaga,
  sendPasswordChangeSaga,
  saveDemoDayAttendanceSectionSaga,
  saveGeneralInfoSection,
  savePeopleAccountSectionSaga,
  saveMentorSectionSaga,
  saveFormSectionWithEmails,
} from './People/saga';
import { saveSiteSettingsGroupSaga } from './Admin/saga';
import sharedCompaniesSaga from './SharedCompanies/saga';
import { makeSelectSpecificFormSchema } from './selectors';
import { setSendingMailError } from '../Admin/DemoDay/actions';
import { SEND_ENDPOINT_ADAPTER } from '../Admin/DemoDay/constants';
import { DELETE_ADVISOR_OR_POC, SUBMIT_COMPANY_HEADER_FORM } from './Company/constants';


export function* loadFormSchema(action) {
  try {
    // Call our request helper (see 'utils/request')
    const schemaRequest = yield call(
      request,
      `${API_BASE}/${action.resourceName}/meta${action.extraParams ? `?${action.extraParams}` : ''}`
    );
    const schema = schemaRequest.meta.jsonschema;
    yield customizeSchema(schema, action.name || action.resourceName);
    yield put(setFormSchema(schema, action.name || action.resourceName));
  } catch (err) {
    logError(err);
    // yield put(contentLoadingError(err));
  }
  return null;
}

export function* saveFormSection(action) {
  const {
    values, objRef: editedObjectRef, section: formName, successAction: endActions, resourceType, apiEndpoint, sendRefFromUrlOnSubmit,
  } = action;
  let editedObject;
  if (editedObjectRef) {
    editedObject = yield select(makeSelectObjectRaw(editedObjectRef));
  } else if (apiEndpoint || resourceType) {
    editedObject = (apiEndpoint && SAMPLE_MAPPER[apiEndpoint]) || (resourceType && SAMPLE_MAPPER[resourceType]);
  }

  const formData = yield handleSpecialFields({ ...values }, action);
  const dataToSubmit = formDataToJApi(editedObject, formData);
  const baseRequestURL = apiEndpoint && apiEndpoint.includes('STD_ENDPOINT')
    ? apiEndpoint.replace('STD_ENDPOINT', refApiUrl(editedObject))
    : apiEndpoint || refApiUrl(editedObject);


  // Request the sent relationships back, to update the local DB
  const sentRels = keys(dataToSubmit.relationships);
  const baseRequestWithSentRels = `${baseRequestURL}${sentRels.length ? `${baseRequestURL.includes('?') ? '&' : '?'}include=${sentRels.join()}` : ''}`;
  const ddInviteFlag = yield select(makeSelectQueryParam('for'));
  const ddInviteQs = ddInviteFlag ? `${baseRequestWithSentRels.includes('?') ? '&' : '?'}for=${ddInviteFlag}` : '';
  let requestURL = `${baseRequestWithSentRels}${ddInviteQs}`;

  const urlRef = yield select(makeSelectQueryParam('ref'));
  if (sendRefFromUrlOnSubmit && urlRef) {
    requestURL = `${requestURL}&ref=${urlRef}`;
  }

  delete formData.id;
  let mainFormErrors = {};
  let saveRequest;
  try {
    saveRequest = yield call(requestFunctionForObject(editedObject), requestURL, { data: dataToSubmit });
    yield put(updateIncludedFromRequest(saveRequest));
  } catch (err) {
    mainFormErrors = yield processError(err, formName);
  }
  const specialFieldErrors = yield handlePostSpecialFields(action, saveRequest?.data);
  const allErrors = { ...mainFormErrors, ...specialFieldErrors };

  if (isEmpty(allErrors)) {
    // if (editedObjectRef) {
    //   yield afterSaveFormUpdates(editedObjectRef);
    // }
    if (endActions && Array.isArray(endActions)) {
      yield all(endActions.map((endAction) => put(endAction)));
    } else if (endActions) {
      yield put(endActions);
    }
    yield put(formSectionSaved(formName, saveRequest, action));
    yield put(setSendingMailError(''));
  }

  if (apiEndpoint === SEND_ENDPOINT_ADAPTER && !isEmpty(allErrors)) {
    yield put(setSendingMailError(allErrors.message));
  }

  yield put(stopSubmit(formName, allErrors));
}

export function* inviteCompany({ values, successAction, section }, isAtomic = false) {
  const fieldsToOmit = ['isExistingCompany', 'class_type'];
  if (values.isExistingCompany) {
    fieldsToOmit.concat('company_name', 'firstname', 'lastname', 'email');
  } else {
    fieldsToOmit.concat('company', 'user');
  }
  const valuesToSend = omit(values, ...fieldsToOmit);

  if (isAtomic) {
    return {
      href: `${API_BASE}/invites/founder`,
      data: formDataToJApi(SAMPLE_INVITE_COMPANY, valuesToSend),
    };
  }

  try {
    yield post(`${API_BASE}/invites/founder`, { data: formDataToJApi(SAMPLE_INVITE_COMPANY, valuesToSend) });

    if (successAction) {
      successAction();
    }
  } catch (err) {
    yield handleError(err, section);
  }
  return true;
}

function* handleSpecialFields(formData) {
  const newFormData = yield handleTagsFields(formData);

  newFormData.notablecustomers = yield createNewCompanies(formData.notablecustomers);
  newFormData.acquired_by = yield createNewCompany(formData.acquired_by);

  newFormData.location = yield createNewLocation(formData.location);

  /*
   * Handling `data to many relation` error
   * - can be moved to a function to separate this
   */
  if (newFormData.selected_companies === null) {
    newFormData.selected_companies = [];
  }

  if (newFormData.selected_investors === null) {
    newFormData.selected_investors = [];
  }

  if (newFormData.bcc === null) {
    newFormData.bcc = [];
  }

  if (newFormData.cc === null) {
    newFormData.cc = [];
  }

  return newFormData;
}

export function* handleTagsFields(formData) {
  const newFormData = { ...formData };
  newFormData.tags = yield createNewTags(formData.tags);
  newFormData.areas_of_interest = yield createNewTags(formData.areas_of_interest);
  newFormData.not_areas_of_interest = yield createNewTags(formData.not_areas_of_interest);
  newFormData.areas_of_service = yield createNewTags(formData.areas_of_service);
  newFormData.press_topics = yield createNewTags(formData.press_topics);

  // Perk Categories
  newFormData.categories = yield createNewPerkCategories(formData.categories);
  return newFormData;
}

function* handlePostSpecialFields(action, parentData) {
  let errors;
  if (action?.handlePostSpecialFields) {
    errors = yield action.handlePostSpecialFields(parentData);
  }

  return errors;
}
export function stdArrayItemCall(relField, extra) {
  return function* arrItemCall(arrItem, objRef) {
    const data = formDataToJApi(
      { ...SAMPLE_MAPPER[arrItem.type], id: arrItem.id },
      arrItem.id ? arrItem : { [relField]: toJS(objRef), ...arrItem, ...extra },
    );
    return yield formRequest(data);
  };
}

export function* createNewTags(tags) {
  return yield createNewObjects(tags, 'text', RESOURCE_TAG);
}

export function* createNewPerkCategories(categories) {
  return yield createNewObjects(categories, 'text', RESOURCE_PERK_CATEGORIES);
}

export function* createNewCompanies(objects) {
  return yield createNewObjects(objects, 'name', RESOURCE_COMPANIES, '_minimal=1');
}

export function* createNewCompany(object) {
  return yield createNewObject(object, 'name', RESOURCE_COMPANIES, '_minimal=1');
}

export function* createNewLocation(location) {
  if (!location || (location.id && location.type === RESOURCE_LOCATIONS)) {
    return location;
  }
  return yield postLocation(location);
}

function* postLocation(locationObj) {
  try {
    const locationReq = yield call(
      post,
      API_LOCATIONS_BASE_URL,
      {
        data: {
          type: RESOURCE_LOCATIONS,
          attributes: { full_google_data: JSON.stringify(omit(locationObj, ['id', 'type'])) },
        },
      }
    );
    yield put(updateIncludedFromRequest(locationReq));
    return resRef(locationReq.data);
  } catch (err) {
    const errorRespJson = yield err.response.json();

    if (errorRespJson.errors && isUniquenessError(errorRespJson.errors[0])) {
      const locationReq = yield request(`${API_LOCATIONS_BASE_URL}?filter[place_id:eq]=${locationObj.place_id}`);
      if (locationReq.data && locationReq.data.length) {
        yield put(updateIncludedFromRequest(locationReq));
        return resRef(locationReq.data[0]);
      }
    }
    logError(err);
  }

  return null;
}

const newObjectsMapper = {};

export function* createNewObjects(objects, nameFieldOrExtract, resType, extraQueryParams) {
  if (!objects || !objects.length) {
    return objects;
  }
  return yield all(objects.map((obj) => createNewObject(obj, nameFieldOrExtract, resType, extraQueryParams)));
}

export function* createNewObject(obj, nameFieldOrExtract, resType, extraQueryParams, rels) {
  const nameExtractor = typeof nameFieldOrExtract === 'string' ? (o) => o[nameFieldOrExtract] : nameFieldOrExtract;
  if (!obj || !obj.isNew || !nameExtractor(obj)) {
    return obj;
  }

  const mapperPath = [resType, nameExtractor(obj)];
  let objectFromMapper = get(newObjectsMapper, mapperPath);
  if (objectFromMapper) {
    while (objectFromMapper.isNew) {
      yield delay(100);
      objectFromMapper = get(newObjectsMapper, mapperPath);
    }
    return objectFromMapper;
  }

  set(newObjectsMapper, mapperPath, obj);
  const keysToRemove = ['id', 'type', 'isNew'];
  let data;
  if (rels) {
    data = { type: resType, attributes: { ...omit(obj, [...keysToRemove, ...rels]) }, relationships: {} };
    rels.forEach((rel) => { data.relationships[rel] = { data: resRefForArrayOrObj(obj[rel]) }; });
  } else {
    data = { type: resType, attributes: { ...omit(obj, keysToRemove) } };
  }
  const objectsCreationRequest = yield formRequest(data, '', extraQueryParams);
  yield put(updateIncludedFromRequest(objectsCreationRequest));
  const createdObjRef = resRef(objectsCreationRequest.data);

  set(newObjectsMapper, mapperPath, createdObjRef);
  return createdObjRef;
}

export function* savePortfolioInvestmentSaga(investment) {
  const investmentToSend = { ...investment };
  try {
    investmentToSend.investment_round = yield createNewObject(
      investment.investment_round,
      (invRound) => `${invRound.stage}--${invRound.company && invRound.company.name}`,
      RESOURCE_INVESTMENT_ROUNDS,
      '_minimal=1',
      ['company']
    );
  } catch (err) {
    throw new ProcessedError('investment_round', 'Error adding new Investment Round');
  }

  return yield formRequest(
    formDataToJApi({ ...SAMPLE_INVESTMENT, id: investment.id }, investmentToSend),
    'investment_round.company.tags,investor_profile,investing_company'
  );
}

// function* afterSaveFormUpdates(editedObject) {
//   switch (editedObject.type) {
//     case RESOURCE_COMPANIES: {
//       const company = yield select(makeSelectObject(editedObject));
//       yield getCompanysDemoDayIFrameHtml(company);
//       break;
//     }
//     default:
//       break;
//   }
// }

/**
 * Adds new data or JSON Validation or edits data in the schema
 * @param {object} schema
 * @param {string}} name
 */
function* customizeSchema(schema, name) {
  let newSchema = { ...schema };

  const tagFields = ['tags', 'areas_of_interest', 'areas_of_service', 'not_areas_of_interest'];
  for (let i = 0; i < tagFields.length; i++) {
    const field = tagFields[i];

    if (newSchema.properties[field]) {
      newSchema = yield adaptSchemaToNewTags(newSchema, field);
    }
  }

  if (name === 'companies') { // here because we set the data.type of relational fields from the .properties.data.type and discard the original
    if (newSchema.properties.acquired_by) {
      newSchema.properties.acquired_by.properties.data.type = schema.properties.acquired_by.type;
    }
    return newSchema;
  }

  if (name === 'event_agendas') {
    newSchema.required = newSchema.required.filter((item) => item !== 'aclass');
    return newSchema;
  }

  if (![STEP_PERSONAL_MORE, STEP_TWO_SHORT_FORM].includes(name) || schema.properties.roles.enum) return schema;

  // Adds all user roles and their details to schema
  try {
    const rolesListRequest = yield call(request, `${API_ROLES_BASE_URL}?filter[hidden:eq]=False&sort=order`);
    newSchema.properties.roles.enum = rolesListRequest.data.map((role) => flattenJAPIObject(role));
    return newSchema;
  } catch (err) {
    logError(err);
    // yield put(contentLoadingError(err));
  }
  return schema;
}

/**
 * Returns schema with added JSON validation for the field passed
 * @param {object} schema
 * @param {string} field
 */
function* adaptSchemaToNewTags(schema, field) {
  const newSchema = { ...schema };
  try {
    let tagsSchema = yield select(makeSelectSpecificFormSchema(RESOURCE_TAG));
    if (!tagsSchema) {
      yield loadFormSchema({ resourceName: RESOURCE_TAG });
      yield delay(300);
      tagsSchema = yield select(makeSelectSpecificFormSchema(RESOURCE_TAG));
    }

    newSchema.properties[field].properties.data.items.oneOf = [
      {
        properties: {
          isNew: { type: 'boolean', enum: [true] },
          text: tagsSchema.properties.text,
        },
        required: ['text', 'isNew'],
      },
      {
        properties: { isNew: { type: 'null' } },
      },
    ];
  } catch (e) {
    logError(e);
  }
  return newSchema;
}

export function* submitIfsRegForm(action) {
  const normalizedAction = cloneDeep(action);
  const selectedTimezone = yield select(selectTimezone);
  if (selectedTimezone?.tzIdentifier) {
    normalizedAction.values.registration_timezone = selectedTimezone.tzIdentifier;
  }
  return yield saveFormSection(normalizedAction);
}

let sagaInitialized = false;

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  if (!sagaInitialized) {
    yield fork(function* takeLatestForEachSchema() {
      const lastTasks = {};
      while (true) {
        const action = yield take(LOAD_FORM_SCHEMA);
        const actionIdentifier = `${action.resourceName}|${action.name}|${action.extraParams}`;
        if (!lastTasks[actionIdentifier]) {
          lastTasks[actionIdentifier] = true;
          yield fork(loadFormSchema, action);
        }
      }
    });
  }
  sagaInitialized = true;

  yield takeLatest(SAVE_FORM_SECTION, saveFormSection);
  yield takeLatest(SAVE_PEOPLE_EXPERIENCE_SECTION, savePeopleExperienceSectionSaga);
  yield takeLatest(SAVE_PEOPLE_PORTFOLIO_SECTION, savePeoplePortfolioSectionSaga);
  yield takeLatest(SAVE_GEN_INFO_SECTION, saveGeneralInfoSection);

  yield takeLatest(SAVE_COMPANY_INVESTMENT_ROUNDS_SECTION, saveCompanyInvestmentRoundsSectionSaga);
  yield takeLatest(SAVE_COMPANY_COACHES_SECTION, saveCompanyCoachRelsSaga);
  yield takeLatest(SAVE_COMPANY_FOUNDERS_SECTION, saveCompanyFoundersRelsSaga);
  yield takeLatest(SAVE_COMPANY_INVESTORS_SECTION, saveCompanyInvestorsSectionSaga);
  yield takeLatest(SAVE_COMPANY_PORTFOLIO_SECTION, saveCompanyPortfolioSectionSaga);
  yield takeLatest(DELETE_ADVISOR_OR_POC, deleteAdvisorOrPOC);
  yield takeLatest(SUBMIT_COMPANY_HEADER_FORM, submitCompanyHeaderForm);

  yield takeLatest(SAVE_INVITE_COMPANY, inviteCompany);

  yield takeLatest(SAVE_SETTINGS, saveSiteSettingsGroupSaga);
  yield takeLatest(SEND_PASSWORD_CHANGE, sendPasswordChangeSaga);
  yield takeLatest(SAVE_DEMODAY_ATTENDANCE_SECTION, saveDemoDayAttendanceSectionSaga);
  yield takeLatest(SAVE_PEOPLE_ACCOUNT_SECTION, savePeopleAccountSectionSaga);
  yield takeLatest(SAVE_MENTOR_SECTION, saveMentorSectionSaga);
  yield takeLatest(SAVE_FORM_WITH_EMAILS, saveFormSectionWithEmails);

  yield takeLatest(SUBMIT_IFS_REG_FORM, submitIfsRegForm);

  yield takeLatest(isFieldChange('invite_team_member', 'company'), loadCompanyUsers);
  yield takeLatest(isFormInitialized('companyHeader'), initializeAaClassDdRelFields);
  yield sharedCompaniesSaga();
}
