/* eslint-disable no-param-reassign */
import { all, call, put, select } from 'redux-saga/effects';
import { stopSubmit, change } from 'redux-form/immutable';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import differenceBy from 'lodash/differenceBy';
import { toDate } from 'date-fns-tz';
import { reset } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';

import { updateIncludedFromRequest } from 'actions';
import { RELATIONS, API_BASE, API_COMPANIES_BASE_URL, ATTEND_TYPE, RESOURCE_PEOPLE, PERMISSION, API_CAPABILITIES_BASE_URL, ATOMIC_RESULTS, API_BASE_OPERATIONS_PREVIEW, API_BASE_OPERATIONS, RESOURCE_COMPANIES } from 'containers/App/constants';
import { handleAuthError } from 'containers/AuthProcess/sagas';
import { isUniquenessError, formRequest } from 'utils/Forms/general';
import { handleCallAndCatches, callAndCatch, saveArrayField, ProcessedError, handleAtomicCallAndCatches, preprocessError } from 'utils/Forms/sagas';
import { formDataToJApi } from 'utils/formToJsonApi';
import { toJS } from 'utils/general';
import { atomicReq, post } from 'utils/request';
import { putActions, updateObjFromApi, waitSelect } from 'utils/sagas';
import { resRef } from 'utils/refs';
import { selectAaClass, selectInvitation, selectIsUserTzNotSupported, selectTimezone } from 'containers/DemoDay/Registration/selectors';
import { makeSelectLocation, makeSelectObject } from 'containers/App/selectors';
import { BROWSER_NOT_SUPPORTED, getDateTime, getValidatedTz } from 'components/Timezone/utils';
import { makeSelectSelectedPerson } from 'containers/People/Single/selectors';
import { logError } from 'utils/log';
import { selectInviteAsNew, selectMatchedUser } from 'containers/People/AddContact/selectors';
import { inviteAsNew, resetState, setPreview } from 'containers/People/AddContact/actions';
import { ADD_CONTACT_FORM_NAME } from 'containers/People/AddContact/constants';
import { showAlert } from 'containers/AlertBox/actions';

import { SAMPLE_COMPANY_MINIMAL, SAMPLE_MEETING, SAMPLE_MEMBERSHIP, SAMPLE_PROFILE_EMAIL } from '../constants';
import { makeSelectCompaniesByName } from '../selectors';
import { setCompanyByName } from '../actions';
// eslint-disable-next-line import/no-cycle
import { savePortfolioInvestmentSaga, saveFormSection } from '../saga';


export function* savePeopleExperienceSectionSaga(action) {
  const errors = yield saveArrayField(action, 'experiences', membershipCall, 'company_membership');

  if (!isEmpty(errors)) {
    yield put(stopSubmit(action.section, errors));
    return false;
  }

  if (action.successAction) {
    yield put(action.successAction);
  }
  return true;
}

function* membershipCall(membership, objRef, idx, action) {
  const membershipToSave = { ...membership };
  try {
    membershipToSave.company = yield handleNewCompany(membership.company);
    yield put(change(action.section, `experiences[${idx}].company`, membershipToSave.company));
  } catch (err) {
    throw new ProcessedError('experiences', 'Error adding new company');
  }
  const data = formDataToJApi(
    { ...SAMPLE_MEMBERSHIP, id: membershipToSave.id },
    { relation: RELATIONS.OTHER, ...membershipToSave, user: toJS(objRef) }
  );
  return yield formRequest(data, 'company.stages_of_interest,company.areas_of_service');
}

export function* profileEmailCall(profileEmail, objRef) {
  const data = formDataToJApi(
    { ...SAMPLE_PROFILE_EMAIL, id: profileEmail.id },
    profileEmail.id ? profileEmail : { user: toJS(objRef), ...profileEmail },
  );
  if (data.attributes.is_primary === false) {
    delete data.attributes.is_primary;
  }
  return yield formRequest(data);
}

export function* meetingsCall(meetingCalendar) {
  const data = formDataToJApi(
    { ...SAMPLE_MEETING, id: meetingCalendar.id },
    meetingCalendar.id ? omit(meetingCalendar, 'user') : meetingCalendar,
  );
  return yield formRequest(data);
}

export function* handleNewCompany(company) {
  if (!company?.name || (!company.isNew && company.id > 0)) {
    return company;
  }
  try {
    const newCompanyRequest = yield call(
      post,
      `${API_COMPANIES_BASE_URL}?_minimal=1`,
      { data: formDataToJApi(SAMPLE_COMPANY_MINIMAL, company) }
    );
    yield put(updateIncludedFromRequest(newCompanyRequest));
    const newMinimalCompany = { ...resRef(newCompanyRequest.data), name: company.name };

    yield put(setCompanyByName(company.name, newMinimalCompany));

    return newMinimalCompany;
  } catch (err) {
    let error = '';
    let foundUniquenessError;
    try {
      const errorJson = yield err.response.json();
      if (errorJson.errors.forEach) {
        errorJson.errors.forEach((currentError) => {
          if (isUniquenessError(currentError)) {
            foundUniquenessError = true;
          }
          error += `${currentError.detail}; `;
        });
      } else if (isUniquenessError(errorJson.errors)) {
        foundUniquenessError = true;
      } else {
        error = 'Server Error';
      }
    } catch (e) {
      error = err.toString();
    }
    if (foundUniquenessError) {
      const companiesByName = yield waitSelect(makeSelectCompaniesByName(), 10);
      if (companiesByName[company.name]) {
        return companiesByName[company.name];
      }
    }
    throw error;
  }
}

export function* savePeoplePortfolioSectionSaga(action) {
  const multiFormMainField = 'erogated_investments';
  const investmentRequests = yield all(action.values[multiFormMainField].map((investment) => callAndCatch(savePortfolioInvestmentSaga, {
    ...investment,
    investing_company: investment.investment_type === 'Fund' ? investment.investing_company : null,
    investor_profile: toJS(action.objRef),
  })));
  const isSuccess = yield handleCallAndCatches(investmentRequests, action, multiFormMainField);
  yield updateObjFromApi(action.objRef, multiFormMainField);
  return isSuccess;
}

export function* loadCompanyUsers({ payload: company }) {
  yield updateObjFromApi(company, 'current_members_rel.user');
}

export function* sendPasswordChangeSaga({ values, section, successAction }) {
  try {
    yield post(`${API_BASE}/authentication/passwd/change`, values);
    yield putActions(successAction);
  } catch (e) {
    yield handleAuthError(e, section, { _error: 'There was an error updating your password' });
  }
}

export function* saveDemoDayAttendanceSectionSaga(action) {
  const location = yield select(makeSelectLocation());
  const aaClass = yield select(selectAaClass);
  const isUserTzNotSupported = yield select(selectIsUserTzNotSupported);
  const invitation = yield select(selectInvitation);
  let normalizedAction = { ...action };
  let fallbackTimezone = null;

  const selectedTimezone = yield select(selectTimezone);

  if (location.pathname.includes('/people/')) {
    // This is executed on save of demo day section in people profile
    const selectedPerson = yield select(makeSelectObject({ type: RESOURCE_PEOPLE, id: location.pathname.split('/people/')?.[1] }));
    fallbackTimezone = getValidatedTz(action.values.registration_timezone, selectedPerson?.location?.().timezone, true);
    fallbackTimezone = getDateTime(new Date(), fallbackTimezone) === BROWSER_NOT_SUPPORTED ? 'UTC' : fallbackTimezone;
    normalizedAction.values.registration_timezone = fallbackTimezone;
  } else if (selectedTimezone?.tzIdentifier && (!isUserTzNotSupported || (isUserTzNotSupported && invitation && !invitation.registration_timezone))) {
    normalizedAction.values.registration_timezone = selectedTimezone.tzIdentifier;
  }

  if (action.values.rec_access_date_usertz && action.values.rec_access_time_usertz) {
    const selectedDateTimeInSelectedTz = toDate(
      `${action.values.rec_access_date_usertz}T${action.values.rec_access_time_usertz}`,
      { timeZone: location.pathname.includes('/people/') ? fallbackTimezone : selectedTimezone?.tzIdentifier }
    );
    const utcDate = toDate(selectedDateTimeInSelectedTz, { timeZone: 'UTC' });

    normalizedAction = {
      ...normalizedAction,
      values: {
        ...normalizedAction.values,
        rec_access_date: utcDate.toISOString().split('T')[0],
        rec_access_time: utcDate.toISOString().split('T')[1].split('.')[0],
      },
    };
  }

  if (aaClass && !aaClass.livestream) {
    normalizedAction.values.registered_to_attend_as = ATTEND_TYPE.remotely_recorded;
  }
  yield saveFormSection(normalizedAction);
}

export function* saveGeneralInfoSection(action) {
  const editedAction = { ...action };
  if (action.values.corporate_cert_level === 0) {
    editedAction.values.corporate_cert_level = null;
  }

  yield saveFormSection(editedAction);
}

export function* getInviteRequests({ selectedPerson, action, isAddContact, isPreview }) {
  const formRequests = [];

  const baseObj = {
    ...resRef(selectedPerson),
    relationships: {
      user: 0,
    },
  };
  let peopleResource = { ...resRef(selectedPerson), ...(selectedPerson ? { user: resRef(selectedPerson) } : {}) };

  const savedRoles = selectedPerson?.roles?.() || [];
  const unsavedRolesFromField = action.values.roles;
  // returns added roles
  const rolesToBeInvited = differenceBy(unsavedRolesFromField, savedRoles, 'id');
  // returns removed roles
  const rolesToBeDeleted = differenceBy(savedRoles, unsavedRolesFromField, 'id');

  const finalUserRoles = [...savedRoles, ...(rolesToBeInvited || [])]
    .filter((r) => !rolesToBeDeleted.map((role) => role.id).includes(r.id));
  if (finalUserRoles?.length > 0) {
    if (!baseObj.relationships) {
      baseObj.relationships = {};
    }
    baseObj.relationships.roles = 0;
    peopleResource.roles = finalUserRoles;
  }

  // populate email, firstname, lastname, linkedin_profile to attributes if user is on add contact form
  if (isAddContact) {
    const isInviteAsNew = yield select(selectInviteAsNew);
    peopleResource = {
      ...peopleResource,
      ...action.values,
    };
    if (isInviteAsNew) peopleResource.invite_as_new = isInviteAsNew;

    baseObj.relationships.company = 0;
    baseObj.attributes = {
      ...action?.values,
    };
    if (isInviteAsNew) baseObj.attributes.invite_as_new = isInviteAsNew;

    // remove extra attributes, they sent as relationships
    delete baseObj.attributes.company;
    delete baseObj.attributes.aclasses;
    delete baseObj.attributes.capabilities_rel;
    delete baseObj.attributes.roles;
  }

  let hrefString = `${API_BASE}/invites/all`;

  if (isPreview) {
    hrefString += '/mail_preview';
  }

  const isUserToBeCertifiedCorpInnov = (
    find(savedRoles, (r) => r.title === PERMISSION.corporate)
    || (rolesToBeInvited?.length > 0 && find(rolesToBeInvited, (r) => r.title === PERMISSION.corporate))
  )
    && action.values.corporate_cert_level;
  if (isUserToBeCertifiedCorpInnov) {
    hrefString += '/register/corporate';
    if (!baseObj.attributes) {
      baseObj.attributes = {};
    }
    baseObj.attributes.corporate_cert_level = 0;
    peopleResource.corporate_cert_level = action.values.corporate_cert_level;
  }

  const coachClassesToAdd = differenceBy(action.values.aclasses, selectedPerson?.coach_classes?.(), 'id');
  // adding Class Coach / Partner / LP / Admin role/s and vault access through invite endpoint
  if ((rolesToBeInvited?.length > 0
    && rolesToBeInvited.some((r) => [PERMISSION.class_coach, PERMISSION.lp, PERMISSION.partner, PERMISSION.admin].includes(r.title)))
    || coachClassesToAdd?.length > 0
    || isUserToBeCertifiedCorpInnov
  ) {
    if (find(rolesToBeInvited, (r) => r.title === PERMISSION.class_coach) || coachClassesToAdd?.length > 0) {
      hrefString += `/${PERMISSION.class_coach}`;
      if (!baseObj.relationships) {
        baseObj.relationships = {};
      }
      baseObj.relationships.aclasses = 0;
      peopleResource.aclasses = coachClassesToAdd;
      delete action.values.aclasses;
    }

    if (find(rolesToBeInvited, (r) => r.title === PERMISSION.partner)) {
      hrefString += `/${PERMISSION.partner}`;
    }

    if (find(rolesToBeInvited, (r) => r.title === PERMISSION.lp)) {
      hrefString += `/${PERMISSION.lp}`;
    }

    if (find(rolesToBeInvited, (r) => r.title === PERMISSION.admin)) {
      hrefString += `/${PERMISSION.admin}`;
    }

    const newAccessesAndRolesReq = {
      href: hrefString,
      data: formDataToJApi(baseObj, peopleResource),
    };
    formRequests.push(newAccessesAndRolesReq);
  } else {
    if (!hrefString.includes('/register')) {
      hrefString += '/register';
    }
    const updatedRolesReq = {
      href: hrefString,
      data: formDataToJApi(baseObj, peopleResource),
    };
    formRequests.push(updatedRolesReq);
  }

  return formRequests;
}

function* getCapabilityRequests(passedCapabilitiesRel) {
  const formRequests = [];

  const capabilitiesRel = passedCapabilitiesRel?.filter((rel) => rel.id);
  if (capabilitiesRel) {
    const capsToDisable = capabilitiesRel.filter((rel) => !rel.active);

    const capabilitiesToUpdate = capsToDisable.map((rel) => ({
      ...rel.resRef,
      inactive_reason: rel.inactive_reason,
      inactive_reason_other: rel.inactive_reason_other,
    }));
    formRequests.push(...capabilitiesToUpdate);

    capsToDisable.forEach((rel) => {
      formRequests.push({
        op: 'update',
        href: `${API_CAPABILITIES_BASE_URL}/${rel?.id}/disable`,
      });
    });
  }

  return formRequests;
}

export function* savePeopleAccountSectionSaga(action, isAddContact, isPreview) {
  let profilePerson;
  if (isAddContact) {
    profilePerson = yield select(selectMatchedUser);
  } else {
    profilePerson = yield select(makeSelectSelectedPerson());
  }

  const formRequests = [];

  if (action.values.company?.id < 0 && action.values.company?.isNew) {
    const companyId = uuidv4();
    formRequests.push({
      op: 'add',
      href: `${API_COMPANIES_BASE_URL}?_minimal=1`,
      data: {
        lid: companyId,
        type: RESOURCE_COMPANIES,
        attributes: {
          name: action.values.company.name,
        },
      },
    });
    delete action.values.company.id;
    action.values.company.lid = companyId;
  }

  // updates existing capabilities
  if (!isPreview) {
    const capabilitiesReq = yield getCapabilityRequests(action.values.capabilities_rel);
    formRequests.push(...(capabilitiesReq || []));
  }

  // invites new capabilities and edits (adds or deletes) user roles
  const rolesAndAccessesReq = yield getInviteRequests({ selectedPerson: profilePerson, action, isAddContact, isPreview });
  formRequests.push(...(rolesAndAccessesReq || []));

  // delete capabilities_rel and roles from action
  // because we don't need this on updating account resource
  delete action.values.capabilities_rel;
  delete action.values.roles;

  if (!isAddContact) {
    if (action.values.corporate_cert_level !== undefined) {
      formRequests.push({
        ...resRef(profilePerson),
        corporate_cert_level: action.values.corporate_cert_level === 0 ? null : action.values.corporate_cert_level,
      });
      delete action.values.corporate_cert_level;
    }

    // saving account section
    if (![null, undefined].includes(action?.values?.connect_me_quota) || action?.values?.lms_wp_id !== undefined) {
      formRequests.push({ ...action.objRef, ...action.values });
    }
  }

  try {
    const res = yield call(atomicReq, formRequests.filter((req) => req), true, isPreview ? API_BASE_OPERATIONS_PREVIEW : API_BASE_OPERATIONS);
    if (profilePerson && !isPreview) yield updateObjFromApi(profilePerson, ['account.capabilities_rel.aclass', 'account.capabilities_rel.aclass_company.aclass', 'roles', 'coach_classes']);

    if (action.successAction && Array.isArray(action.successAction)) {
      yield all(action.successAction.map((s) => put(s)));
    } else if (action.successAction) {
      yield put(action.successAction);
    }

    if (isAddContact && !isPreview) {
      const isInviteAsNew = yield select(selectInviteAsNew);
      const invitedUserNicename = `${action?.values?.firstname} ${action?.values?.lastname}`;
      yield put(showAlert(`Success! ${invitedUserNicename} was added to the Vault and invited to register a profile.`));
      yield put(resetState());
      yield put(reset(ADD_CONTACT_FORM_NAME));
      yield put(setPreview(null));
      if (isInviteAsNew) yield put(inviteAsNew(false));
    }
    if (isPreview) return res;
  } catch (err) {
    logError(err);
    const { errorJson } = yield preprocessError(err);
    yield handleAtomicCallAndCatches(errorJson[ATOMIC_RESULTS], action);
  }

  return true;
}

export function* saveMentorSectionSaga(action) {
  const { meetings } = action.values;
  const newAction = cloneDeep(action);
  newAction.handlePostSpecialFields = function* funcToRun() {
    let errors = null;
    if (meetings) {
      errors = yield saveArrayField(action, 'meetings', meetingsCall);
    }
    return errors;
  };
  if (newAction.values.meetings) {
    delete newAction.values.meetings;
  }
  yield saveFormSection(newAction);
}

export function* saveFormSectionWithEmails(action) {
  const profileEmails = action.values.profile_emails;
  const newAction = cloneDeep(action);
  newAction.handlePostSpecialFields = function* funcToRun() {
    let errors = null;
    if (profileEmails) {
      errors = yield saveArrayField(action, 'profile_emails', profileEmailCall);
    }
    return errors;
  };
  if (newAction.values.profile_emails) {
    delete newAction.values.profile_emails;
  }
  yield saveFormSection(newAction);
}
