/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp, faTimes } from '@fortawesome/pro-solid-svg-icons';

const Input = (props) => (
  <components.Input {...props} spellCheck />
);

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon className="dsa-select-input__caret" icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown} />
  </components.DropdownIndicator>
);

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object,
};

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon icon={faTimes} />
  </components.ClearIndicator>
);

export {
  Input,
  DropdownIndicator,
  ClearIndicator,
};
