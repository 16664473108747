import { makeTwoDigits } from './general';
import { timezoneAbbreviationFromDB } from './timezoneAbbreviationFromDB';

export const addMinutes = (dateTime, minutes) => new Date(dateTime.getTime() + (minutes * 60 * 1000));
export const substractMinutes = (dateTime, minutes) => new Date(dateTime.getTime() - (minutes * 60 * 1000));
export const addDays = (dateTime, days) => addMinutes(dateTime, days * 24 * 60);
export const substractDays = (dateTime, days) => substractMinutes(dateTime, days * 24 * 60);
export const makeDateTime = (date, time, tzOffset = 'Z') => new Date(`${date}T${time}${tzOffset}`);

export const getDates = (startDt, stopDt) => {
  const dateArray = [];
  let currentDate = new Date(startDt);
  const stopDate = new Date(stopDt);
  while (currentDate.setHours(0, 0, 0) <= stopDate.setHours(0, 0, 0)) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

export function makeDateTimeGCal(date, time) {
  return `${date && date.replace(/-/g, '')}T${time && time.replace(/:/g, '')}`;
}

export const parseDateString = (dateStr) => {
  if (dateStr instanceof Date || !dateStr) {
    return dateStr;
  }
  if (dateStr.match(/^\d{4}-\d\d-\d\d$/)) {
    return getLocalMidnight(dateStr);
  }
  if (!dateStr.match(/\d(Z|[+-]\d\d:\d\d)$/)) {
    return new Date(`${dateStr}Z`);
  }
  return new Date(dateStr);
};

export const fullDateTimeNoYear = (dateObjOrStr) => {
  const dateObj = dateObjOrStr instanceof Date ? dateObjOrStr : parseDateString(dateObjOrStr);

  let dateTimeFormatter;
  if (window.Intl?.DateTimeFormat) {
    dateTimeFormatter = new Intl.DateTimeFormat(
      undefined,
      {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
        month: 'short',
        day: 'numeric',
      }
    );
  } else {
    dateTimeFormatter = { format: (date) => date.toJSON() };
  }
  return dateTimeFormatter.format(dateObj);
};

export const extractTimeStr = (dateTime) => dateTime.toJSON().split('T')[1].split('.')[0];

export const time24To12 = (time) => {
  if (!time) return '';

  const timeStr = typeof time === 'string' ? time : extractTimeStr(time);
  const timeParts = timeStr.match(/^([01]\d|2[0-3])(?::)([0-5]\d)(?::[0-5]\d)?$/) || [time];

  return `${timeParts[1] % 12 || 12}:${timeParts[2]} ${timeParts[1] < 12 ? 'AM' : 'PM'}`;
};

export const asHour12 = (dateTime) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return dateTime.toLocaleString('en-US', options);
};

export function getFormattedDate(dateString) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);

  return dateObj.getDate()
    ? `${makeTwoDigits(dateObj.getMonth() + 1)}/${dateObj.getDate()}/${dateObj.getFullYear().toString().substr(2)}`
    : '';
}

export function makeTZoffsetStr(tzOffsetInTotalMinutes) {
  const tzOffsetInMinutesAbs = Math.abs(tzOffsetInTotalMinutes);
  const sign = tzOffsetInTotalMinutes >= 0 ? '-' : '+';
  const tzOffsetHours = makeTwoDigits(Math.floor(tzOffsetInMinutesAbs / 60));
  const tzOffsetMinutes = makeTwoDigits(tzOffsetInMinutesAbs % 60);
  const tzOffsetStr = `${sign}${tzOffsetHours}:${tzOffsetMinutes}`;
  return tzOffsetStr;
}

export function getLocalMidnight(dateString) {
  if (!dateString) {
    return null;
  }
  const tzOffsetInTotalMinutes = (new Date(`${dateString}T11:00:00Z`)).getTimezoneOffset();
  const tzOffsetStr = makeTZoffsetStr(tzOffsetInTotalMinutes);

  return new Date(`${dateString}T00:00:00${tzOffsetStr}`);
}

export const getLocalISODate = (dateObj) => dateObj &&
  `${dateObj.getFullYear()}-${makeTwoDigits(dateObj.getMonth() + 1)}-${makeTwoDigits(dateObj.getDate())}`;

export const getLocalISOTime = (dateObj) => dateObj &&
  `${makeTwoDigits(dateObj.getHours())}:${makeTwoDigits(dateObj.getMinutes())}:${makeTwoDigits(dateObj.getSeconds())}`;

export function getYyyyMmDd(dateString) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);
  if (!dateObj.getDate()) {
    return '';
  }
  return getLocalISODate(dateObj);
}

export const minutesToHoursText = (mins) => `${mins / 60} hour${mins !== 60 ? 's' : ''}`;

export function getFullDate(dateString, mmddyy) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);
  const yearDate = mmddyy ? dateObj.getFullYear().toString().substr(2) : dateObj.getFullYear();

  return dateObj.getDate()
    ? `${makeTwoDigits(dateObj.getMonth() + 1)}/${makeTwoDigits(dateObj.getDate())}/${yearDate}`
    : '';
}

export const getMonthsToNow = (dateObj) => (new Date() - dateObj) / (3600 * 1000 * 24 * 30);

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];
const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function getMonthYearDate(dateString, shortMonth, shortYear) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);
  const monthDate = shortMonth
    ? monthNames[dateObj.getMonth()].substr(0, 3)
    : monthNames[dateObj.getMonth()];
  const yearDate = shortYear
    ? dateObj.getFullYear().toString().substr(2)
    : dateObj.getFullYear();

  return dateObj.getDate() ? `${monthDate} ${yearDate}` : '';
}

export function getMonthDayDate(dateString, shortMonth) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);
  const dOM = dateObj.getDate();
  const monthDate = shortMonth
    ? monthNames[dateObj.getMonth()].substr(0, 3)
    : monthNames[dateObj.getMonth()];

  return dateObj.getDate() ? `${monthDate} ${dOM}` : '';
}

export function getMonthDayYearDate(dateString, shortMonth, shortYear) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);
  const dOM = dateObj.getDate();
  const monthDate = shortMonth
    ? monthNames[dateObj.getMonth()].substr(0, 3)
    : monthNames[dateObj.getMonth()];
  const yearDate = shortYear
    ? dateObj.getFullYear().toString().substr(2)
    : dateObj.getFullYear();

  return dateObj.getDate() ? `${monthDate} ${dOM}, ${yearDate}` : '';
}

export function getLongDate(dateString, shortDay, shortMonth, shortYear) {
  if (!dateString) return '';

  const dateObj = parseDateString(dateString);
  const dOM = dateObj.getDate();
  const dOW = shortDay
    ? weekDays[dateObj.getDay()].substr(0, 3)
    : weekDays[dateObj.getDay()];
  const monthDate = shortMonth
    ? monthNames[dateObj.getMonth()].substr(0, 3)
    : monthNames[dateObj.getMonth()];
  const yearDate = shortYear
    ? dateObj.getFullYear().toString().substr(2)
    : dateObj.getFullYear();

  return dateObj.getDate() ? `${dOW}, ${monthDate} ${dOM}, ${yearDate}` : '';
}

export const getPstDate = (date) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    }),
  );
};

export const getUTCTimePart = (dateTime) => dateTime.toJSON().split('T')[1].substring(0, 8);

export const daysHoursMinutesLeft = (toDate) => {
  const diffInMinutes = Math.ceil((new Date(toDate) - new Date()) / (60 * 1000));
  return {
    days: Math.floor(diffInMinutes / (24 * 60)),
    hours: Math.floor((diffInMinutes % (24 * 60)) / 60),
    minutes: diffInMinutes % 60,
  };
};

export const stdTimezoneOffset = (datetime) => {
  const jan = new Date(datetime.getFullYear(), 0, 1);
  const jul = new Date(datetime.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (datetime) => datetime.getTimezoneOffset() < stdTimezoneOffset(datetime);

export const getCurrentTimestamp = () => (new Date()).getTime() / 1000;
export const getPdtPst = (isoDTString) => isoDTString && (isoDTString.split('-').reverse()[0] === '07:00' ? 'PDT' : 'PST');
export const getTzOffsetStr = (tzawareTime) => tzawareTime.match(/([+-]\d\d):\d\d/)[1];
export const getFullTzOffsetStr = (tzawareTime) => tzawareTime.match(/([+-]\d\d:\d\d)/)[1];

export function subtractDates(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000;

  const utcDate1 = Date.UTC(date1?.getFullYear(), date1?.getMonth(), date1?.getDate());
  const utcDate2 = Date.UTC(date2?.getFullYear(), date2?.getMonth(), date2?.getDate());

  return Math.floor((utcDate2 - utcDate1) / oneDay);
}

export const timezoneAbbreviation = (timezoneString) => {
  const timezoneAbbreviations = {
    'Afghanistan Time': 'AFT',
    'Alaska Daylight Time': 'AKDT',
    'Alaska Standard Time': 'AKST',
    'Amazon Summer Time': 'AMST',
    'Amazon Time': 'AMT',
    'Arabia Standard Time': 'AST',
    'Argentina Time': 'ART',
    'Armenia Summer Time': 'AMST',
    'Armenia Time': 'AMT',
    'ASEAN Common Time': 'ACT',
    'Atlantic Daylight Time': 'ADT',
    'Atlantic Standard Time': 'AST',
    'Australian Central Daylight Savings Time': 'ACDT',
    'Australian Central Standard Time': 'ACST',
    'Australian Eastern Daylight Savings Time': 'AEDT',
    'Australian Eastern Standard Time': 'AEST',
    'Australian Western Daylight Time': 'AWDT',
    'Australian Western Standard Time': 'AWST',
    'Azerbaijan Time': 'AZT',
    'Azores Standard Time': 'AZOST',
    'Baker Island Time': 'BIT',
    'Bangladesh Standard Time': 'BST',
    'Bhutan Time': 'BTT',
    'Bolivia Time': 'BOT',
    'Brasilia Time': 'BRT',
    'British Indian Ocean Time': 'BIOT',
    'British Summer Time': 'BST',
    'Brunei Time': 'BDT',
    'Cape Verde Time': 'CVT',
    'Central Africa Time': 'CAT',
    'Central Daylight Time': 'CDT',
    'Central European Daylight Time': 'CEDT',
    'Central European Summer Time': 'CEST',
    'Central European Time': 'CET',
    'Central Indonesia Time': 'CIT',
    'Central Standard Time': 'CST',
    'Central Summer Time': 'CST',
    'Central Western Standard Time': 'CWST',
    'Chamorro Standard Time': 'ChST',
    'Chatham Daylight Time': 'CHADT',
    'Chatham Standard Time': 'CHAST',
    'Chile Standard Time': 'CLT',
    'Chile Summer Time': 'CLST',
    'China Standard Time': 'CST',
    'China time': 'CT',
    Choibalsan: 'CHOT',
    'Christmas Island Time': 'CXT',
    'Chuuk Time': 'CHUT',
    'Clipperton Island Standard Time': 'CIST',
    'Cocos Islands Time': 'CCT',
    'Colombia Summer Time': 'COST',
    'Colombia Time': 'COT',
    'Cook Island Time': 'CKT',
    'Coordinated Universal Time UTC': 'UTC',
    'Cuba Daylight Time': 'CDT',
    'Cuba Standard Time': 'CST',
    'Davis Time': 'DAVT',
    "Dumont d'Urville Time": 'DDUT',
    'East Africa Time': 'EAT',
    'Easter Island Standard Summer Time': 'EASST',
    'Easter Island Standard Time': 'EAST',
    'Eastern Caribbean Time': 'ECT',
    'Eastern Daylight Time': 'EDT',
    'Eastern European Daylight Time': 'EEDT',
    'Eastern European Summer Time': 'EEST',
    'Eastern European Time': 'EET',
    'Eastern Greenland Summer Time': 'EGST',
    'Eastern Greenland Time': 'EGT',
    'Eastern Indonesian Time': 'EIT',
    'Eastern Standard Time': 'EST',
    'Ecuador Time': 'ECT',
    'Falkland Islands Standard Time': 'FKST',
    'Falkland Islands Summer Time': 'FKST',
    'Falkland Islands Time': 'FKT',
    'Fernando de Noronha Time': 'FNT',
    'Fiji Time': 'FJT',
    'French Guiana Time': 'GFT',
    'Further-eastern European Time': 'FET',
    'Galapagos Time': 'GALT',
    'Gambier Island Time': 'GIT',
    'Gambier Islands': 'GAMT',
    'Georgia Standard Time': 'GET',
    'Gilbert Island Time': 'GILT',
    'Greenwich Mean Time UTC': 'GMT',
    'Gulf Standard Time': 'GST',
    'Guyana Time': 'GYT',
    'Hawaii Standard Time': 'HST',
    'Hawaii-Aleutian Daylight Time': 'HADT',
    'Hawaii-Aleutian Standard Time': 'HAST',
    'Heard and McDonald Islands Time': 'HMT',
    "Heure Avancée d'Europe Centrale": 'HAEC',
    'Hong Kong Time': 'HKT',
    'Indian Ocean Time': 'IOT',
    'Indian Standard Time': 'IST',
    'Indian/Kerguelen': 'TFT',
    'Indochina Time': 'ICT',
    'Iran Daylight Time': 'IRDT',
    'Iran Standard Time': 'IRST',
    'Irish Standard Time': 'IST',
    'Irkutsk Time': 'IRKT',
    'Israel Daylight Time': 'IDT',
    'Israel Standard Time': 'IST',
    'Japan Standard Time': 'JST',
    'Kaliningrad Time': 'USZ1',
    'Kamchatka Time': 'PETT',
    'Khovd Time': 'HOVT',
    'Korea Standard Time': 'KST',
    'Kosrae Time': 'KOST',
    'Krasnoyarsk Time': 'KRAT',
    'Kyrgyzstan time': 'KGT',
    'Line Islands Time': 'LINT',
    'Lord Howe Standard Time': 'LHST',
    'Lord Howe Summer Time': 'LHST',
    'Macquarie Island Station Time': 'MIST',
    'Magadan Time': 'MAGT',
    'Malaysia Standard Time': 'MST',
    'Malaysia Time': 'MYT',
    'Maldives Time': 'MVT',
    'Marquesas Islands Time': 'MART',
    'Marshall Islands': 'MHT',
    'Mauritius Time': 'MUT',
    'Mawson Station Time': 'MAWT',
    'Middle European Saving Time': 'MEST',
    'Middle European Time': 'MET',
    'Moscow Time': 'MSK',
    'Mountain Daylight Time': 'MDT',
    'Mountain Standard Time': 'MST',
    'Myanmar Standard Time': 'MST',
    'Myanmar Time': 'MMT',
    'Nepal Time': 'NPT',
    'New Caledonia Time': 'NCT',
    'New Zealand Daylight Time': 'NZDT',
    'New Zealand Standard Time': 'NZST',
    'Newfoundland Daylight Time': 'NDT',
    'Newfoundland Standard Time': 'NST',
    'Newfoundland Time': 'NT',
    'Niue Time': 'NUT',
    'Norfolk Time': 'NFT',
    'Omsk Time': 'OMST',
    'Oral Time': 'ORAT',
    'Pacific Daylight Time': 'PDT',
    'Pacific Standard Time': 'PST',
    'Pakistan Standard Time': 'PKT',
    'Papua New Guinea Time': 'PGT',
    'Paraguay Summer Time': 'PYST',
    'Paraguay Time': 'PYT',
    'Peru Time': 'PET',
    'Philippine Standard Time': 'PHT',
    'Phoenix Island Time': 'PHOT',
    'Pohnpei Standard Time': 'PONT',
    'Rothera Research Station Time': 'ROTT',
    'Réunion Time': 'RET',
    'Saint Pierre and Miquelon Daylight Time': 'PMDT',
    'Saint Pierre and Miquelon Standard Time': 'PMST',
    'Sakhalin Island time': 'SAKT',
    'Samara Time': 'SAMT',
    'Samoa Standard Time': 'SST',
    'Seychelles Time': 'SCT',
    'Showa Station Time': 'SYOT',
    'Singapore Standard Time': 'SST',
    'Singapore Time': 'SGT',
    'Solomon Islands Time': 'SBT',
    'South African Standard Time': 'SAST',
    'South Georgia and the South Sandwich Islands': 'GST',
    'Srednekolymsk Time': 'SRET',
    'Sri Lanka Time': 'SLST',
    'Suriname Time': 'SRT',
    'Tahiti Time': 'TAHT',
    'Tajikistan Time': 'TJT',
    'Thailand Standard Time': 'THA',
    'Timor Leste Time': 'TLT',
    'Tokelau Time': 'TKT',
    'Tonga Time': 'TOT',
    'Turkmenistan Time': 'TMT',
    'Tuvalu Time': 'TVT',
    'Ulaanbaatar Time': 'ULAT',
    'Uruguay Standard Time': 'UYT',
    'Uruguay Summer Time': 'UYST',
    'Uzbekistan Time': 'UZT',
    'Vanuatu Time': 'VUT',
    'Venezuelan Standard Time': 'VET',
    'Vladivostok Time': 'VLAT',
    'Volgograd Time': 'VOLT',
    'Vostok Station Time': 'VOST',
    'Wake Island Time': 'WAKT',
    'West Africa Summer Time': 'WAST',
    'West Africa Time': 'WAT',
    'Western European Daylight Time': 'WEDT',
    'Western European Summer Time': 'WEST',
    'Western European Time UTC': 'WET',
    'Western Indonesian Time': 'WIT',
    'Western Standard Time': 'WST',
    'Yakutsk Time': 'YAKT',
    'Yekaterinburg Time': 'YEKT',
    'Zulu Time': 'Z',
  };
  return timezoneAbbreviations[timezoneString] || timezoneString;
};

export const getTimezoneAbbreviation = (date) => {
  let match;
  const dtStr = date.toString();
  // Wed Feb 29 2012 00:00:00 GMT-0800 (Pacific Standard Time)
  // Wed Feb 29 2012 00:00:00 GMT-0800 (PST)
  match = dtStr.match(/\((.+)\)/);
  if (match) {
    const timezoneId = new Intl.DateTimeFormat(date).resolvedOptions().timeZone;
    let timeZoneAbbrFromDB = timezoneAbbreviationFromDB[timezoneId] || timezoneId;
    if (timeZoneAbbrFromDB.includes('+') || timeZoneAbbrFromDB.includes('-')) {
      timeZoneAbbrFromDB = timezoneId;
    }
    return timeZoneAbbrFromDB || timezoneAbbreviation(match[1].valueOf());
  }
  match = dtStr.match(/\d{2}:\d{2}:\d{2}\s(\w{1,5})\s/);
  // Wed Feb 29 00:00:00 PST 2012
  if (match) {
    return match[1].valueOf();
  }
  return undefined;
};

export function getTimeAgo(isoString) {
  // append Z to isoString to let new Date interpret it as UTC +0 time
  const date = new Date(`${isoString}Z`);
  const now = new Date();
  const diff = now - date;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return seconds + (seconds === 1 ? ' second ago' : ' seconds ago');
  }
  if (minutes < 60) {
    return minutes + (minutes === 1 ? ' minute ago' : ' minutes ago');
  }
  if (hours < 24) {
    return hours + (hours === 1 ? ' hour ago' : ' hours ago');
  }
  if (days < 30) {
    return days + (days === 1 ? ' day ago' : ' days ago');
  }
  if (months < 12) {
    return months + (months === 1 ? ' month ago' : ' months ago');
  }

  return years + (years === 1 ? ' year ago' : ' years ago');
}

/**
 * Returns 24-hr time format
 * @param time Example: '13:00'
 * @param minutes Example: 30
 * @returns '13:30:00'
 */
export const addTime = (time, minutes) => {
  if (time && minutes) {
    const [hour, min] = time.split(':');
    let totalHour = Number(hour);
    let totalMin = Number(min) + Number(minutes);
    if (totalMin >= 60) {
      totalHour += Math.floor(totalMin / 60);
      totalMin %= 60;
    }

    return `${makeTwoDigits(totalHour)}:${makeTwoDigits(totalMin)}:00`;
  }
  return time;
};

export const transformDateToDMDYString = (date) => {
  const day = weekDays[date.getDay()];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const dayNumber = date.getDate();
  return `${day}, ${month} ${dayNumber}, ${year}`;
};

export const dateObjToMDString = () => {
  const date = new Date();
  const options = { month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};
